import React, { useEffect, useState, useCallback, useMemo } from "react";
import Navbar from "../../app/shared/Navbar";
import Sidebar from "../../app/shared/Sidebar";
import StickyMenu from "../common/StickyMenu";
import { Styles } from "../../app/Courses/styles/course.js";
import instructorService from "../../services/instructorService";
import { Link } from "react-router-dom";
import {
    Alert,
    Button,
    Col,
    Container,
    Modal,
    Nav,
    Row,
    Tab,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import SettingsPanel from "../../app/shared/SettingsPanel";
import Footer from "../../app/shared/Footer";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import service, { URI } from "../../services/service";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import UserService from "../../services/UserService";
import GlobalModal from "../../app/shared/GlobalModal.js";
import adminServices from "../../services/adminServices.js";
import FilterDataTable from "../../pages/instructor/FilterDataTable.js";
import DataTable from "react-data-table-component";

const languages = [
    { code: "en", name: "English", country_code: "gb" },
    { code: "hi", name: "Hindi", country_code: "in" },
];
export const LibraryContentRequestList = () => {

    const [getPendingRequest, setPendingRequest] = useState([])
    const [selectedRowsMap, setSelectedRowsMap] = useState({})
    // const [selectedRowsLength, setSelectedRowsLength] = useState([])

    const [clearRowsToggle, setClearRowsToggle] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false)
    const [filterText, setFilterText] = React.useState("");
    const [newContentRemarks, setNewContentRemarks] = useState('Access Deny!');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    useEffect(() => { console.log("getPendingRequest", getPendingRequest) }, [getPendingRequest])
    useEffect(() => {
        getPedingReqestsForLibrary()
    }, [])

    const toggleRemarksModal = () => {
        setRemarksModal(!remarksModal)
    }
    const getPedingReqestsForLibrary = useCallback(() => {
        adminServices.getPendingLibRequests().then((res) => {
            if (res.status === 200) {
                setPendingRequest([...(res.data)])
            }
        }).catch((err) => {
            console.log("Error", err)
        })
    }, [])

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            width: '100px',
            sortable: true,
        },
        {
            name: "Email Id",
            selector: row => `${row?.emailId}`,
            sortable: true,
            wrap: true,
            //width: '150px',
        },
        {
            name: "Library Name",
            selector: row => row?.libName,
            wrap: true,
            sortable: true,
        },
        {
            name: "Content Name",
            selector: row => row?.contentName,
            wrap: true,
            sortable: true,
        },
        {
            name: "Status",
            sortable: true,
            wrap: true,
            cell: (row) => <div>
                {
                    row?.status && <span className="text-success">Pending</span>
                }
            </div>
        }
    ];

    const filteredItemsFeedbackQuestion = getPendingRequest.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
            -1
    );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
    const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            }
        },
        headCells: {
            style: {
                fontSize: '17px',
                fontWeight: '500',
                paddingLeft: '0 8px',
                marginLeft: '10px',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                paddingLeft: '0 8px',
                marginLeft: '10px'
            },
        },
    };

    const handleSelectedRowsChange = ({ selectedRows }) => {
        let userIdToContentIdMap = selectedRows.reduce((acc, row) => {
            const { userId, contentId } = row
            if (acc[userId]) {
                acc[userId].push(contentId)
            } else {
                acc[userId] = [contentId];
            }
            return acc
        }, {})
        
        // setSelectedRowsLength(selectedRows)
        setSelectedRowsMap(userIdToContentIdMap)
        setClearRowsToggle(false);
    }

    const handleApproveLibContent = () => {
        if (Object.keys(selectedRowsMap).length === 0) {
            swal(`warning`, 'Select the Users for Content Approval!!', "warning");
        } else {
            adminServices.approveLibRequest(selectedRowsMap).then((res) => {
                if (res.status === 200) {
                    getPedingReqestsForLibrary()
                    swal(`success`, 'Successfully Approved!!', "success");
                    setSelectedRowsMap({})
                    setClearRowsToggle(!clearRowsToggle);

                }


            }).catch((err) => {
                swal(`error`, 'Internal Server Error!!', "error");
                console.log("Error ", err)
            })
        }
    }
    const handleRejectLibContent = () => {
        if (Object.keys(selectedRowsMap).length === 0) {
            swal(`warning`, 'Select the Users for Content Rejection!!', "warning");
        } else {
            const libData = {
                "remarks": newContentRemarks,
                "rejectLibList": selectedRowsMap
            }
            adminServices.rejectLibRequest(libData).then((res) => {
                if (res.status === 200) {
                    setRemarksModal(false)
                    getPedingReqestsForLibrary()
                    swal(`success`, 'Successfully Rejected!!', "success");
                    setSelectedRowsMap({})
                    setClearRowsToggle(!clearRowsToggle);

                }
            }).catch((err) => {
                swal(`error`, 'Internal Server Error!!', "error");
                console.log("Error ", err)
            })
        }
    }
    const handleReviewChange = (event) => {
        setNewContentRemarks(event.target.value);
    };
    return (
        <div className="container-scroller">

            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h4 className="page-title">Library Content Request List</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#!" onClick={(event) => event.preventDefault()}>
                                            Library
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Library Content Request List
                                    </li>
                                </ol>
                            </nav>
                        </div>


                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">

                                <div className="card-body">
                                    <div className="">
                                        <Button variant="success mr-2" onClick={handleApproveLibContent} disabled={Object.keys(selectedRowsMap).length === 0}>Approve</Button>
                                        <Button variant="danger" onClick={toggleRemarksModal} disabled={Object.keys(selectedRowsMap).length === 0}>Reject</Button>
                                    </div>
                                    {/* {selectedRowsLength.length > 0 &&
                                        <Alert className="my-2" key={"info"} variant={"info"}>
                                            {selectedRowsLength} rows selected!
                                        </Alert>
                                    } */}
                                    <DataTable
                                        columns={columns}
                                        data={filteredItemsFeedbackQuestion}
                                        defaultSortField="Name"
                                        defaultSortAsc={true}
                                        striped
                                        pagination
                                        highlightOnHover
                                        customStyles={customStyles}
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                        selectableRows
                                        onSelectedRowsChange={handleSelectedRowsChange}
                                        clearSelectedRows={clearRowsToggle}
                                    />
                                    {/* For debugging, you can log or display the map */}
                                    {/* <pre>{JSON.stringify(selectedRowsMap, null, 2)}</pre> */}
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                    {/* Remarks modal */}
                    <Modal
                        centered
                        show={remarksModal}
                        onHide={toggleRemarksModal}
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Remarks</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="drawer-footer">
                            <textarea
                                placeholder="Write your feedback..."
                                value={newContentRemarks}
                                onChange={handleReviewChange}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={handleRejectLibContent} disabled={Object.keys(selectedRowsMap).length === 0}>
                                Submit
                            </Button>
                            <Button variant="danger" onClick={toggleRemarksModal}>
                                Close
                            </Button>
                            {/* You can add additional buttons or actions here */}
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div >
    );
}
