import React, { useEffect, useState } from 'react'
import service from '../../../services/service';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import "./ContentReviews.css";
import { Rating } from 'react-simple-star-rating';
import swal from "sweetalert";

const ContentReviews = ({ selectedContent, setSelectedContent, contentRatingModalShow, setContentRatingModalShow }) => {

    let tenantId = 1;
    let reviewType = 2; //// Type 2 For Content Review

    // const [contentList, setContentList] = useState([]);
    // const [selectedContent, setSelectedContent] = useState({});
    const [contentRatingAndReviewList, setContentRatingAndReviewList] = useState([]);
    const [selectedRating, setSelectedRating] = useState({})

    // useEffect(() => {

    //     fetchContentListByUserIdandCourseId();
    // }, [userId, courseId])


    // const fetchContentListByUserIdandCourseId = () => {
    //     service.getAllContentsByInstructorAndCourse(userId, courseId)
    //         .then((res) => {
    //             console.log(res.data);
    //             setContentList(res.data);
    //         })
    //         .catch((err) => {

    //         })
    // }

    useEffect(() => {
        fetchContentRatingAndReview(selectedContent);
    }, [selectedContent]);


    const fetchContentRatingAndReview = (content) => {
        service.getRatingByItemIdReviewTypeTenantId(content.id, tenantId, reviewType)
            .then((res) => {
                console.log(res.data);
                setContentRatingAndReviewList(res.data);
            })
            .catch((err) => {

            })
    }

    const onRatingReviewSelect = (rating) => {
        setSelectedRating(rating);
    }

    const onCloseModal = () => {
        setContentRatingModalShow(false);
    }
    const handleOnDisableReview =async (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
           await service.disableRatatingAndReview(e.target.value, 1).then(async (res) => {
                if (res.status === 200) {
                    await swal('success', 'Rating and Review disabled successfully!!', "success");

                }
            })
        } else {
          await  service.disableRatatingAndReview(e.target.value, 0).then((res) => {
                if (res.status === 200) {
                    swal('success', 'Rating and Review enabled successfully!!', "success");

                }
            })

        }
        fetchContentRatingAndReview(selectedContent);
    }


    // console.log("userId: ", userId);
    // console.log("courseId: ", courseId);
    // console.log("selectedContent: ", selectedContent);

    return (
        <div className='all-content-review-main'>

            <Modal
                centered
                show={contentRatingModalShow}
                size="xl"
                backdrop="static"
                scrollable
            >
                <Modal.Header>
                    <h5>Rating and Reviews: {selectedContent.label}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='content-rating-list-main'>
                        {
                            contentRatingAndReviewList?.length > 0 ?
                                <div className='content-rating-list'>
                                    {contentRatingAndReviewList?.map((rating) => (
                                        <div
                                            className='content-rating-item'
                                            key={rating.reviewId}>
                                            <ListGroup>
                                                <ListGroup.Item
                                                    onClick={() => onRatingReviewSelect(rating)}
                                                >
                                                    <div className='content-rating-item-main'>
                                                        <div className='content-rating-item-main-left'>
                                                            <p>Learner: {rating.firstName}</p>
                                                            <Rating
                                                                transition={true}
                                                                allowFraction={true}
                                                                size={25}
                                                                initialValue={rating.rating}
                                                                readonly
                                                            />
                                                            <div className='bg-light p-2'>
                                                                <label className='mr-2'>Disable the review</label>
                                                                <input className='' type='checkbox' onClick={(e) => { handleOnDisableReview(e) }} value={rating?.reviewId} defaultChecked={rating?.reviewAllowed}/>
                                                            </div>
                                                        </div>
                                                        <div className='content-rating-item-main-right'>
                                                            <textarea
                                                                className='content-rating-item-textarea'
                                                                placeholder=''
                                                                value={rating.reviewText}
                                                                disabled
                                                            />
                                                        </div>

                                                    </div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div>
                                    No Rating and Reviews Found
                                </div>
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => onCloseModal()}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ContentReviews