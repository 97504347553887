import React, { Component, useEffect, useState } from 'react';
import Datas from '../data/hero/hero-slider.json';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UserService from '../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import './styles/ImageSlider.css';

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in'
    }
]

const ImageSlider = () => {

    useEffect(() => {
        UserService.generateToken();
    }, []);

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        cssEase: "linear"
    };

    return (
        <div className='img-slider-area'>
            <div className="img-slider-container">
                <Slider {...settings}>
                    {Datas.map(data => (
                        <div
                            key={data.id}
                            className="img-slide"
                        >
                            <img
                                src={process.env.PUBLIC_URL + `/assets/images/${data.backgroundImage}`}
                                className="slider-image"
                                alt={data.backgroundImage}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default ImageSlider