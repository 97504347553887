import React, { useState, useEffect, useMemo } from 'react';
import { BreadcrumbBox } from '../../../components/common/Breadcrumb.js';
import HeaderTwo from '../../../components/HeaderTwo.js';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import instructorService from '../../../services/instructorService.js';
import FilterDataTable from '../FilterDataTable.js';
import FooterTwo from '../../../components/FooterTwo.js';
import UserService from '../../../services/UserService.js';
import '../styles.css';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import md5 from 'md5';
import ViewPdf from '../ViewPdf.js';
import CryptoJS from 'crypto-js';
import { DMS, URI } from '../../../services/service.js';
import MediaContentView from '../../courses/components/MediaContentView.js';
import useNonce from '../../../helper/Nonce.js';
import Videojs from './video.js';
import axios from 'axios';
import { Styles } from './styles/NCSD.js'
import ReactPlayer from 'react-player';

const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        },
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            textTransform: 'uppercase',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
        },
    },
};

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in',
    },
];

// Modals and other components
const FileUploadModal = ({
    show,
    onHide,
    onSubmit,
    selectFile,
    errorMsg,
    htmlFileNames,
    fileOption,
    handleFileOptionChange,
    checkBox,
    toggleCheckBox,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
        selectFile(event);
    };

    const handleUpload = async () => {
        setLoading(true);
        await onSubmit(selectedFiles);
        setLoading(false);
    };

    return (
        <Modal centered show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('upload_documents_files')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div style={{ marginLeft: 10, color: 'red' }}>
                        <span>{t('upload_files')}</span>
                    </div>
                    <div style={{ marginLeft: 10, color: 'red' }}>
                        <span>{t('req_file')}</span>
                    </div>
                    <div className="mb-3 mt-3">
                        <label>{t('file_name')} : </label>
                        <input type="text" className="form-control" id="file_name" placeholder={t('pls_enter_file_name')} />
                        <span style={{ color: 'red' }}>{errorMsg.fileErr}</span>
                    </div>
                    <div className="mb-3 mt-3">
                        <label>{t('duration_minutes')} : </label>
                        <input type="number" className="form-control" id="duration" placeholder={t('duration_minutes')} />
                        <span style={{ color: 'red' }}>{errorMsg.durationErr}</span>
                    </div>
                    <div className="mb-3 mt-3">
                        <input type="file" className="form-control" onChange={handleFileChange} accept="*" id="file" />
                    </div>
                    {/* {selectedFiles && (
                        <div className="mb-3 mt-3">
                            <label>{t('scorm_zip')} &nbsp; </label>
                            <input
                                type="checkbox"
                                id="ScormCheckbox"
                                onClick={toggleCheckBox}
                                data-toggle="toggle"
                                data-onstyle="primary"
                            ></input>
                        </div>
                    )} */}
                    {checkBox && htmlFileNames.length > 0 && (
                        <div>
                            <p>{t('select_one_to_show_scorm_file')}:</p>
                            {htmlFileNames.map((data) => (
                                <div key={data}>
                                    <input type="radio" value={data} checked={fileOption === data} onChange={handleFileOptionChange} /> {data}
                                </div>
                            ))}
                            <br></br>
                        </div>
                    )}
                    {loading ? (
                        <button className="btn btn-success" disabled>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">{t('submit')}.</span>
                            </div>{' '}
                            {t('upload')}
                        </button>
                    ) : (
                        <button className="btn btn-success" disabled={!selectedFiles} onClick={handleUpload}>
                            {t('upload')}
                        </button>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} className="btn btn-danger">
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const FolderCreationModal = ({ show, onHide, onSubmit, errorMsg }) => {
    const { t } = useTranslation();
    // alert("foldercreation")
    // setTimeout(() => {
    //     console.log("show", show, onHide)
    // }, 100000);
    return (
        <Modal centered show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('new_folder_details')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('required_fields')} *
                <div className="mb-3 mt-3">
                    <label>{t('name')} : *</label>
                    <input type="text" minLength={2} maxLength={200} className="form-control" id="folderName" placeholder={t('enter_folder_name')} />
                    {/* <span style={{ color: 'red' }}>Enter valid folder name</span> */}
                </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
                <Button
                    variant="primary"
                    style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)' }}
                    onClick={onSubmit}
                >
                    {t('submit')}
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const ContentUpdateModal = ({ show, onHide, onSubmit, errorMsg, contentDetails }) => {
    const { t } = useTranslation();

    return (
        <Modal centered show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('update_file_details')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3 mt-3">
                    <label>{t('file_name')} : </label>
                    <input type="text" minLength={2} maxLength={250} className="form-control" defaultValue={contentDetails.contentName} id="update_file_name" placeholder={t('pls_enter_file_name')} />
                    <span style={{ color: 'red' }}>{errorMsg.fileErr}</span>
                </div>
                <div className="mb-3 mt-3">
                    <label>{t('duration')} : </label>
                    <input type="number" className="form-control" defaultValue={contentDetails.contentDuration} min="1" max="120" id="update_duration" placeholder={t('enter_duration')} />
                    <span style={{ color: 'red' }}>{errorMsg.durationErr}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} className="btn btn-primary">
                    {t('Updates')}
                </Button>
                <Button onClick={onHide} className="btn btn-danger">
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const FolderNameUpdateModal = ({ show, onHide, onSubmit, errorMsg, folderDetails }) => {
    const { t } = useTranslation();

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('update_folder_name')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3 mt-3">
                    <label>{t('folder_name')} : </label>
                    <input type="text" minLength={2} maxLength={200} className="form-control" defaultValue={folderDetails.dirName} id="folder_name" placeholder={t('enter_folder_name')} />
                    <span style={{ color: 'red' }}>{errorMsg.fileErr}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} className="btn btn-primary">
                    {t('update')}
                </Button>
                <Button onClick={onHide} className="btn btn-danger">
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const PreviewModal = ({ show, onHide, contentType, url, videoEncodeStatus }) => {
    console.log("PreviewModal", url)

    const { t } = useTranslation();
    // console.log("content type", contentType)
    // alert("content type")
    const handleContextMenu = (e) => {
        e.preventDefault();
    };

    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 1100,
        height: 800,
        controls: true,
        sources: [
            {
                src: `${url}`,
                type: 'video/mp4',
            },
        ],
    };
    const [contentUrl, setContentUrl] = useState('#');
    useEffect(() => {
        const fetchContent = async () => {
            if (url) {
                setContentUrl("#");
                switch (contentType) {
                    case 'mp4':
                    case 'png':
                    case 'jpeg':
                    case 'jpg':
                    case 'img':
                        try {
                            // const response = await axios.get(`${URI.SERVER?._DMS_URL}/${url}`, {
                            //     responseType: 'blob'
                            // });
                            let response = '';
                            if (contentType === "img") {
                                response = await axios.get(url, {
                                    responseType: 'blob'
                                });
                                const blobUrl = URL.createObjectURL(response.data);
                                setContentUrl(blobUrl);
                            }
                            else {
                                response = await axios.get(`${URI.SERVER?._DMS_URL}/${url}`, {
                                    responseType: 'blob'
                                });
                                const blobUrl = URL.createObjectURL(response.data);
                                setContentUrl(blobUrl);
                            }
                            // setContentType(nodeType);
                        } catch (error) {
                            console.error('Error fetching content:', error);
                            setContentUrl('#');
                        }
                        break;
                    default: break;

                }

            }
        };

        fetchContent();

        // Cleanup function
        return () => {
            if (contentUrl !== '#') {
                URL.revokeObjectURL(contentUrl);
            }
        };
    }, [url]);


    return (
        <Modal size="xl" centered show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {contentType === 'zip' ? (
                        <i className="fas fa-file-archive" style={{ fontSize: '25px', color: '#fdbf00' }}> Zip</i>
                    ) : contentType === 'pdf' ? (
                        <i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#b30b00' }}> PDF</i>
                    ) : contentType === 'jpg' ||
                        contentType === 'png' ||
                        contentType === 'jpeg' ? (
                        <i className="fas fa-file-image" style={{ fontSize: '25px', color: '#b2b1ff' }}> Image</i>
                    ) : contentType === 'html' ? (
                        <i className="fab fa-html5" style={{ fontSize: '25px', color: '#e54c21' }}> Html</i>
                    ) : contentType === 'ogg' ||
                        contentType === 'webm' ||
                        contentType === 'mp4' ? (
                        <i className="fas fa-file-video" style={{ fontSize: '25px', color: '#8cee02' }}> Video</i>
                    ) : contentType === 'scorm' ? (
                        <i className="far fa-file-archive" style={{ fontSize: '25px', color: 'green' }}> Scorm</i>
                    ) : contentType === 'youtube' ? (
                        <i className="fab fa-youtube" style={{ fontSize: '25px', color: 'green' }}> YouTube</i>)
                        : null}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {contentType === 'jpg' ||
                        contentType === 'png' ||
                        contentType === 'img' ||
                        contentType === 'jpeg' ? (
                        <img
                            // src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
                            key={contentUrl}
                            src={contentUrl}
                            width="100%"
                            height="100%"
                            alt='#'
                        />
                    ) : contentType === 'pdf' ? (
                        <div>
                            <ViewPdf pdfUrl={`${URI.SERVER?._DOMAIN_URL}/pdfViewer/${url}`} />
                        </div>
                    ) : contentType === 'mp4' ? (
                        videoEncodeStatus === 'success' ? (
                            <div>
                                <MediaContentView cmsUrl={url} />
                            </div>
                        ) : (
                            <div>
                                <video key={contentUrl} width="100%" height="100%" controls autoPlay>
                                    <source
                                        // src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
                                        src={contentUrl}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        )
                    ) : contentType === 'html' ? (
                        <iframe width="100%" height="800" src={`${url}`}></iframe>
                    ) : contentType === 'zip' ? (
                        <iframe width="100%" height="800" src={`${url}`}></iframe>
                    ) : contentType === 'scorm' ? (
                        <iframe width="100%" height="800" src={`${url}`}></iframe>
                    ) :
                        contentType === 'youtube' ? (
                            <ReactPlayer
                                url={url}
                                width="100%"
                                height="800px"
                                controls="true"
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1 },
                                    },
                                }}
                            />

                        ) : (
                            <p>{t('no_content_available')}</p>
                        )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

const ChildFolderCreationModal = ({ show, onHide, onSubmit, errorMsg }) => {
    const { t } = useTranslation();
    return (
        <Modal centered show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                {/* <Modal.Title>{t('new_child_folder_details')}</Modal.Title> */}
                <Modal.Title>Sub-folder Details</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                {t('required_fields')} *
                <div className="mb-3 mt-3">
                    <label>{t('name')} : *</label>
                    <input type="text" minLength={2} maxLength={200} className="form-control" id="childFolderName" placeholder={t('enter_folder_name')} />
                </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
                <Button
                    variant="primary"
                    style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)' }}
                    onClick={onSubmit}
                >
                    {t('submit')}
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


const CourseStructureDrive = () => {
    const { t } = useTranslation();
    const fetchAndSetNonce = useNonce();
    const [currentLanguage, setCurrentLanguage] = useState(languages.find((l) => l.code === (cookies.get('i18next') || 'en')));
    const [folderStructure, setFolderStructure] = useState([]);
    const [contentDetails, setContentDetails] = useState([]);
    const [errorMsg, setErrorMsg] = useState({ fileErr: '', durationErr: '' });
    const [isFolderCreationModalOpen, setFolderCreationModalOpen] = useState(false);
    const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
    const [isContentUpdateModalOpen, setContentUpdateModalOpen] = useState(false);
    const [isFolderNameUpdateModalOpen, setFolderNameUpdateModalOpen] = useState(false);
    const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const [selectedFolderName, setSelectedFolderName] = useState('');
    const [fileOption, setFileOption] = useState('none');
    const [checkBox, setCheckBox] = useState(false);
    const [htmlFileNames, setHtmlFileNames] = useState([]);
    const [selectedContent, setSelectedContent] = useState({ contentId: '', contentName: '', contentDuration: '' });
    const [previewContent, setPreviewContent] = useState({ contentType: '', url: '', videoEncodeStatus: null });
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [expandedFolders, setExpandedFolders] = useState({});
    const [isSubfolderSelected, setIsSubfolderSelected] = useState(false);
    const [isChildFolderCreationModalOpen, setChildFolderCreationModalOpen] = useState(false);


    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    useEffect(() => {
        UserService.generateToken();
        fetchFolderStructure();
    }, []);



    // Handlers for various modal submissions and content fetching...


    const fetchFolderStructure = async () => {
        const userId = UserService.getUserid();
        try {
            const response = await instructorService.getFolderStructure(userId);
            setFolderStructure(response.data);
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const handleChildFolderCreationModalSubmit = async () => {
        const folderName = document.getElementById('childFolderName').value;
        if (!folderName.match(/^[A-Za-z0-9&,.()\- ]{2,200}$/)) {
            return setErrorMsg({ ...errorMsg, fileErr: t('invalid_folder_name') });
        }
        const userId = UserService.getUserid();
        try {
            await fetchAndSetNonce();
            let data = { dirName: folderName, lastModifiedBy: userId, dirParentId: selectedFolderId }
            console.log("childdata", data)
            const response = await instructorService.createChildDirectory(data);
            if (response.status === 201 || response.status === 200) {
                await swal(t('created'), t('child_folder_created'), 'success');
                fetchFolderStructure();
                setChildFolderCreationModalOpen(false);
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const handleFolderCreationModalSubmit = async () => {
        // alert("create folder")
        const folderName = document.getElementById('folderName').value;
        if (!folderName.match(/^[A-Za-z0-9&,.()\- ]{2,200}$/)) {
            return setErrorMsg({ ...errorMsg, fileErr: t('invalid_folder_name') });
        }
        const userId = UserService.getUserid();
        try {
            await fetchAndSetNonce();
            const response = await instructorService.createDirectory({ dirName: folderName, lastModifiedBy: userId });
            if (response.status === 201) {
                await swal(t('created'), t('root_folder_created'), 'success');
                fetchFolderStructure();
                setFolderCreationModalOpen(false);
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const handleFileUploadModalSubmit = async (selectedFiles) => {
        const duration = document.getElementById('duration').value;
        const fileName = document.getElementById('file_name').value;
        if (!fileName.match(/^[A-Za-z0-9&,.()\- ]{2,200}$/)) {
            return setErrorMsg({ ...errorMsg, fileErr: t('invalid_file_name') });
        }
        if (!duration.match(/^[0-9]{1,3}$/) || duration == 0) {
            return setErrorMsg({ ...errorMsg, durationErr: t('invalid_duration') });
        }
        try {
            await fetchAndSetNonce();
            const sig = md5(selectedFiles[0].size.toString());
            const userId = UserService.getUserid();
            const response = await instructorService.fileUpload(
                selectedFiles[0],
                sig,
                userId,
                selectedFolderId,
                duration,
                fileName,
                fileOption,
                checkBox,
                (event) => { }
            );
            if (response.status === 200) {
                if (response.data === 'double-extension-file') {
                    swal(t('error'), t('double-ext-file'), 'error');
                } else if (response.data === 'file-sig-error') {
                    swal(t('error'), 'Invalid File Error', 'error');
                } else if (response.data === 'Uploaded Successfully') {
                    setErrorMsg({ ...errorMsg, fileErr: '', durationErr: '' });
                    await swal(t('uploaded'), response.data, 'success');
                } else {
                    await swal(t('msg'), response.data, 'info');
                }
                fetchContentDetails(selectedFolderId);
                setFileUploadModalOpen(false);
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const handleContentUpdateModalSubmit = async () => {
        const contentId = selectedContent.contentId;
        const fileName = document.getElementById('update_file_name').value;
        const duration = document.getElementById('update_duration').value;
        if (!fileName.match(/^[A-Za-z0-9&,.()\- ]{2,200}$/)) {
            return setErrorMsg({ ...errorMsg, fileErr: t('invalid_file_name') });
        }
        if (!duration.match(/^[0-9]{1,3}$/) || duration == 0) {
            return setErrorMsg({ ...errorMsg, durationErr: t('invalid_duration') });
        }
        try {
            const userId = UserService.getUserid();
            const response = await instructorService.fileCotentDetailsUpdate({
                contentId,
                contentName: fileName,
                contentDuration: duration,
            });
            if (response.status === 200) {
                setErrorMsg({ ...errorMsg, fileErr: '', durationErr: '' });
                await swal(t('Updates'), t('details_update_msg'), 'success');
                fetchContentDetails(selectedFolderId);
                setContentUpdateModalOpen(false);
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const handleFolderNameUpdateModalSubmit = async () => {
        const folderName = document.getElementById('folder_name').value;
        if (!folderName.match(/^[A-Za-z0-9&,.()\- ]{2,200}$/)) {
            return setErrorMsg({ ...errorMsg, fileErr: t('invalid_folder_name') });
        }
        try {
            const userId = UserService.getUserid();
            const response = await instructorService.folderNameUpdate({
                dirName: folderName,
                lastModifiedBy: userId,
                dirParentId: selectedFolderId,
            });
            if (response.status === 200) {
                setErrorMsg({ ...errorMsg, fileErr: '' });
                await swal(t('Updates'), t('folder_updated'), 'success');
                fetchFolderStructure();
                setFolderNameUpdateModalOpen(false);
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const fetchContentDetails = async (folderId) => {
        try {
            const userId = UserService.getUserid();
            const response = await instructorService.contentDetails(folderId, userId);
            setContentDetails(response.data);
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };



    const handleFolderClick = (folderId, folderName, isSubfolder = false) => {
        setSelectedFolderId(folderId);
        setSelectedFolderName(folderName);
        fetchContentDetails(folderId);

        // Toggle the expanded state of the folder
        setExpandedFolders(prevState => ({
            ...prevState,
            [folderId]: !prevState[folderId]
        }));

        // Set subfolder selection state
        setIsSubfolderSelected(isSubfolder);
    };



    const handleDeleteContent = async (contentId, contentType) => {
        // console.log("contenttupe",contentType)
        // alert("contenttype", contentType)
        try {
            const userId = UserService.getUserid();
            const response = await instructorService.checkContentStatus(contentId, contentType);
            if (response.data) {
                await swal(t('msg'), t('this_content_map_mydrive'), 'warning');
            } else {
                const isConfirmed = await swal({
                    title: t('r_u_sure'),
                    text: t('u_want_to_delete_content'),
                    icon: 'warning',
                    buttons: [t('no_cancel'), t('yes_delete')],
                    dangerMode: true,
                });
                if (isConfirmed) {
                    const deleteResponse = await instructorService.contentDelete(contentId);
                    if (deleteResponse.status === 200) {
                        await swal(t('deleted'), t('your_content_deleted'), 'success');
                        fetchContentDetails(selectedFolderId);
                    }
                }
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    const handleContentEdit = (contentId, contentName, contentDuration) => {
        setSelectedContent({ contentId, contentName, contentDuration });
        setContentUpdateModalOpen(true);
    };

    const handleContentPreview = async (contentType, url, encodeStatus, streamId, row) => {

        try {
            const response = await instructorService.contentAccess(`${URI.SERVER?._DMS_URL}${url}`);
            let finalUrl = '';
            if (contentType === 'pdf') {
                const encrypted = CryptoJS.AES.encrypt("/" + URI.SERVER._MEGH_API + "/" + response.data, "cdac@123").toString();
                const safeEncodedString = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
                finalUrl = URI.SERVER?._DMS + "/" + safeEncodedString;
            } else if (contentType === 'mp4') {
                if (encodeStatus === 'pending') {
                    finalUrl = response.data;
                } else if (encodeStatus === 'success') {
                    finalUrl = response.data;
                } else {
                    finalUrl = response.data;
                }
            } else {
                if (encodeStatus === 'success') {
                    const axiosResponse = await axios.get(response.data, {
                        headers: { Authorization: UserService.getToken() },
                        responseType: "arraybuffer",
                    });
                    finalUrl = `data:${axiosResponse.headers['content-type']};base64,${new Buffer(axiosResponse.data).toString('base64')}`;
                } else {
                    finalUrl = response.data;
                }
            }
            setPreviewContent({ contentType, url: finalUrl, videoEncodeStatus: encodeStatus });
            setPreviewModalOpen(true);
        } catch (error) {
            swal(`${t("error")}`, `${error} ${t("try_sometimes")}`, "error");
        }
    };

    const handleFileOptionChange = (event) => {
        setFileOption(event.target.value);
    };

    const toggleCheckBox = () => {
        setCheckBox(!checkBox);
    };


    const columns = [
        {
            name: 'Name',
            selector: (row) => row.contentName,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Type',
            selector: (row) => row.contentType,
            sortable: true,
        },
        {
            name: 'Duration',
            selector: (row) => row.contentDuration,
            sortable: true,
        },
        {
            name: 'Preview',
            sortable: true,
            cell: (row) => (
                <a href="#" onClick={() => handleContentPreview(row.contentType, row.shareUrl, row.encodeStatus, row.streamId, row)}>
                    {row.contentType === 'zip' ? (
                        <i className="fas fa-file-archive" style={{ fontSize: '25px', color: '#fdbf00' }}></i>
                    ) : row.contentType === 'pdf' ? (
                        <i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#b30b00' }}></i>
                    ) : row.contentType === 'jpg' ||
                        row.contentType === 'png' ||
                        row.contentType === 'jpeg' ? (
                        <i className="fas fa-file-image" style={{ fontSize: '25px', color: '#b2b1ff' }}></i>
                    ) : row.contentType === 'html' ? (
                        <i className="fab fa-html5" style={{ fontSize: '25px', color: '#e54c21' }}></i>
                    ) : row.contentType === 'ogg' ||
                        row.contentType === 'webm' ||
                        row.contentType === 'mp4' ? (
                        <i className="fas fa-file-video" style={{ fontSize: '25px', color: '#8cee02' }}></i>
                    ) : row.contentType === 'scorm' ? (
                        <i className="far fa-file-archive" style={{ fontSize: '25px', color: 'green' }}></i>
                    ) : null}
                </a>
            ),
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row) => (
                <div>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('delete')}</Tooltip>}>
                        <span className="d-inline-block">
                            <a href="#" onClick={() => handleDeleteContent(row.contentId, row.contentType)}>
                                <i className="fas fa-trash " style={{ fontSize: '20px' }}></i>
                            </a>
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('edit')}</Tooltip>}>
                        <span className="d-inline-block ">
                            <a href="#" onClick={() => handleContentEdit(row.contentId, row.contentName, row.contentDuration)}>
                                <i className="fas fa-edit  " style={{ fontSize: '20px', marginLeft: '20px' }}></i>
                            </a>
                        </span>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];

    const filteredItems = contentDetails.filter(
        (item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterDataTable onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const handleFolderEdit = (folderId, folderName) => {
        setSelectedFolderId(folderId);
        setSelectedFolderName(folderName);
        setFolderNameUpdateModalOpen(true);
    };

    const handleDeleteFolder = async (parentId, childId) => {
        try {
            const userId = UserService.getUserid();
            const response = await instructorService.directoryStatusCheck(childId, parentId);
            if (response.data.length === 0) {
                const isConfirmed = await swal({
                    title: t('r_u_sure'),
                    text: t('delete_folder_structure'),
                    icon: 'warning',
                    buttons: [t('no_cancel'), t('yes_delete')],
                    dangerMode: true,
                });
                if (isConfirmed) {
                    const data = { lastModifiedBy: userId, dirParentId: childId };
                    const deleteResponse = await instructorService.deleteDirectory(data);
                    if (deleteResponse.data === 'deleted successfully') {
                        await swal(t('Deleted!'), t('Your Folder has been deleted.'), 'success');
                        fetchFolderStructure();
                    }
                }
            } else {
                await swal(t('msg'), t('delete_content_then_folder'), 'warning');
            }
        } catch (error) {
            swal(t('error'), t('try_sometimes'), 'error');
        }
    };

    return (

        <div className="main-wrapper course-page">
            <HeaderTwo />
            <BreadcrumbBox title={t('course_Content_Drive')} />
            <Styles>
                <div className="main-content">
                    <div className="left-sidebar">
                        <h5 className=' rounded m-3 text-center '>
                            <span class="fs-4" style={{ marginTop: "10px" }}>
                                <i
                                    class="fas fa-hdd"
                                    style={{ fontSize: "25px", marginRight: "5px" }}
                                ></i>
                                {t("my_drive")}
                            </span>
                        </h5>
                        <div className="items">
                            {folderStructure.map((item, index) => (
                                <div key={index} className="item">
                                    <div>
                                        <span
                                            className={`onFolderhover ${selectedFolderId === item.Id ? 'activeFolder' : 'inActiveFolder'}`}
                                            onClick={() => handleFolderClick(item.Id, item.Name)}
                                        >
                                            <i
                                                className="fas fa-folder"
                                                style={
                                                    selectedFolderId === item.Id
                                                        ? { fontSize: '18px', color: 'white' }
                                                        : { fontSize: '18px', color: 'black' }
                                                }
                                            ></i>
                                            <span style={{ marginLeft: '10px' }}>{item.Name} &nbsp;&nbsp;</span>
                                            <span style={{ position: 'relative', float: 'right' }}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <a href="#" onClick={() => handleDeleteFolder(item.Id, item.Id)}>
                                                            <i className="fas fa-trash" style={{ fontSize: '16px' }}></i>
                                                        </a>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('edit')}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <a href="#" onClick={() => handleFolderEdit(item.Id, item.Name)}>
                                                            <i className="fas fa-edit" style={{ fontSize: '16px' }}></i>
                                                        </a>
                                                    </span>
                                                </OverlayTrigger>
                                            </span>
                                        </span>
                                        {expandedFolders[item.Id] && (
                                            <div style={{ marginLeft: '20px' }}>
                                                {item.Child.map((subItem, subIndex) => (
                                                    <div key={subIndex} className="item">
                                                        <div>
                                                            <span
                                                                className={`onFolderhover ${selectedFolderId === subItem.Id ? 'activeFolder' : 'inActiveFolder'}`}
                                                                onClick={() => handleFolderClick(subItem.Id, subItem.Name, true)}
                                                            >
                                                                <i
                                                                    className="fas fa-folder"
                                                                    style={
                                                                        selectedFolderId === subItem.Id
                                                                            ? { fontSize: '18px', color: 'white' }
                                                                            : { fontSize: '18px', color: 'black' }
                                                                    }
                                                                ></i>
                                                                <span style={{ marginLeft: '10px' }}>{subItem.Name} &nbsp;&nbsp;</span>
                                                                <span style={{ position: 'relative', float: 'right' }}>
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            <a href="#" onClick={() => handleDeleteFolder(item.Id, subItem.Id)}>
                                                                                <i className="fas fa-trash" style={{ fontSize: '16px' }}></i>
                                                                            </a>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('edit')}</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            <a href="#" onClick={() => handleFolderEdit(subItem.Id, subItem.Name)}>
                                                                                <i className="fas fa-edit" style={{ fontSize: '16px' }}></i>
                                                                            </a>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="center-content">
                        <div className="table-section ">
                            <nav className="navbar navbar-expand-lg navbar-light" >
                                <div className="navbar-nav">

                                    {(
                                        <button className="create-button" onClick={() => setFolderCreationModalOpen(true)}><i className="fas fa-folder-plus"></i> Create New</button>

                                    )}
                                    {!isSubfolderSelected && selectedFolderId !== null && (

                                        <button className="create-button" onClick={() => setChildFolderCreationModalOpen(true)}><i className="fas fa-folder-plus"></i> Create Sub-Folder</button>
                                    )}
                                    &nbsp;&nbsp;
                                    {(isSubfolderSelected || selectedFolderId !== null) &&

                                        <button className="create-button" onClick={() => setFileUploadModalOpen(true)}><i className="fas fa-upload"></i> Upload</button>
                                    }
                                </div>
                            </nav>
                        </div>

                        <div className="table-section mt-3">
                            <h6 className="section-title">Content Details</h6>
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                defaultSortField="Name"
                                defaultSortAsc={true}
                                striped
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                            />
                        </div>

                    </div>

                    {/* <div className="right-sidebar">
                    <h6>
                        {previewContent.contentType === 'zip' ? (
                            <i className="fas fa-file-archive" style={{ fontSize: '25px', color: '#fdbf00' }}> Zip</i>
                        ) : previewContent.contentType === 'pdf' ? (
                            <i className="fas fa-file-pdf" style={{ fontSize: '25px', color: '#b30b00' }}> PDF</i>
                        ) : previewContent.contentType === 'jpg' ||
                            previewContent.contentType === 'png' ||
                            previewContent.contentType === 'jpeg' ? (
                            <i className="fas fa-file-image" style={{ fontSize: '25px', color: '#b2b1ff' }}> Image</i>
                        ) : previewContent.contentType === 'html' ? (
                            <i className="fab fa-html5" style={{ fontSize: '25px', color: '#e54c21' }}> Html</i>
                        ) : previewContent.contentType === 'ogg' ||
                            previewContent.contentType === 'webm' ||
                            previewContent.contentType === 'mp4' ? (
                            <i className="fas fa-file-video" style={{ fontSize: '25px', color: '#8cee02' }}> Video</i>
                        ) : previewContent.contentType === 'scorm' ? (
                            <i className="far fa-file-archive" style={{ fontSize: '25px', color: 'green' }}> Scorm</i>
                        ) : null}
                    </h6>
                    <div className="file-preview">
                        <p>File Preview</p>
                        <div>
                            {previewContent.contentType === 'jpg' ||
                                previewContent.contentType === 'png' ||
                                previewContent.contentType === 'jpeg' ? (
                                <img src={`${previewContent.url}`} width="100%" height="100%"></img>
                            ) : previewContent.contentType === 'pdf' ? (
                                <div style={{ height: "200px" }}>

                                    <iframe width="100%" height="200" src={`${URI.SERVER?._DOMAIN_URL}/pdfViewer/${previewContent.url}`} type="application/pdf" embedded="true"></iframe>
                                </div>
                            ) : previewContent.contentType === 'mp4' ? (
                                <div key={previewContent.url} >
                                    <Videojs
                                        autoplay={false}
                                        controls={true}
                                        sources={[{ src: previewContent.url, type: 'video/mp4' }]}
                                    />
                                </div>
                            ) : previewContent.contentType === 'html' ? (
                                <iframe width="100%" height="800" src={`${previewContent.url}`}></iframe>
                            ) : previewContent.contentType === 'zip' ? (
                                <iframe width="100%" height="800" src={`${previewContent.url}`}></iframe>
                            ) : previewContent.contentType === 'scorm' ? (
                                <iframe width="100%" height="800" src={`${previewContent.url}`}></iframe>
                            ) : (
                                <p>{t('no_content_available')}</p>
                            )}
                        </div>
                    </div>
                    <div className="file-details">
                        <p>File name: {previewContent.fileName}</p>
                        <p>File type: {previewContent.contentType.toUpperCase()}</p>
                        <p>File size: {previewContent.fileSize}</p>
                        <p>Date created: {previewContent.dateCreated}</p>
                        <p>Last modified: {previewContent.lastModified}</p>
                        <p>Owner name: {previewContent.ownerName}</p>
                        <p>Permissions: {previewContent.permissions}</p>
                    </div>
                </div> */}


                </div>
            </Styles>
            <FolderCreationModal
                show={isFolderCreationModalOpen}
                onHide={() => setFolderCreationModalOpen(false)}
                onSubmit={handleFolderCreationModalSubmit}
                errorMsg={errorMsg}
            />
            <FileUploadModal
                show={isFileUploadModalOpen}
                onHide={() => setFileUploadModalOpen(false)}
                onSubmit={handleFileUploadModalSubmit}
                selectFile={(e) => { }}
                errorMsg={errorMsg}
                htmlFileNames={htmlFileNames}
                fileOption={fileOption}
                handleFileOptionChange={handleFileOptionChange}
                checkBox={checkBox}
                toggleCheckBox={toggleCheckBox}
            />
            <ContentUpdateModal
                show={isContentUpdateModalOpen}
                onHide={() => setContentUpdateModalOpen(false)}
                onSubmit={handleContentUpdateModalSubmit}
                errorMsg={errorMsg}
                contentDetails={selectedContent}
            />
            <FolderNameUpdateModal
                show={isFolderNameUpdateModalOpen}
                onHide={() => setFolderNameUpdateModalOpen(false)}
                onSubmit={handleFolderNameUpdateModalSubmit}
                errorMsg={errorMsg}
                folderDetails={{ dirName: selectedFolderName }}
            />
            <PreviewModal
                show={isPreviewModalOpen}
                onHide={() => setPreviewModalOpen(false)}
                contentType={previewContent.contentType}
                url={previewContent.url}
                videoEncodeStatus={previewContent.videoEncodeStatus}
            />
            <ChildFolderCreationModal
                show={isChildFolderCreationModalOpen}
                onHide={() => setChildFolderCreationModalOpen(false)}
                onSubmit={handleChildFolderCreationModalSubmit}
                errorMsg={errorMsg}
            />

            <FooterTwo />
        </div>

    );
};

export default CourseStructureDrive;
