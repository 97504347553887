import React from 'react';
import { MagnifyingGlass, Puff, Circles, DNA, Bars } from 'react-loader-spinner'
import { Styles } from "../../components/styles/nomination.js";


const GlobalModal = ({ loadModal }) => {
    return (

        <Styles>
            <div className='modal-loader-position'>
                {/* <MagnifyingGlass
                    visible={loadModal}
                    height="80"
                    width="80"
                    ariaLabel="MagnifyingGlass-loading"
                    wrapperStyle={{}}
                    wrapperClass="MagnifyingGlass-wrapper"
                    glassColor='#c0efff'
                    color='#063970'
                /> */}
                {/* <Puff
                    height="80"
                    width="80"
                    radius={5}
                    color="#063970"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loadModal}
                /> */}
                {/* <Circles
                    height="60"
                    width="60"
                    color="#063970"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loadModal}
                /> */}
                {/* <DNA
                    visible={loadModal}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                    isible={loadModal}
                /> */}
                <Bars
                    height="60"
                    width="60"
                    color="#063970"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loadModal} />
            </div>
        </Styles>


    )
}

export default GlobalModal
