import React, { useState } from 'react';
import "./ContentDeliveryToolbar.css";

const ContentDeliveryToolbar = ({ selectedContent, toggleRatingSidebar, toggleNotesSidebar, toggleToolBar, toolBarOpen, setToolBarOpen }) => {


    console.log("toolBarOpen: ", toolBarOpen);
    return (
        (Object.keys(selectedContent).length !== 0) && (selectedContent?.activityProgressforDependency === 100) && (
            <div className='content-delivery-toolbar-main'>
                <div
                    className={`content-delivery-toolbar-float-btn ${toolBarOpen ? `open` : ``}`}
                    onClick={toggleToolBar}
                >
                    <i className={`fa fa-2x ${toolBarOpen ? 'fa-caret-down' : 'fa-caret-up'}`} aria-hidden="true"></i>
                </div>
                <div className={`content-delievry-toolbar-area ${toolBarOpen ? `open` : ``}`}>
                    {toolBarOpen && (
                        <div className={`content-delievry-toolbar-buttons ${toolBarOpen ? `open` : ``}`}>
                            <button
                                onClick={toggleRatingSidebar}
                            >
                                <i class="fa fa-2x fa-star-half-o" aria-hidden="true"></i>
                            </button>
                            <button
                                onClick={toggleNotesSidebar}
                            >
                                <i class="fa fa-2x fa-sticky-note" aria-hidden="true"></i>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    )
}

export default ContentDeliveryToolbar