import React, { useEffect, useState, Component, useContext } from 'react';
import Header from './components/Header';
import HeroSlider from './components/HeroSlider';
import IconBox from './components/IconBox';
import AboutUs from './components/AboutUs';
import CourseFilter from './components/CourseFilter';
import TestimonialSlider from './components/TestimonialSlider';
import FaqEvent from './components/FaqEvent';
import TeamSlider from './components/TeamSlider';
import HelpArea from './components/HelpArea';
import HomeBlog from './components/HomeBlog';
import CampusTour from './components/CampusTour';
import NewsletterForm from './components/NewsletterForm';
import Footer from './components/Footer';
import UserService from './services/UserService';
import service from './services/service';
import Setting from './components/common/Setting';
import FooterTwo from './components/FooterTwo';
import { GlobalContext } from './pages/courses/contentdelivery/contexts/GlobalContext';
import swal from 'sweetalert';
import ImageSlider from './components/ImageSlider';


function HomeOne(props) {

    const { setGlobalContextState } = useContext(GlobalContext)

    useEffect(() => {
        UserService.generateToken();
        const arcane = props.match.params.arcane
        setGlobalContextState(prevState => { return { ...prevState, arcane } })
        sessionStorage.setItem("arcane", arcane)
    }, []);

    useEffect(() => {
        let browser = UserService.FireFoxModal();
        if (browser === "Firefox") {
            testSwal();
        }
    }, []);

    const testSwal = () => {

        swal({
            title: 'Browser Information',
            text: 'Recommended to use google chrome, Brave and Microsoft IE/Edge browsers only!',
            icon: 'warning',
            buttons: false, // Hide the default "OK" button
            timer: 9000000, // Automatically close the alert after 3000 milliseconds (3 seconds)
        }).then(() => {
            // Code to execute after the alert is closed (e.g., navigate to another page)
            testSwal();
            // console.log('Alert closed');
        })

    }



    return (
        <div className="main-wrapper" >

            {/* Header */}
            < Header />

            {/* < Setting /> */}

            {/* Hero Slider */}
            {/* < HeroSlider /> */}

            <ImageSlider />

            {/* Icon Box */}
            < IconBox />

            {/* About Area */}
            < AboutUs />

            {/* Course Filter */}
            < CourseFilter />

            {/* Testimonial Slider */}
            < TestimonialSlider />

            {/* Faq & Event Area */}
            < FaqEvent />

            {/* Team Slider */}
            {/* < TeamSlider /> */}

            {/* Help Area */}
            {/* < HelpArea /> */}

            {/* Blog Area */}
            {/* < HomeBlog /> */}

            {/* Campus Tour */}
            {/* < CampusTour /> */}

            {/* Newsletter Form */}
            {/* < NewsletterForm /> */}

            {/* Footer */}
            < FooterTwo />
        </div >
    );
}

export default HomeOne;

