import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

    .dashboard-page {
        .dashboard-page-area {
            padding : 10px 5px;
            color      : ${colors.blue};

        }
        @media(max-width: 767px) {
            .dashboard-page-area {
                padding : 10px 5px;
                
    
            }
        }
        
    }
`;