import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`

.custom-icon-col-height {
  width : 100%;
  height : 100px;
  
}
.info-box-icon {
  font-size : 55px;
 
}
.info-box-text-size{
  font-size : 18px;
  padding : 15px;
}
.cutom-shadow:hover
{
 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.disabled-row {
  cursor: not-allowed;
  opacity: 0.5; /* You can adjust the opacity to visually indicate the row is disabled */
}
.custom-single-select {
  background-color: #ffffff;
  padding: 10px;
}

.custom-single-select.border-danger {
  border-color: red;
}

.custom-single-select option {
  color: #333333;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
  font-size : 14px;
}

.custom-multiselect {
  width: 100%; /* Set the width of the MultiSelect component */
  border: 0px solid #ccc;
  border-radius: 5px;
  margin-bottom : 5px;
 
}

/* Styling for the dropdown options */
.custom-multiselect .dropdown-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styling for the selected option tags */
.custom-multiselect .item-tag {
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
  padding: 2px 5px;
  margin: 2px;
}


/* Styling for the search input */
.custom-multiselect input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
}

/* Styling for the dropdown arrow icon */
.custom-multiselect svg {
  fill: #007bff;
}

.modal-loader{
  position: absolute;
  width : 100%;
  height : 100%;
  background-color: #ffffff80;
  z-index: 2;
}
.modal-loader-position{
  position: absolute;
  top : 40%;
  left : 50%;
  
  z-index: 3;
}




`;