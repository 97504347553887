import React, { Component, useEffect, useState } from 'react';
import Datas from '../data/icon-box/icon-box.json';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/iconBox.css';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import UserService from '../services/UserService';
import CountUp from 'react-countup';
import service from '../services/service.js';

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]

function IconBox() {

    const [getAboutState, setAboutState] = useState({
        isOpen: false,
        coursesCount: 0,
        learnerCount: 0,
        instructorCount: 0
    });

    useEffect(() => {
        service.getNumberOfCounts()
            .then(res => {
                setAboutState({ coursesCount: res.data.courseCount, learnerCount: res.data.learnerCount, instructorCount: res.data.instructorCount })
            })
    }, [])


    useEffect(() => {
        UserService.generateToken();
    }, []);

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])




    return (
        < section className="icon-box-area" >
            <Container >
                <Row>
                    <Col>
                        <div className="full-icon-box d-flex align-items-center justify-content-center">
                            <div className="icon-box d-flex flex-column justify-content-center" style={{ top: "-85px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: "10px" }}>
                                <img
                                    src={process.env.PUBLIC_URL + `/assets/images/policeman.png`}
                                    className="slider-image"
                                    alt="Learners image"
                                    style={{ width: "80px", marginBottom: "5px" }}
                                />
                                <div className="" style={{ backgroundColor: "#fdd8d6", padding: "10px", paddingInline: "50px", borderRadius: "40px" }}>
                                    <h3 style={{ color: "#EE665D", fontWeight: "bold" }}><CountUp end={getAboutState.learnerCount} duration={5} delay={1.5} /> + </h3>
                                    {/* <CountUp end={getAboutState.learnerCount} duration={5} delay={1.5} />   */}
                                </div>
                                <div className="box-title" style={{ marginTop: "0px", paddingTop: "5px" }}>
                                    <h6>{t('learners')}</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="full-icon-box d-flex align-items-center justify-content-center">
                            <div className="icon-box d-flex flex-column justify-content-center" style={{ top: "-85px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: "10px" }}>
                                <img
                                    src={process.env.PUBLIC_URL + `/assets/images/teacher.png`}
                                    className="slider-image"
                                    alt="instructor image"
                                    style={{ width: "80px", marginBottom: "5px" }}
                                />
                                <div className="" style={{ backgroundColor: "#F2D2FF", padding: "10px", paddingInline: "50px", borderRadius: "40px" }}>
                                    <h3 style={{ color: "#82469A", fontWeight: "bold" }}><CountUp end={getAboutState.instructorCount} duration={5} delay={1.5} /> + </h3>
                                    {/* <CountUp end={getAboutState.instructorCount} duration={5} delay={1.5} />  */}
                                </div>
                                <div className="box-title" style={{ marginTop: "0px", paddingTop: "5px" }}>
                                    <h6>{t('instructors')}</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="full-icon-box d-flex align-items-center justify-content-center">
                            <div className="icon-box d-flex flex-column justify-content-center" style={{ top: "-85px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: "10px" }}>
                                <img
                                    src={process.env.PUBLIC_URL + `/assets/images/book.png`}
                                    className="slider-image"
                                    alt="books image"
                                    style={{ width: "80px" }}
                                />
                                <div className="" style={{ backgroundColor: "#b7dfff", padding: "10px", paddingInline: "50px", borderRadius: "40px" }}>
                                    <h3 style={{ color: "#2c97ea", fontWeight: "bold" }}><CountUp end={getAboutState.coursesCount} duration={5} delay={1.5} /> + </h3>
                                    {/* <CountUp end={getAboutState.coursesCount} duration={5} delay={1.5} />  */}
                                </div>
                                <div className="box-title" style={{ marginTop: "0px", paddingTop: "5px" }}>
                                    <h6>{t('courses')}</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default IconBox