import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import StickyMenu from '../../components/common/StickyMenu';
import adminServices from '../../services/adminServices';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import service from '../../services/service';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in'
    },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]


const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900px',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500px',
            //paddingLeft: '0 8px',
            //marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};

export default function ManageEstablishment() {

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {
        UserService.generateToken();
    }, []);



    const [serviceDistrict, setServiceDistrict] = useState([]);
    const [newServiceDistrictName, setNewServiceDistrictName] = useState("");
    const [serviceDistrictId, setServiceDistrictId] = useState();
    const [getDeleteId, setDeleteId] = useState("");
    const [getEditId, setEditId] = useState("");

    const [deleteLoading, setDeleteLoading] = useState({
        isLoading: false
    })

    const [editLoading, setEditLoading] = useState({
        isLoading: false
    })

    useEffect(() => {
        getAllServiceDistrict();
    }, [])

    const getAllServiceDistrict = () => {
        service.RegisterServiceDistrict()
            .then((res) => {
                // console.log(res.data);
                setServiceDistrict(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const onClickDeleteButton = (serviceDistrictId) => {
        setDeleteId(serviceDistrictId);
        setDeleteLoading({ isLoading: true });
        adminServices.deleteServiceDistrict(serviceDistrictId).then((resp) => {
            console.log(resp.status);
            if (resp.status == 200) {
                swal(`${t('success')}`, `${t('serviceDistrict_deleted')}`, 'success')
                getAllServiceDistrict();
                setDeleteLoading({ isLoading: false });
            }
        }).catch((err) => {
            setDeleteLoading({ isLoading: false });
            // console.log(err.response.status)
            if (err.response.status == 404) {
                setDeleteLoading({ isLoading: false });
                swal(`${t('not_found')}`, "NOT FOUND", 'error')
            }
            else if (err.response.status == 400) {
                setDeleteLoading({ isLoading: false });
                swal(``, "Already in use by users, Cannot Delete", 'warning')
            } else {
                setDeleteLoading({ isLoading: false });
                swal(`Error`, err.response.data, 'error')
            }
        })
    }


    const onClickUpdateButton = () => {
        const check = validate();
        setEditLoading({ isLoading: true });
        if (check) {
            const serviceDistrict = {
                serviceDistrictId: serviceDistrictId,
                serviceDistrictName: newServiceDistrictName,
            }
            adminServices.updateServiceDistrict(serviceDistrict)
                .then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        swal(`${t('success')}`, res.data, 'success')
                        getAllServiceDistrict();
                        setAddModalShow(false);
                        setEditLoading({ isLoading: false });
                    }
                }).catch((err) => {
                    setDeleteLoading({ isLoading: false });
                    console.log(err.response)
                    if (err.response.status == 404) {
                        setEditLoading({ isLoading: false });
                        swal(`${t('not_found')}`, err.response.data, 'error')
                    }
                    else if (err.response.status == 400) {
                        setEditLoading({ isLoading: false });
                        swal(``, err.response.data, 'warning')
                    } else {
                        setEditLoading({ isLoading: false });
                        swal(`Error`, err.response.data, 'error')
                    }
                })
        }
    }

    const onClickEditButton = (serviceDistrictId) => {

        setEditId(serviceDistrictId);
        setEditLoading({ isLoading: true });

        adminServices.getServiceDistrictById(serviceDistrictId)
            .then((resp) => {
                setEditLoading({ isLoading: false });
                setNewServiceDistrictName(resp.data.serviceDistrictName)
                setServiceDistrictId(resp.data.serviceDistrictId);
                setAddModalShow(true);
            }).then((err) => {
                setEditLoading({ isLoading: false });
                //console.log(err)
            })

    }


    const ServiceDistrictColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            wrap: true,
            width: "250px",

        },
        {
            name: "Service District/Establishment",
            selector: row => row.serviceDistrictName,
            width: "260px",
            wrap: true,

        },
        {
            name: "Action",
            selector: row => <>
                <Button style={{ marginRight: "5px" }} onClick={() => { onClickEditButton(row.serviceDistrictId) }}>
                    {editLoading.isLoading ? getEditId === row.serviceDistrictId ? (<> {t('loading')}</>) : (<>  {t('edit')} </>) : (<> {t('edit')} </>)}
                </Button>       <Button className="btn btn-danger" onClick={() => { onClickDeleteButton(row.serviceDistrictId) }}>
                    {deleteLoading.isLoading ? getDeleteId === row.serviceDistrictId ? (<>{t('loading')}</>) : (<>  {t('delete')}  </>) : (<> {t('delete')}  </>)}
                </Button>
            </>,
            width: "250px",
            right: true,

        }
    ];

    const [addModalShow, setAddModalShow] = useState(false);
    const onClickAdd = () => {
        setServiceDistrictId();
        setNewServiceDistrictName();
        setAddModalShow(true);
    }

    const onChangeHandler = (e) => {
        setNewServiceDistrictName(e.target.value)
    }

    const [serviceDistrictNameError, setServiceDistrictNameError] = useState();

    const validate = () => {
        if (!newServiceDistrictName) {
            setServiceDistrictNameError(t('please_enter_serviceDistrict_name'))
            return false;
        }
        else if (newServiceDistrictName.length > 50) {
            setServiceDistrictNameError(t('text_less_than_50'))
            return false;
        }
        else if (newServiceDistrictName.length < 3) {
            setServiceDistrictNameError(t('text_greater_than_3'))
            return false;
        }
        else if (!newServiceDistrictName.match(/^[a-zA-Z0-9 ]+$/)) {
            setServiceDistrictNameError(t('special_charater_not_allowed'))
            return false;
        }
        else {
            setServiceDistrictNameError("")
            return true;
        }
    }

    const onClickAddServiceDistrict = () => {
        const check = validate()
        if (check) {
            console.log(newServiceDistrictName);
            adminServices.addServiceDistrict(newServiceDistrictName)
                .then((res) => {
                    // console.log(res.data);
                    if (res.status == 200) {
                        swal(`${t('success')}`, res.data, 'success')
                        getAllServiceDistrict();
                        setAddModalShow(false)
                    }
                }).catch((err) => {
                    //console.log(err)
                    if (err.response.status == 400) {
                        swal(``, err.response.data, 'warning')
                        setAddModalShow(false)
                    } else if (err.response.status == 500) {
                        swal(`Error`, err.response.data , 'error')
                        setAddModalShow(false)
                    } else {
                        swal("Error", "Some Error Has occured" , 'error')
                        setAddModalShow(false)
                    }
                })
        }
    }

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">{t('manage_serviceDistrict')}</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('registration_details')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('manage_serviceDistrict')}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div class="card">
                                <Col lg="12">
                                    <Row >
                                        <Col>
                                            <div>
                                                <br></br>
                                                <Card>
                                                    <div>
                                                        <div className="col-md-12 text-right w-100 p-0">
                                                            <Button className='btn' onClick={onClickAdd} style={{ width: "60px" }}> {t('add')} </Button>
                                                        </div>
                                                        <div className="row pb-5">
                                                            <div className="col-md-12 text-center mb-3">
                                                                <h4>{t('serviceDistrict_list')}</h4>
                                                            </div>
                                                        </div>
                                                        <div className='pt-3'>
                                                            <DataTable
                                                                columns={ServiceDistrictColumns}
                                                                data={serviceDistrict}
                                                                pagination
                                                                customStyles={customStyles}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                                <br></br>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal centered show={addModalShow} onHide={() => { setAddModalShow(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title serviceDistrictId="contained-modal-title-vcenter" style={{ alignContent: "center" }}>{t('add_serviceDistrict')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="mb-3 mt-3">
                        <label for="name">{t('name')} : *</label>
                        <input type="text" minLength={2} maxLength={50} class="form-control" placeholder={t('please_enter_serviceDistrict_name')} onChange={onChangeHandler} value={newServiceDistrictName} />
                        {
                            serviceDistrictNameError
                                ?
                                <div className="alert alert-danger mt-2">
                                    {serviceDistrictNameError}
                                </div>
                                :
                                <>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    {
                        serviceDistrictId
                            ?
                            <Button variant="secondary" onClick={onClickUpdateButton}> {t('update_serviceDistrict')} </Button>
                            :
                            <Button variant="secondary" onClick={onClickAddServiceDistrict}>{t('add_serviceDistrict')} </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
}
