import axios from "axios"
import { URI } from "./service";
console.log("loading learner service")

class learnerService {

    getPublishCourses() {
        return axios.get(`${URI.SERVER?._COURSE_URL}/courseOrganizer/` + "public/getPublishCourses");
    }

    getCourseMetadataById(courseId) {
        return axios.get(`${URI.SERVER?._COURSE_URL}/courseOrganizer/` + "public/getCourseMetadata/" + courseId);
    }

    getCourseStructureById(courseId) {
        return axios.get(`${URI.SERVER?._COURSE_URL}/courseOrganizer/` + "public/getCourseStructure/" + courseId);
    }

    getLibraryStructureById(courseId) {
        return axios.get(`${URI.SERVER?._COURSE_URL}/courseOrganizer/` + "api/getPubLibraryStructure/" + courseId);
    }

    getLibraryStructureForAdminById(courseId) {
        return axios.get(`${URI.SERVER?._COURSE_URL}/courseOrganizer/` + "api/getInstLibraryStructure/" + courseId);
    }


    getUserEnrolledByCourse(courseId, tenantId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api/' + "getCourseEnrolledLearners/" + courseId + "/" + tenantId);
    }

    getCourseInstructors(courseId, tenantId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api/' + "getCourseInstructors/" + courseId + "/" + tenantId);
    }

    getAssignedQuizzesStatus(userId, courseId) {
        return axios.get(URI.SERVER?._QUIZRESULT + '/assign/get/getAssignedQuizzesWithStatusByUserIdAndCourseId' + "/" + userId + "/" + courseId);
    }

    toGenerateCertificate(userId, courseId, tenantId) {

        return axios.get(URI.SERVER?._CERTIFICATION + '/certificate/api/gencert/' + userId + '/' + courseId + '/' + tenantId);
    }

    toDownloadCertificate(courseId, fileCode) {

        let url = URI.SERVER?._CERTIFICATION + '/certificate/api/downloadFile/' + courseId + '/' + fileCode;
        return axios.get(url, { responseType: 'arraybuffer' });
    }

    certificateVerification(certificateId) {
        return axios.get(URI.SERVER?._CERTIFICATION + '/certificate/public/verifycertificate?certificateid=' + certificateId);
    }

    getLearnerActivityLog(userId, fromDate, toDate) {
        return axios.get(URI.SERVER?._LEARNING_ANALYTICS + '/la_an/api/' + `activitylogbydaterange/${userId}?fromdate=${fromDate}&todate=${toDate}`)
    }

    getLearnerTimeSpend(userId, courseId, fromDate, toDate) {
        return axios.get(URI.SERVER?._LEARNING_ANALYTICS + '/la_an/api/' + `timespentresbydaterange/${userId}/${courseId}?fromdate=${fromDate}&todate=${toDate}`)
    }

    getLearnerContentAccessLog(userId, courseId, fromDate, toDate) {
        return axios.get(URI.SERVER?._LEARNING_ANALYTICS + '/la_an/api/' + `contentaccessbydaterange/${userId}/${courseId}?fromdate=${fromDate}&todate=${toDate}`)
    }

    getUserTenantCourseDetails(courseId, tenantId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + `/courseCatalogue/api/getUserTenantCourseDetails/${courseId}/${tenantId}`)
    }

}
export default new learnerService