import React, { useEffect, useState, useCallback, useMemo } from "react";
import Navbar from "../../app/shared/Navbar";
import Sidebar from "../../app/shared/Sidebar";
import StickyMenu from "../common/StickyMenu";
import { Styles } from "../../app/Courses/styles/course.js";
import instructorService from "../../services/instructorService";
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Nav,
    Row,
    Tab,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import SettingsPanel from "../../app/shared/SettingsPanel";
import Footer from "../../app/shared/Footer";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import service, { URI } from "../../services/service";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import UserService from "../../services/UserService";
import GlobalModal from "../../app/shared/GlobalModal.js";

const languages = [
    { code: "en", name: "English", country_code: "gb" },
    { code: "hi", name: "Hindi", country_code: "in" },
];

function AdminViewAllLibrary() {
    const [courseData, setCourseData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [loadingStates, setLoadingStates] = useState({}); // Individual loading states for courses
    const postsPerPage = 8;
    const [getEnableLoader, setEnableLoader] = useState(false)
    const { t } = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const currentLanguage = useMemo(() => languages.find((l) => l.code === currentLanguageCode), [currentLanguageCode]);

    const value = useSelector((state) => state.inputValue);

    useEffect(() => {
        UserService.generateToken();
        fetchCourseData();
        document.body.dir = currentLanguage.dir || "ltr";
        document.title = t("app_title");
    }, [currentLanguage, t]);

    const fetchCourseData = useCallback(async () => {
        try {
            setEnableLoader(true)
            const result = await service.getAllLibraryForAdmin();
            setEnableLoader(false)
            setCourseData(result.data);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const filteredCourseData = useMemo(() => {
        if (value) {
            return courseData.filter(course =>
                course.courseName.toLowerCase().includes(value.toLowerCase())
            );
        }
        return courseData;
    }, [courseData, value]);

    const currentPageCourses = useMemo(() => {
        const start = pageNumber * postsPerPage;
        return filteredCourseData.slice(start, start + postsPerPage);
    }, [filteredCourseData, pageNumber, postsPerPage]);

    const pageCount = useMemo(() => Math.ceil(filteredCourseData.length / postsPerPage), [filteredCourseData, postsPerPage]);

    const changePage = useCallback(({ selected }) => setPageNumber(selected), []);

    const imageUrls = useCallback((url) => {
        if (!url) return "";
        return `${URI.SERVER?._COURSE_URL}/${url.replace(/\\/g, "\\\\")}`;
    }, []);

    // Handle individual loading states for actions (approve/reject)
    const handleAction = useCallback(async (courseId, action) => {
        setLoadingStates((prevState) => ({ ...prevState, [courseId]: true })); // Set loading state for the courseId
        try {
            const actionMap = {
                approve: instructorService.LibraryPublish,
                reject: instructorService.libraryUnPublish,
            };
            const response = await actionMap[action](courseId);
            if (response.status === 200) {
                const message = action === "approve" ? t("library_published") : t("library_unpublished");
                swal(t("successfully"), message, "success");
                fetchCourseData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingStates((prevState) => ({ ...prevState, [courseId]: false })); // Reset loading state for the courseId
        }
    }, [fetchCourseData, t]);

    return (
        <div className="container-scroller">
            {
                getEnableLoader &&
                <div className='modal-loader'>
                    <GlobalModal showModal={getEnableLoader} />
                </div>

            }
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h4 className="page-title">All Library</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#!" onClick={(event) => event.preventDefault()}>
                                            Library
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        All Library
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <Container className="bg-light p-md-5" fluid>
                            <Tab.Container defaultActiveKey="all">
                                <Styles>
                                    <div className="course-tab-list">
                                        <Nav className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="all">{t("all")}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="published">{t("published_library")}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="reject">{t("reject_library")}</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Styles>

                                <Tab.Content>
                                    <Tab.Pane eventKey="all">
                                        <Row className="h-100">
                                            {currentPageCourses.map((course) => (
                                                <Col
                                                    key={course.courseId}
                                                    xl={2}
                                                    lg={4} // 4 columns on large screens
                                                    md={4} // 3 columns on medium screens
                                                    sm={6} // 2 columns on small screens
                                                    xs={12} // 1 column on extra small (mobile) screens
                                                    className="rounded my-3"
                                                >
                                                    <div className="card text-center shadow">
                                                        <div className="overflow">
                                                            <img src={imageUrls(course.courseImage)} alt="" className="card-img-top card-img-top " height={200} />
                                                        </div>
                                                        <div className="card-body text-dark w-100 h-100">
                                                            <h4 className="card-title">{course.courseName}</h4>
                                                            <p className="card-text text-secondary">
                                                                {course.status === "R" || course.status === "N" ? (
                                                                    <div className="d-flex p-1">
                                                                        <Button
                                                                            className="btn btn-success flex-grow mr-1"
                                                                            onClick={() => handleAction(course.courseId, "approve")}
                                                                            disabled={loadingStates[course.courseId]} // Show loading state for individual course
                                                                        >
                                                                            {loadingStates[course.courseId] ? t("loading") : t("approve")}
                                                                        </Button>
                                                                        <Button
                                                                            className="btn btn-danger flex-grow"
                                                                            onClick={() => handleAction(course.courseId, "reject")}
                                                                            disabled={loadingStates[course.courseId]} // Show loading state for individual course
                                                                        >
                                                                            {loadingStates[course.courseId] ? t("loading") : t("reject")}
                                                                        </Button>
                                                                    </div>
                                                                ) : course.status === "P" ? (
                                                                    <Button
                                                                        className="btn btn-danger w-100"
                                                                        onClick={() => handleAction(course.courseId, "reject")}
                                                                        disabled={loadingStates[course.courseId]} // Show loading state for individual course
                                                                    >
                                                                        {loadingStates[course.courseId] ? t("loading") : t("reject")}
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className="btn btn-success w-100"
                                                                        onClick={() => handleAction(course.courseId, "approve")}
                                                                        disabled={loadingStates[course.courseId]} // Show loading state for individual course
                                                                    >
                                                                        {loadingStates[course.courseId] ? t("loading") : t("approve")}
                                                                    </Button>
                                                                )}
                                                                <Link to={`/library/view-content/${course.courseId}`} className="w-100 h-100">
                                                                    <Button className="btn btn-info w-100 h-100 my-1">
                                                                        {t("view_content")}
                                                                    </Button>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {course.status === "N" && (
                                                        <div className="px-3 rounded shadow" style={{ position: "absolute", top: "0", left: "15px", background: "#c20606", color: "white" }}>
                                                            {t("new_content")}
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </Tab.Pane>

                                    {/* Published Tab */}
                                    <Tab.Pane eventKey="published">
                                        <Row className="h-100">
                                            {currentPageCourses
                                                .filter(course => course.status === "P")
                                                .map((course) => (
                                                    <Col
                                                        key={course.courseId}
                                                        xl={2}
                                                        lg={4} // 4 columns on large screens
                                                        md={4} // 3 columns on medium screens
                                                        sm={6} // 2 columns on small screens
                                                        xs={12} // 1 column on extra small (mobile) screens
                                                        className="rounded my-3"
                                                    >
                                                        <div className="card text-center shadow">
                                                            <div className="overflow">
                                                                <img src={imageUrls(course.courseImage)} alt="" className="card-img-top" height={200} />
                                                            </div>
                                                            <div className="card-body text-dark w-100 h-100">
                                                                <h4 className="card-title">{course.courseName}</h4>
                                                                <p className="card-text text-secondary">
                                                                    <Button
                                                                        className="btn btn-danger w-100"
                                                                        onClick={() => handleAction(course.courseId, "reject")}
                                                                        disabled={loadingStates[course.courseId]}
                                                                    >
                                                                        {loadingStates[course.courseId] ? t("loading") : t("reject")}
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Tab.Pane>

                                    {/* Rejected Tab */}
                                    <Tab.Pane eventKey="reject">
                                        <Row className="h-100">
                                            {currentPageCourses
                                                .filter(course => course.status === "R" || course.status === "U")
                                                .map((course) => (
                                                    <Col
                                                        key={course.courseId}
                                                        xl={2}
                                                        lg={4} // 4 columns on large screens
                                                        md={4} // 3 columns on medium screens
                                                        sm={6} // 2 columns on small screens
                                                        xs={12} // 1 column on extra small (mobile) screens
                                                        className="rounded my-3"
                                                    >
                                                        <div className="card text-center shadow">
                                                            <div className="overflow">
                                                                <img src={imageUrls(course.courseImage)} alt="" className="card-img-top" height={200} />
                                                            </div>
                                                            <div className="card-body text-dark w-100 h-100">
                                                                <h4 className="card-title">{course.courseName}</h4>
                                                                <p className="card-text text-secondary">
                                                                    <Button
                                                                        className="btn btn-success w-100"
                                                                        onClick={() => handleAction(course.courseId, "approve")}
                                                                        disabled={loadingStates[course.courseId]}
                                                                    >
                                                                        {loadingStates[course.courseId] ? t("loading") : t("approve")}
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Container>

                        {filteredCourseData.length > 0 && (
                            <Col md="12" className="text-center mt-3">
                                <ReactPaginate
                                    previousLabel={t("previous")}
                                    nextLabel={t("next")}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                />
                            </Col>
                        )}

                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AdminViewAllLibrary;
