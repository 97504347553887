import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, OverlayTrigger, Row, Tab, Table, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledFileInput, StyledFormGroup, StyledFormLabel, StyledTabs, TabContent } from './AssignmentDeliveryStyles';
import AssignmentService, { ASSIGN_URI } from '../../../../services/AssignmentService';
import DataTable from 'react-data-table-component';
import useNonce from '../../../../helper/Nonce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import md5 from 'md5';
import { Tooltip } from 'chart.js';
import { saveAs } from 'file-saver';


//styles , icons and columns
const fileIcons = {
    zip: { icon: "fas fa-file-archive text-warning fa-3x" },
    pdf: { icon: "fas fa-file-pdf text-danger fa-3x" },
    doc: { icon: "fas fa-file-word text-info fa-3x" },
    docx: { icon: "fas fa-file-word text-primary fa-3x" }
};
const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },

    rows: {
        style: {
            minHeight: '72px'
        },
    },

    headCells: {
        style: {

            widthRight: '8px',
            widthLeft: '8px',
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            fontSize: '17px',
            // fontWeight: '500',
            // textTransform: 'uppercase',
            // paddingLeft: '0 8px',
            // marginLeft: '10px',
        },
    },
    cells: {
        style: {
            widthRight: '8px',
            widthLeft: '8px',
            // paddingLeft: '8px', // override the cell padding for data cells
            // paddingRight: '8px',
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};
const viewAssignFileColumn = [
    {
        name: 'File Name',
        selector: row => row.filename,
    },
    {
        name: "Preview",
        cell: (row) => <FilePreview filename={row.filename} id={row.id} viewFileType={"assignment"} />
    },
];
const uploadSolutionColumns = (handleDeleteFile, getAssignDetails, setAssignDetails) => [
    {
        name: 'File Name',
        selector: row => row.filename
    },
    {
        name: 'Preview',
        selector: row => <FilePreview filename={row.filename} id={row.sno} viewFileType={"solution"} />
    },
    {
        name: 'Delete',
        selector: row =>
            <>
                {
                    getAssignDetails?.solutionDetails?.status !== 'evaluated'
                        ?
                        <Button variant='outline-danger' onClick={() => { handleDeleteFile(row.sno, getAssignDetails, setAssignDetails) }}>  <i className="fas fa-trash"></i> </Button>
                        :
                        <span className='text-danger'> Already Evaluated!</span>

                }
            </>
    },
]

//Internal components
const FilePreview = ({ filename, id, viewFileType }) => {

    const handleSaveFile = async (uri,filename, fileId) => {
        try {

            
            const response = await AssignmentService.getfileView(uri,fileId);
        //    console.log("res.",response.data)
        //    alert("dfafsd")
            if (filename.match(".zip")) { saveAs(response.data, filename) }
            else if (filename.match(".pdf")) {
                saveAs(response.data, filename) }
            else if (filename.match(".doc") || filename.match(".docx")) { saveAs(response.data, filename) }
        } catch (error) {
            if (error) {
                swal("Information", "Assignment File does not Exist!", "info");
            }
            // console.error("error", error)
        }
    }
    // viewFileType = assignment, solution
    const iconData = handleGetFileIcon(filename);
    let previewUrl = viewFileType === 'assignment' ? ASSIGN_URI._GET_FILE_TO_VIEW : ASSIGN_URI._GET_STUDENT_FILE

    // let previewUrl = viewFileType === 'assignment' 
    // ? encodeURIComponent(ASSIGN_URI._GET_FILE_TO_VIEW) 
    // : encodeURIComponent(ASSIGN_URI._GET_STUDENT_FILE);

    if (!iconData) return null;
    return (
        // <a href={`${previewUrl}${id}`} rel="noopener noreferrer">
        <div onClick={() => { handleSaveFile(previewUrl,filename, id) }} rel="noopener noreferrer">
            <i className={iconData.icon}></i>
        </div>
        // </a>
    );
};
const CustomFileInput = ({ handleSelectedFileChange, handleUploadSolution }) => {

    return (
        <StyledFileInput>
            <input type='file' id='custom-file-upload' className='custom-file-upload' onChange={handleSelectedFileChange} />
            <Button className='create-button' onClick={handleUploadSolution}>
                <i className="fa fa-upload" aria-hidden="true"></i>
                <span>Upload</span>
            </Button>
        </StyledFileInput>
    );
};

//handler's
const handleGetFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return fileIcons[extension] || null;
};
const handleGetAssignmentDetails = (getAssignDetails, setAssignDetails) => {
    //get assignment by assigmentID
    AssignmentService.getAssignmentData(getAssignDetails.assignmentId).
        then((resp) => {
            setAssignDetails((prevState) => {
                return {
                    ...prevState,
                    assignmentFileList: [...(resp.data.assignmentFilePath)],
                    assignDetails: resp.data
                }
            })
            // setAssignmentFileList(resp.data.assignmentFilePath);
            ////console.log(resp.data.assignmentFilePath);
        }).catch((error) => {

        })
}

const handleSelectedFileChange = (e, t, setAssignDetails) => {
    let files = e.target.files;

    for (const file of files) {

        if (file.type !== "application/pdf" &&
            file.type !== "application/x-zip-compressed" &&
            file.type !== "application/msword" &&
            file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setAssignDetails(prevState => ({ ...prevState, errorType: t('assign_upload_err') }));
            alert("invalid file")
            return;
        } else {
            setAssignDetails(prevState =>
            ({
                ...prevState,
                fileCheck: true,
                selectedFiles: files
            })
            );

        }
        // Else block removed as it contained only commented out code
    }
};
const handleDeleteFile = (id, getAssignDetails, setAssignDetails) => {
    AssignmentService.solutionfileonlydelete(id).then((resp) => {
        if (resp.status === 200) {
            swal('Success', 'File Deleted Successfully!', 'success');
            handleGetUploadAssignDetails(getAssignDetails, setAssignDetails);
        }
    }).catch((err) => {
        console.error("Error deleting file:", err);
    })
}
const handleUploadSolution = (t, getAssignDetails, setAssignDetails) => {

    if (getAssignDetails.fileCheck) {
        const fileInput = document.getElementById('custom-file-upload');
        const form = new FormData();
        for (const file of getAssignDetails.selectedFiles) {
            form.append('files', file);
            form.append('filehash', md5((file.size).toString()))
        }
        const startDate = new Date(getAssignDetails?.assignDetails?.createdDate);
        const endDate = new Date(getAssignDetails?.assignDetails?.closingDate);
        const currentDate = new Date();

        if (!(currentDate >= startDate && currentDate <= endDate)) {

            swal('Warning', 'Please Check the Start and End date of the Assignment!', 'warning');
            if (fileInput) {
                fileInput.value = '';
            }
            setAssignDetails(prevState => ({ ...prevState, fileCheck: false }))
            console.log(true);
            return;
        }

        AssignmentService.postStudentFileUpload(getAssignDetails.assignmentId, getAssignDetails.userId, form).then((res) => {
            if (res.data === "InvalidFile") {
                swal(t('error'), t('file_not_support'), 'success');

            }
            else if (res.data === "ReSubmitError") {
                swal(t('resubmiterror'), t('resubmitmsg'), 'warning');


                if (fileInput) {
                    fileInput.value = '';
                }
            }
            else {
                swal(t('success'), t('file_upload_msg'), 'success');
                handleGetUploadAssignDetails(getAssignDetails, setAssignDetails);
                // setUploadAssign(defaultUploadAssign)

                if (fileInput) {
                    fileInput.value = '';
                }
            }
            setAssignDetails(prevState => ({ ...prevState, fileCheck: false }))
        }).catch((error) => {
            console.error("Error uploading file:", error);
        })
    }
}
const handleGetUploadAssignDetails = (getAssignDetails, setAssignDetails) => {
    //service Called here to get the data

    // await fetchAndSetNonce("Assignment");
    AssignmentService.getStudentFileUpload(getAssignDetails.assignmentId, getAssignDetails.userId)
        .then((resp) => {

            console.log("comming in sol file")
            if (resp.data.solutionfile !== null) {
                setAssignDetails((prevState) => ({
                    ...prevState,
                    solutionFiles: resp.data?.solutionfile,
                    solutionDetails: resp.data.solutionmarks
                }))

            }
            if (resp.data.solutionfile === null) {
                setAssignDetails((prevState) => ({
                    ...prevState,
                    solutionFiles: [],
                    solutionDetails: null
                }))
            }

        }).catch((err) => {


        })
}

//View Assignment Component
export const AssignmentDetails = ({ getAssignDetails }) => {
    return (
        <Card className='w-100 text-center'>
            <Card.Body>
                <Card.Title>{getAssignDetails.assignDetails?.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{getAssignDetails.assignDetails?.description}</Card.Subtitle>
                <Card.Text>
                    <div className='border'>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Total Marks</th>
                                    <th>Passing Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{getAssignDetails?.assignDetails?.createdDate}</td>
                                    <td>{getAssignDetails?.assignDetails?.closingDate}</td>
                                    <td>{getAssignDetails?.assignDetails?.totalMarks}</td>
                                    <td>{getAssignDetails?.assignDetails?.passMarks}</td>


                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='border mt-3'>
                        <DataTable
                            columns={viewAssignFileColumn}
                            data={getAssignDetails?.assignmentFileList}
                            customStyles={customStyles}
                            striped
                            highlightOnHover
                        />
                    </div>
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
            </Card.Body>
        </Card>


    )
}


//Upload Assignment Component
export const UploadSolution = ({ getAssignDetails, setAssignDetails }) => {
    const { t } = useTranslation()

    const UploadSolutoncolumns = uploadSolutionColumns(handleDeleteFile, getAssignDetails, setAssignDetails);

    return (
        <Card className='w-100 text-center'>
            <Card.Body>
                <Card.Title>{t('upload_file')}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{t('file_pdf_doc_zip')}</Card.Subtitle>
                <Card.Text>
                    <CustomFileInput
                        handleSelectedFileChange={(e) => { handleSelectedFileChange(e, t, setAssignDetails) }}
                        handleUploadSolution={() => { handleUploadSolution(t, getAssignDetails, setAssignDetails) }}
                    />
                    <div className='border mt-3'>
                        <DataTable
                            columns={UploadSolutoncolumns}
                            data={getAssignDetails?.solutionFiles}
                            customStyles={customStyles}
                            striped
                            highlightOnHover
                        />
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export const AssignmentStatus = ({ getAssignDetails, setAssignDetails }) => {
    return (
        <Row className="justify-content-md-center w-100">
            <Col xs={12} md={6}>
                <Table className="table table-borderless bg-white rounded" responsive>
                    <tbody>
                        {getAssignDetails.solutionDetails !== null && (
                            <>
                                <tr>
                                    <td className='text-capitalize font-weight-bold text-start'>Marks Achieved</td>
                                    <td className='text-capitalize font-weight-bold px-2'>:</td>
                                    <td>{getAssignDetails.solutionDetails?.marks}</td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize font-weight-bold text-start'>Remarks</td>
                                    <td className='text-capitalize font-weight-bold px-2'>:</td>
                                    <td className='text-capitalize'>{getAssignDetails.solutionDetails?.remarks}</td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize font-weight-bold text-start'>Status</td>
                                    <td className='text-capitalize font-weight-bold px-2'>:</td>
                                    <td className='text-capitalize'>{getAssignDetails.solutionDetails?.status}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
                {
                    getAssignDetails.solutionDetails === null && <Alert className="my-2 text-center" key="assignment" variant="warning">
                        Please Upload the Solution File!
                    </Alert>
                }
            </Col>
        </Row>
    )
}

//Assignment Details Component -- code start's here
export const AssignmentDelivery = ({ assignmentId, userId, userRole }) => {

    const fetchAndSetNonce = useNonce()
    const assignmentDetailsState = {
        assignmentFileList: [],
        assignDetails: null,
        solutionFiles: [],
        solutionDetails: null,
        fileCheck: false,
        selectedFiles: [],
        errorType: null,
        assignmentId: null,
        userId: null

    }
    const [getAssignDetails, setAssignDetails] = useState(assignmentDetailsState)

    useEffect(() => {
        setAssignDetails(prevState => ({
            ...prevState,
            assignmentId,
            userId
        }))
    }, [assignmentId, userId])

    // get Assignment detials and solution file details
    useEffect(() => {
        if (getAssignDetails.assignmentId && getAssignDetails.userId) {
            handleGetAssignmentDetails(getAssignDetails, setAssignDetails)
            if (userRole === 'learner') {
                handleGetUploadAssignDetails(getAssignDetails, setAssignDetails)
            }
        }
    }, [getAssignDetails.assignmentId, getAssignDetails.userId])

    useEffect(() => {
        console.log("getAssignDetails", getAssignDetails)
    }, [getAssignDetails])
    return (
        <div>
            <StyledTabs
                defaultActiveKey="assignment-details"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="assignment-details" title="Assignment Detials" className=''>
                    <TabContent>
                        <AssignmentDetails getAssignDetails={getAssignDetails} />
                    </TabContent>
                </Tab>
                {
                    userRole === 'learner' &&
                    <Tab eventKey="uploaded-assignment-files" title="Upload Solution files">
                        <TabContent>
                            <UploadSolution assignmentId={assignmentId} getAssignDetails={getAssignDetails} setAssignDetails={setAssignDetails} userId={userId} />
                        </TabContent>
                    </Tab>}
                {
                    userRole === 'learner' &&
                    <Tab eventKey="assignment-tatus" title="Assignment Status">
                        <TabContent>
                            <AssignmentStatus assignmentId={assignmentId} getAssignDetails={getAssignDetails} userId={userId} />
                        </TabContent>
                    </Tab>

                }
            </StyledTabs>
        </div>
    );
};