import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import { BreadcrumbBox } from '../common/Breadcrumb';
import HeaderTwo from '../HeaderTwo';
import { Styles } from "./styles/AddCourseCategory.js"
import FooterTwo from '../FooterTwo.js';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import service, { URI } from '../../services/service.js';
import swal from 'sweetalert';
import UserService from '../../services/UserService.js';


const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in'
    },
];

export const EditLibrary = (props) => {
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()
    let UserId = UserService.getUserid();

    const [getLibraries, setLibraries] = useState({
        libraryName: "",
        libraryId: "",
        libraryInst: "",
        libraryProfile: "",
        userId:""
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const courseId = props.match.params.cId;

    useEffect(() => {
        getLibraryDetails();
    }, [courseId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
       
        setLibraries(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Update the libraryProfile in state
            setLibraries(prev => ({
                ...prev,
                libraryProfile: file
            }));

            // Create and set image preview
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const getLibraryDetails = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const res = await service.getLibraryById(courseId);
            if (res.status === 200 && res.data) {
                setLibraries({
                    libraryName: res.data.courseName,
                    libraryId: res.data.courseId,
                    libraryInst: res.data.inst_profile,
                    libraryProfile: res.data.imageUrl,
                    userId : res.data.userId
                    
                });
            }
        } catch (err) {
            setError("Error fetching library details");
            console.error("Error: ", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            // Create FormData for file upload
            const formData = new FormData();
            formData.append('categoryId', 1)
            formData.append('courseId', getLibraries.libraryId);
            formData.append('userId', UserId)
            formData.append('courseName', getLibraries.libraryName);
            formData.append('inst_profile', getLibraries.libraryInst);
            if (getLibraries.libraryProfile instanceof File) {
                formData.append('file', getLibraries.libraryProfile);
            }

            // Add your submit logic here
            await service.updateLibrary(formData).then((res)=>{
                if(res.data === "Unauthorized"){
                    swal(`${t('warning')}`, `Unauthorized Access`, "warning")

                }
                else if(res.status === 200){
                    swal(`${t('success')}`, `${t('course_updated_successfully')}`, "success")
                }
            }).catch((err)=>{console.log("Err : ", err)})
            console.log('Submitting:', formData);
        } catch (err) {
            swal(t('error'), 'Unknown Error occured!', "error");
            setError("Error updating library");
            console.error("Error: ", err);
        } finally {
            setIsLoading(false);
        }
    };

    // Determine which image to show
    const imageToShow = imagePreview || 
        (getLibraries.libraryProfile ? 
            `${URI.SERVER?._COURSE_URL}/${getLibraries.libraryProfile}` : 
            `${URI.SERVER?._COURSE_URL}/courseuploads/DefaultImage/default.jpg`);

    return (
        <Styles>
            <div className="main-wrapper registration-page">
                <HeaderTwo />
                <BreadcrumbBox title="Edit Library" />

                <section className="registration-area">
                    <Container>
                        <div className="registration-box" style={{ maxWidth: "1200px" }}>
                            <h2 id="heading"><h3>Update Library Details </h3></h2>
                            <p style={{ textAlign: 'center' }}>{t('fill_all_form')}</p>

                            {error && <div className="alert alert-danger">{error}</div>}

                            <form className="form" id="msform" onSubmit={handleSubmit}>
                                <fieldset>
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7">
                                                <h2 className="fs-title">Edit Library :</h2>
                                            </div>
                                        </div>

                                        <p className="form-control">
                                            <label className="control-label" htmlFor="libraryName">
                                                {t('libraryName')} :
                                            </label>
                                            <input 
                                                className="form-control" 
                                                minLength={3} 
                                                maxLength={50} 
                                                id="libraryName" 
                                                name="libraryName" 
                                                type="text" 
                                                placeholder={t('enter_c_name')}
                                                value={getLibraries.libraryName}
                                                onChange={handleInputChange}
                                            />
                                            <span className="registration_input-msg"></span>
                                        </p>

                                        <p className="form-control">
                                            <label className="control-label" htmlFor="libraryInst">
                                                {t('instructor_profile')} :
                                            </label>
                                            <input 
                                                type="text" 
                                                minLength={3} 
                                                maxLength={250}
                                                value={getLibraries.libraryInst}
                                                className="form-control"
                                                name="libraryInst"
                                                id="libraryInst"
                                                onChange={handleInputChange}
                                                placeholder={t('enter_instructor_profile')}
                                            />
                                            <span className="registration_input-msg"></span>
                                        </p>

                                        <p className="form-control">
                                            <label className="control-label" htmlFor="libraryProfile">
                                                {t('instructor_profile')} : ({t('icon_desp')})
                                            </label>
                                            <input 
                                                type="file"
                                                className="form-control" 
                                                style={{ marginBottom: '0px' }} 
                                                id="libraryProfile" 
                                                name="libraryProfile" 
                                                accept="image/*" 
                                                onChange={handleImageChange}
                                            />
                                            <br />
                                            <div className="image-preview">
                                                <img
                                                    src={imageToShow}
                                                    alt="Library Profile"
                                                    style={{ 
                                                        height: '150px', 
                                                        width: '200px',
                                                        objectFit: 'cover',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '4px'
                                                    }} 
                                                />
                                                {imagePreview && 
                                                    <Button 
                                                        variant="link" 
                                                        className="mt-2"
                                                        onClick={() => {
                                                            setImagePreview(null);
                                                            setLibraries(prev => ({
                                                                ...prev,
                                                                libraryProfile: prev.libraryProfile
                                                            }));
                                                        }}
                                                    >
                                                        Reset Image
                                                    </Button>
                                                }
                                            </div>
                                        </p>
                                    </div>

                                    <Button 
                                        type="submit"
                                        className="next action-button rounded" 
                                        variant="success"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Submit'}
                                    </Button>
                                </fieldset>
                            </form>
                        </div>
                    </Container>
                </section>
                <FooterTwo />
            </div>
        </Styles>
    );
}