import axios from "axios";
import { DMS_URL, COURSE_URL, COURSE_CLUG, COURSE_CLONE, urlsDefined, URI } from "./service";

//DMS_URL is document management service
console.log("loading inst service")
// debugger
console.log(URI);
// debugger



class instructorService {

    /* Category Services Start here  */

    addCourseCategory(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/addCategory", data);
    }

    getAllCourseCategory() {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/public/getAllCategories");
    }

    deleteCategory(categoryId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/deleteCategory/" + categoryId);
    }

    editCategory(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/updateCategory", data);
    }

    getCourseName(courseId) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/getCoursedetailById/" + courseId);
    }
    /* Category Services End here  */

    /* Course Create Services Start */

    createCourse(feeSelectVal, courseFeeVal, courseNameVal, courseCategoryVal, durationVal, type, durationSelectVal, publishDateVal, enrollStartDateVal, enrollEndDateVal,
        commencementDateVal, courseIcon, iconsig, banner, bannersig, video, videosig, instructorProfile, Objective, gDetails, prerequisite, isScormCompliant, userId, fee_discount) {
        let formData = new FormData();
        formData.append("courseType", feeSelectVal);
        if (feeSelectVal == "free" || feeSelectVal == "restricted") {
            formData.append("courseFee", 0);
        } else {
            formData.append("courseFee", courseFeeVal);
        }
        formData.append("courseName", courseNameVal);
        formData.append("categoryId", courseCategoryVal);
        formData.append("courseAccessType", durationSelectVal);
        if (durationSelectVal == "unlimited") {
            formData.append("duration", 0);
        } else {
            formData.append("duration", durationVal);
        }
        formData.append("publishDate", publishDateVal);
        formData.append("enrollSdate", enrollStartDateVal);
        formData.append("enrollEdate", enrollEndDateVal);
        formData.append("commencementDate", commencementDateVal);
        formData.append("file", courseIcon);
        formData.append("filesig", iconsig);
        formData.append("video", video);
        formData.append("videosig", videosig);
        formData.append("banner", banner);
        formData.append("bannersig", bannersig);
        // type 1 is for Course and 2 for library 
        formData.append("type", type);
        if (gDetails == '') {
            formData.append("generalDetails", undefined);
        } else {
            formData.append("generalDetails", gDetails);
        }
        if (prerequisite == '') {
            formData.append("prerequisite", undefined);
        } else {
            formData.append("prerequisite", prerequisite);
        }
        if (Objective == '') {
            formData.append("objective", undefined);
        } else {
            formData.append("objective", Objective);
        }
        formData.append("inst_profile", instructorProfile);
        formData.append("isScormCompliant", isScormCompliant);
        formData.append("userId", userId);
        formData.append("fee_discount", fee_discount);
        // console.log("formData", formData);
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/addCourse/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }

    updateCourse(feeSelectVal, courseFeeVal, courseNameVal, courseCategoryVal, durationVal, durationSelectVal, publishDateVal, enrollStartDateVal, enrollEndDateVal,
        commencementDateVal, courseIcon, iconsig, banner, bannersig, video, videosig, instructorProfile, Objective, gDetails, prerequisite, isScormCompliant, userId, courseId, fee_discount) {
        // console.log("phlesasasasasas", feeSelectVal, courseFeeVal, courseNameVal, courseCategoryVal, durationVal, durationSelectVal, publishDateVal, enrollStartDateVal, enrollEndDateVal,
        //     commencementDateVal, courseIcon, banner, video, instructorProfile, Objective, gDetails, prerequisite, isScormCompliant, userId, courseId, fee_discount)
        let formData = new FormData();
        formData.append("courseType", feeSelectVal);
        if (feeSelectVal == "free" || feeSelectVal == "restricted") {
            formData.append("courseFee", 0);
        } else {
            formData.append("courseFee", courseFeeVal);
        }
        formData.append("courseName", courseNameVal);
        formData.append("categoryId", courseCategoryVal);
        formData.append("courseAccessType", durationSelectVal);
        if (durationSelectVal == "unlimited") {
            formData.append("duration", 0);
        } else {
            formData.append("duration", durationVal);
        }
        formData.append("publishDate", publishDateVal);
        formData.append("enrollSdate", enrollStartDateVal);
        formData.append("enrollEdate", enrollEndDateVal);
        formData.append("commencementDate", commencementDateVal);
        formData.append("file", courseIcon);
        formData.append("filesig", iconsig);
        formData.append("video", video);
        formData.append("videosig", videosig);
        formData.append("banner", banner);
        formData.append("bannersig", bannersig);
        if (gDetails == '') {
            formData.append("generalDetails", undefined);
        } else {
            formData.append("generalDetails", gDetails);
        }
        if (prerequisite == '') {
            formData.append("prerequisite", undefined);
        } else {
            formData.append("prerequisite", prerequisite);
        }
        if (Objective == '') {
            formData.append("objective", undefined);
        } else {
            formData.append("objective", Objective);
        }
        formData.append("inst_profile", instructorProfile);
        formData.append("isScormCompliant", isScormCompliant);
        formData.append("userId", userId);
        formData.append("courseId", courseId)
        formData.append("fee_discount", fee_discount);
        // console.log("bad me", feeSelectVal, courseFeeVal, courseNameVal, courseCategoryVal, durationVal, publishDateVal, enrollStartDateVal, enrollEndDateVal,
        //     commencementDateVal, courseIcon, Objective, gDetails, prerequisite, isScormCompliant, userId, courseId);
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/updateCourse/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })

    }

    getAllCourses() {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/public/getCourses")
    }

    getCourseById(cId) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/getCourses/" + cId);
    }

    getLibraryById(cId) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/getLibraries/" + cId);
    }

    getLibraryContent(id) {
        return axios.get(URI.SERVER?._COURSE_URL + `/courseOrganizer/api/getInstLibraryStructure/${id}`);
    }

    updateCertificateStatus(courseId, certificateStatus) {
        return axios.post(URI.SERVER?._COURSE_URL + `/courseOrganizer/api/instructor/updatecertificateStatus/${courseId}/${certificateStatus}`);
    }

    getCertificateStatus(courseId) {
        return axios.get(URI.SERVER?._COURSE_URL + `/courseOrganizer/api/getcertificateStatus/${courseId}`);
    }

    /* Course Create Services End */

    /* Create Course Structure and Get All Code Start Here */


    contentDetails(dir_id, user_id) {
        return axios.get(URI.SERVER?._DMS_URL + "/dms/api/getContentDetails/" + dir_id + "/" + user_id);
    }


    createDirectory(data) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/addRootDirectory/", data);
    }

    createChildDirectory(data) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/addChildDirectory/", data)
    }

    getFolderStructure(userId) {
        return axios.get(URI.SERVER?._DMS_URL + "/dms/api/getDirectories/" + userId);
    }

    fileUpload(file, filesig, user_id, dir_name, durationInMinutes, contentName, fileSelectedOption, checkBox) {
        //console.log(file);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileSig", filesig);
        formData.append("user_id", user_id)
        formData.append("dir_name", dir_name);
        formData.append("durationInMinutes", durationInMinutes);
        formData.append("contentName", contentName);
        formData.append("zipStatus", checkBox);
        formData.append("fileSelectedOption", fileSelectedOption);
        // return axios.post(URI.SERVER?._DMS_URL + "/dms/api/fileUpload/", formData, {
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //     }
        // })
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/fileUploadmediacms/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }

    deleteDirectory(data) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/deleteDirectory", data);
    }

    directoryStatusCheck(dirId, parentId) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/directoryStatusCheck/" + dirId + "/" + parentId)
    }


    contentDelete(contentId) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/deleteContent/" + contentId);
    }

    fileCotentDetailsUpdate(data) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/updateContent", data)
    }

    folderNameUpdate(data) {
        return axios.post(URI.SERVER?._DMS_URL + "/dms/api/updateDirectory", data);
    }

    contentAccess(url) {
        return axios.get(url);
    }

    getContentAccess(url) {
        return axios.get(`${URI.SERVER?._DMS_URL}/${url}`)
    }

    /* Create Course Structure and Get All Code Start Here */

    /* Course Structure API Methods */

    addContentToCourseStructure(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/addContent", data)
    }

    checkContentStatus(contentId, contentType) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/contentStatusCheck/" + contentId + "/" + contentType);
    }

    deleteCourseContent(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/deleteContent", data);
    }

    deleteCourseStructureChild(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/deleteChild", data);
    }

    addModuleOrTopic(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/addChild", data);
    }

    courseDelete(id) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/deleteCourse/" + id);
    }

    updateFolderDetails(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/updateChild", data);
    }

    updateContentDetails(data) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/updateContent", data);
    }

    coursePublish(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/admin/publishCourse/" + cId);
    }

    LibraryPublish(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/admin/publishLibrary/" + cId);
    }

    coursePublishAdminRequest(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/requestAdminforPublishCourse/" + cId);
    }

    libraryPublishAdminRequest(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/requestAdminforPublishLibrary/" + cId);
    }

    ContentPublishAdminRequest(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/requestAdminforPublishContent/" + cId);
    }

    LibraryDisableStatus(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/LibraryDisableStatus/" + cId);
    }

    courseUnPublish(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/UnPublishCourse/" + cId);
    }

    libraryUnPublish(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/admin/UnPublishLibrary/" + cId);
    }

    // libraryUnPublish(cId) {
    //     return axios.post(URI.SERVER?._COURSE_URL + "api/admin/UnPublishLibrary/" + cId);
    // }

    CourseDisable(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/CourseDisableStatus/" + cId);
    }

    LibraryDisable(cId) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/LibraryDisableStatus/" + cId);
    }
    //  get time and date call this api

    getServerTime() {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/public/getSystemDate/");
    }

    /////  Library  Services   ///

    addLibrary(data1) {
        return axios({
            method: "POST",
            url: URI.SERVER?._COURSE_URL + `/courseOrganizer/api/instructor/addLibrary`,
            data: data1,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })

    }

    ///////////COURSE CLONE SERVICES /////////////////
    createClone(selectedCourseId, courseName, publishDate, enrolStartDate, enrolEndDate, commenceDate, userId) {
        //console.log(userId);
        let formData = new FormData();

        formData.append("courseId", selectedCourseId);
        formData.append("courseName", courseName);
        formData.append("publishDate", publishDate);
        formData.append("enrollSdate", enrolStartDate);
        formData.append("enrollEdate", enrolEndDate);
        formData.append("commencementDate", commenceDate);
        formData.append("userId", userId);

        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/cloneCourse", formData
            , {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )

    }

    contentClone(clonedCourseId, oldCourseId, userId, courseName) {
        //console.log(userId);
        let formData = new FormData();

        formData.append("courseId", clonedCourseId);
        formData.append("oldCourseId", oldCourseId);
        formData.append("userId", userId);
        formData.append("courseName", courseName);

        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/cloneContent", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })

    }

    ////ADD CONTENT ABOVE ANOTHER EXISTING CONTENT
    addContentInBetween(newContentArray, existingContentId) {
        let formData = new FormData();

        formData.append("newContentArray", newContentArray);
        formData.append("existingContentId", existingContentId);

        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/addContentInBetween/" + existingContentId, newContentArray)
    }

    updatedCourseStructure(userId, courseId, updatedCourseStructureJson) {
        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/updateCourseStructure/" + userId + "/" + courseId, updatedCourseStructureJson)
    }

    // getCourseName(courseId) {
    //     return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/getCoursedetailById/" + courseId);
    // }
    isContentOwner(userId, dirChildId) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/ContentOwner/" + userId + "/" + dirChildId)
    }
    // isCourseOwner(userId, courseId) {
    //     return axios.get(URI.SERVER?._COURSE_URL + " /courseOrganizer/getCourseOwner/" + userId + "/" + courseId)
    // }
    isCourseOwner(userId, courseId) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/instructor/getCourseOwner/" + userId + "/" + courseId)
    }
    // isCourseOwner(userId,courseId){
    //     return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/getCourseOwnerUserId/" + userId + "/" + courseId)
    // }

    updateCourseSchedule(courseId, commencementDate, enrollSdate, enrollEdate) {

        const requestBody = {
            courseId: courseId,
            commencementDate: commencementDate,
            enrollSdate: enrollSdate,
            enrollEdate: enrollEdate
        }

        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/updateCourseSchedule", requestBody);
    }

    getCourseDuartionUpdateData(courseId) {
        return axios.get(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/getCourseDuartionUpdateData/" + courseId);
    }

    updateCourseDuration(courseId, duration) {
        const requestBody = {
            courseId: courseId,
            duration: duration,
        }

        return axios.post(URI.SERVER?._COURSE_URL + "/courseOrganizer/api/adminInstructor/updateCourseDuration", requestBody);
    }

    getUsersByCourseId(courseId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + "/courseCatalogue/api/getUsersByCourseId/" + courseId);
    }

    updateUserCourseDuration(courseId, duration, selectedUsers) {
        const requestBody = {
            userIds: selectedUsers,
            courseId: courseId,
            duration: duration
        }

        return axios.post(URI.SERVER?._COURSE_CLUG + "/courseCatalogue/api/updateUserCourseSchedule", requestBody);
    }
    removeLibRequests(courseId,contentId){
        return axios.get(this.validateURL(URI.SERVER._COURSE_URL) + `/courseOrganizer/api/getLibRequests/${courseId}/${contentId}`)
    }

}

export default new instructorService()