import axios from "axios";
import UserService from "./UserService";
import { COURSE_URL, COURSE_CLUG, Communication, USER_API, USER_API2, ACTIVITYCOMPLETION, URI } from "./service";

// const USER_API_KEYCLOCK = URI.SERVER?._USER_API2 + "um_auth/";

// const BULK_UPLOAD_URL = URI.SERVER?._USER_API2 + "um_bulk/upload";


// /* Testimonials */
// const TESTIMONIAL_API = URI.SERVER?._COMMUNICATION + "/testimonial/";

// const requesOfLearnerForCourse = URI.SERVER?._COURSE_CLUG + '/api';

// const approve_reject_content = `${URI.SERVER?._COURSE_URL}/courseOrganizer`;

// const Enable_Disable_User = URI.SERVER?._USER_API2 + "um_auth"

// const Cadre_Qualification_Designation_CURD = URI.SERVER?._USER_API2 + "um_api"

// const CheckEmail = URI.SERVER?._USER_API + "domainList/validate"

// const getCourseInstructors = URI.SERVER?._COURSE_CLUG + '/api/getCourseMultipleInstructors';
// const assignMultipleInst = URI.SERVER?._COURSE_CLUG + '/api/assignInstructor';
// const assignMultipleInstToAssessment = URI.SERVER?._COURSE_CLUG + '/assign/post/assignInstructor';
// // const assignMultipleInstToAssessment = "http://10.244.2.253:8094" + '/assign/post/assignInstructor';

// const removeMultipleInst = URI.SERVER?._COURSE_CLUG + '/api/removeInstructor';
// const removeMultipleInstFromAssessment = URI.SERVER?._COURSE_CLUG + '/assign/post/removeAssignedInstructor';
// // const removeMultipleInstFromAssessment = "http://10.244.2.253:8094" + '/assign/post/removeAssignedInstructor';


// // const getCourseInstructors = "http://10.244.2.253:8085" + '/api/getCourseMultipleInstructors';
// // const assignMultipleInst = "http://10.244.2.253:8085" + '/api/assignInstructor';
// // const removeMultipleInst = "http://10.244.2.253:8085" + '/api/removeInstructor';


class adminServices {



    /* for user service */
    getAllLearners() {
        return axios.get(URI.SERVER?._USER_API + "api/learner");
    }
    getAllInstructors() {
        return axios.get(URI.SERVER?._USER_API + "api/learner/getInstructorList");
    }
    getUserImage(userId) {
        return axios.get(URI.SERVER?._USER_API + "public/getprofilepic/" + userId)
    }
    getAllInstructorRequest() {

        return axios.get(URI.SERVER?._USER_API + "api/getUserRequestInstructorList?status=pending")
        // return axios.get(URI.SERVER?._USER_API + "getUserRequestInstructorList")
    }
    getLearnerByid(userId) {
        return axios.get(URI.SERVER?._USER_API + "api/learner/byId?userid=" + userId)
    }
    disableUser(userId) {
        return axios.post(URI.SERVER?._USER_API2 + "/um_auth/" + "api/DUser/" + userId);
    }
    enableUser(userId) {
        return axios.post(URI.SERVER?._USER_API2 + "/um_auth/" + "api/EUser/" + userId);
    }
    getAllRequestForLearner() {
        return axios.get(URI.SERVER?._USER_API + "api/learnerlistforadminapproval");
    }

    // By Dhirendra
    putRejectInstructorRequestURL(rowId, rejectRemark) {
        return axios.post(URI.SERVER?._USER_API + `api/rejectInstRequest?id=${rowId}&remarks=${rejectRemark}`);
    }

    getRequestOfLearnerForCourse(courseId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api' + "/getUnapprovedCourseEnrollRequest/" + courseId);
    }
    getapprovedCourseEnrollRequest(courseId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api' + "/getapprovedCourseEnrollRequest/" + courseId);
    }

    getRequestOfLearnerForLibrary(courseId) {
        return axios.get(URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api' + "/getUnapprovedCourseEnrollRequest/" + courseId);
    }

    // #################################### COMMUNICATION Start #################################### //

    getAllTestimonial() {
        // return axios.get(URI.SERVER?._COMMUNICATION + "/testimonial/" + "getAllTestimonial");
        return axios.get(URI.SERVER?._COMMUNICATION + "/communications/public/getAllTestimonial");
    }

    approveTestimonials(testId) {
        // return axios.post(URI.SERVER?._COMMUNICATION + "/testimonial/" + "approve/" + testId);
        return axios.post(URI.SERVER?._COMMUNICATION + "/communications/api/approve/" + testId);
    }

    rejectTestimonials(testId) {
        // return axios.post(URI.SERVER?._COMMUNICATION + "/testimonial/" + "reject/" + testId);
        return axios.post(URI.SERVER?._COMMUNICATION + "/communications/api/reject/" + testId);
    }

    // #################################### COMMUNICATION End #################################### //

    /* Bulk User Registration */

    bulkUserUpload(fileData, userId) {
        let formData = new FormData();
        formData.append("file", fileData);
        // formData.append("programId", programId);
        formData.append("updatedBy", userId);
        //return axios.post(URI.SERVER?._USER_API2 + "/um_bulk/upload", formData);
        return axios.post(URI.SERVER?._USER_API2 + "/um_bulk/api/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }


    enableLearnerUser(userId) {
        return axios.post(URI.SERVER?._USER_API2 + "/um_auth" + `/EUser/${userId}`);
    }

    disableLearnerUser(userId) {
        return axios.post(URI.SERVER?._USER_API2 + "/um_auth" + `/DUser/${userId}`);
    }

    ///////  DESINATION //////

    addDesignation(desig) {
        return axios({
            method: "POST",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/api/desig`,
            data: desig,
        })
    }

    getAllDesignation() {
        return axios({
            method: "GET",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/public/desig`,
        })
    }

    getDesignationById(id) {
        return axios({
            method: "GET",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/api/desig/${id}`,
        })
    }

    UpdateDesignation(desig) {
        return axios({
            method: "POST",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/api/updatedesig`,
            data: desig,
        })
    }

    deleteDesignation(id) {
        return axios.post(URI.SERVER?._USER_API2 + "/um_api" + `/api/desig/${id}`);
    }

    //////  QUALIFICATION   ///////

    addQualification(qualf) {
        return axios({
            method: "POST",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/api/qualf`,
            data: qualf,
        })
    }

    getAllQualification() {
        return axios({
            method: "GET",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/public/qualf`,
        })
    }

    getQualificationById(id) {
        return axios({
            method: "GET",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/api/qualf/${id}`,
        })
    }

    UpdateQualification(qualf) {
        return axios({
            method: "POST",
            url: URI.SERVER?._USER_API2 + "/um_api" + `/api/updatequalf`,
            data: qualf,
        })
    }

    deleteQualification(id) {
        return axios.post(URI.SERVER?._USER_API2 + "/um_api" + `/api/qualf/${id}`);
    }


    approveContent(id1, cid) {

        const data1 = {
            courseId: cid,
            contentId: id1
        }
        return axios.post(`${URI.SERVER?._COURSE_URL}/courseOrganizer` + "/api/admin/approveContent", data1)
    }


    rejectContent(data1) {

        return axios.post(`${URI.SERVER?._COURSE_URL}/courseOrganizer` + "/api/admin/rejectContent", data1);

        // return axios({
        //     method : "POST",
        //     url :  `${URI.SERVER?._COURSE_URL}/courseOrganizer`+"/api/admin/rejectContent",
        //     data : data1,
        // })
    }

    checkEmailSpam(email) {
        return axios.get(URI.SERVER?._USER_API + "domainList/public/validate" + `/${email}`);
    }

    getUsersCourseSummaryByCourseId(courseId) {
        return axios.get(URI.SERVER?._ACTIVITYCOMPLETION + `/coursecompletionmaster/api/getallbycourseid/${courseId}`);
    }

    getCourseMultipleInstructors(userId, courseId) {
        return axios.get(`${URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api/getCourseMultipleInstructors'}/${userId}/${courseId}`);
    }

    assignMultipleInstructor(userId, courseId, instId) {
        return axios.post(`${URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api/assignInstructor'}/${userId}/${courseId}`, instId);
    }
    assignMultipleInstructorToAssessment(data) {
        return axios.post(`${URI.SERVER?._QUIZ_AUTHORING + '/assign/post/assignInstructor'}`, data);
    }
    removeMultipleInstructor(userId, courseId, instId) {
        return axios.post(`${URI.SERVER?._COURSE_CLUG + '/courseCatalogue/api/removeInstructor'}/${userId}/${courseId}`, instId);
    }
    removeMultipleInstructorFromAssessment(data) {
        return axios.post(`${URI.SERVER?._QUIZ_AUTHORING + '/assign/post/removeAssignedInstructor'}`, data);
    }

    sendMailTempPasswordToLI = (uid) => {
        return axios.post(`${URI.SERVER?._USER_API2}/um_api/api/admin/send-temp-password/${uid}`);
    }

    // bulkUserUpload = (fileData, userId) => {
    //     let formData = new FormData();
    //     formData.append("file", fileData);
    //     // formData.append("programId", programId);
    //     formData.append("updatedBy", userId);
    //     //return axios.post(BULK_UPLOAD_URL, formData);
    //     return axios.post(`${URI.SERVER?._USER_API2}/um_bulk/api/learnerbulkuploadregistration`, formData, {
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     })
    // }

    learnerBulkUpload = (data, userId) => {
        return axios.post(`${URI.SERVER?._USER_API2}/um_bulk/api/learnerbulkuploadregistration/${userId}`, data);
    }

    InstructorBulkUpload = (data, userId) => {
        return axios.post(`${URI.SERVER?._USER_API2}/um_bulk/api/instructorbulkuploadregistration/${userId}`, data);
    }

    sendActivationLink = (userId) => {
        return axios.post(`${URI.SERVER?._USER_API2}/um_api/api/admin/send-actvation-link/${userId}`);
    }

    getPendingLibRequests() {
        return axios.get(`${URI.SERVER?._COURSE_URL}/courseOrganizer/api/admin/getPendingLibRequests`)
    }

    approveLibRequest(libData) {
        return axios.post(`${URI.SERVER?._COURSE_URL}/courseOrganizer/api/admin/approveLibRequests`, libData)
    }


    rejectLibRequest(libData) {
        return axios.post(`${URI.SERVER?._COURSE_URL}/courseOrganizer/api/admin/rejectLibRequests`, libData)
    }
    removeLearner = (userId, learnerId) => {
        return axios.post(`${URI.SERVER?._USER_API2}/um_api/deleteLearner/${userId}/${learnerId}`)
    }

    sendMailForVerifyOtp(data) {
        return axios.post(`${URI.SERVER?._USER_API2}/um_api/public/send-otp`, data);
    }

    sendMailForPasswordChange(data) {
        return axios.post(`${URI.SERVER?._USER_API2}/um_api/public/send-password-on-otp-verification`, data);
    }

    //// Admin Assign Instructor Api
    assignAsInstructor(id) {
        return axios.post(URI.SERVER?._USER_API + `api/assignasinstructor`, id,
            {
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
    }

    removeAsInstructor(id) {
        return axios.post(URI.SERVER?._USER_API + `api/removeinstructor`, id,
            {
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
    }

    searchUserByEmail = (emailId) => {
        return axios.get(`${URI.SERVER?._USER_API2}/um_api/api/users/search`, {
            params: {
                email: emailId
            }
        });
    }
    //////

    /// MANAGE SERVICE DISTRICT / ESTABLISHMENT

    getServiceDistrictById(id) {
        return axios({
            method: "GET",
            url: URI.SERVER?._USER_API + `public/get-servicedistrict/${id}`,
        })
    }

    addServiceDistrict(newServiceDistrictName) {
        return axios.post(URI.SERVER?._USER_API + "api/add-servicedistrict",
            newServiceDistrictName,
            {
                headers: {
                    'Content-Type': 'text/plain'
                }
            }
        );
    }

    updateServiceDistrict(updateserviceDistrict) {
        return axios.post(URI.SERVER?._USER_API + "api/update-servicedistrict", updateserviceDistrict);
    }

    deleteServiceDistrict(id) {
        return axios.post(URI.SERVER?._USER_API + `api/delete-servicedistrict`, id, {
            headers: {
                'Content-Type': 'text/plain'
            }
        }
        );
    }
}

export default new adminServices()