import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .registration-page {
        .registration-area {
            padding: 40px 0;
            .registration-box {
                max-width: 900px;
                margin: auto;
                border: 1px solid ${colors.border1};
                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1);
                padding: 35px 40px;
                border-radius: 8px;
                background-color: #fff;

                .registration-title {
                    h3 {
                        color: ${colors.black2};
                        font-weight: 600;
                        padding-bottom: 12px;
                        margin-bottom: 24px;
                        font-size: 24px;
                        text-align: center;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 60px;
                            height: 3px;
                            background: ${colors.green};
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }

                form.form {
                    p.form-control {
                        margin-bottom: 20px;
                        label {
                            font-size: 14px;
                            font-weight: 500;
                            color: ${colors.text1};
                            margin-bottom: 6px;
                        }
                        
                        input, select {
                            width: 100%;
                            height: 50px;
                            padding: 15px;
                            font-size: 15px;
                            color: ${colors.black1};
                            border: 1px solid ${colors.border3};
                            border-radius: 6px;
                            background-color: #f9f9f9;
                            
                            &::placeholder {
                                font-size: 14px;
                                color: ${colors.text3};
                            }
                            
                            &:focus {
                                border-color: ${colors.green};
                                box-shadow: 0 0 5px rgba(0, 150, 0, 0.2);
                            }
                        }

                        textarea {
                            width: 100%;
                            height: 120px;
                            padding: 15px;
                            font-size: 15px;
                            color: ${colors.black1};
                            border: 1px solid ${colors.border3};
                            border-radius: 6px;
                            background-color: #f9f9f9;

                            &::placeholder {
                                font-size: 14px;
                                color: ${colors.text3};
                            }

                            &:focus {
                                border-color: ${colors.green};
                                box-shadow: 0 0 5px rgba(0, 150, 0, 0.2);
                            }
                        }
                    }

                    button {
                        width: 100%;
                        height: 50px;
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                        background: ${colors.green};
                        border: none;
                        border-radius: 6px;
                        text-transform: uppercase;
                        transition: background 0.3s ease;

                        &:hover {
                            background: ${colors.gr_bg2};
                        }
                    }
                }

                .error-text {
                    color: ${colors.red};
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
        }

        /* Card styling */
        .main-wrapper .card {
            border-radius: 8px;
            box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
            border: 1px solid ${colors.border3};
            background-color: #fff;
            margin-bottom: 30px;

            .card-header {
                background-color: ${colors.gr_bg};
                color: #fff;
                border-bottom: none;
                padding: 16px 20px;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
            }

            .card-body {
                padding: 20px 30px;
            }
        }

        /* Button styling */
        .btn-fill {
            font-size: 16px;
            font-weight: 500;
            padding: 12px 20px;
            color: #fff;
            background-color: ${colors.green};
            border: none;
            border-radius: 6px;
            transition: background 0.3s ease;

            &:hover {
                background-color: ${colors.gr_bg2};
            }
        }
    }

    /* Responsive adjustments */
    @media (max-width: 767px) {
        .registration-area {
            padding: 20px 10px;

            .registration-box {
                padding: 20px;
            }

            form.form p.form-control input, form.form p.form-control select, form.form p.form-control textarea {
                font-size: 14px;
                height: 45px;
            }

            button {
                font-size: 15px;
                height: 45px;
            }
        }
    }
`;
