import React from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  TabPane,
  Table,
  Alert,
} from "react-bootstrap";
import { Styles, StyledModal, StyledModalHeader, StyledModalFooter, HeaderUnderline, HeaderTitle, CloseButton } from "../styles/coursedetails.js";
import DataTable from "react-data-table-component";

import DatePicker from "react-datepicker";

import { useTranslation } from "react-i18next";
import TopicFeedback from "../TopicFeedback.js";
import { Bar, Pie } from "react-chartjs-2";
import { DragDropContext } from "react-beautiful-dnd";
import DragAndDropContainer from "../DragAndDropContainer.js";
import { AssignmentDelivery } from "../../../courses/contentDeliveryUpdated/Assignment/AssignmentDelivery.js";


export const CourseDriveModal = ({
  show,
  onHide,
  getFolderName,
  items,
  getContentDetails,
  columns,
  filteredItems,
  customStyles,
  subHeaderComponent,
  handleRowSelected,
  selectedRows,
  startDate,
  setStartDate,
  ExampleCustomInput,
  AddContentToCourseStructure,
  newArr,
  getCourseStructureFolderId,
  getCourseStructureJson,
  isModalOpen,
  closeModal,
  folderData,
  setFolderData,
  findNodeById,
  openModal,
  ContentModal,
  setToggle,
  isLibrary
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onHide();
    setToggle(false);
  };

  const handleAddContentAtEnd = () => {
    AddContentToCourseStructure(newArr, getCourseStructureFolderId);
    // handleClose();
  };

  const handleAddContentInBetween = () => {
    setFolderData(findNodeById(getCourseStructureJson, getCourseStructureFolderId));
    openModal();

  };

  return (
    <StyledModal show={show} onHide={handleClose} backdrop="static" centered>
      <CloseButton onClick={handleClose}><i className="fa fa-times-circle" aria-hidden="true"></i></CloseButton>

      <StyledModalHeader >
        <Modal.Title>
          <HeaderTitle>
            <span className='text-success'><i className="fas fa-hdd mr-2"></i>Course </span>
            <span className='text-secondary'>Drive</span>
            <HeaderUnderline />
          </HeaderTitle>
        </Modal.Title>
      </StyledModalHeader>
      <Modal.Body className="d-flex w-100">
        {/* <div className="shadow-lg bg-body rounded">
          <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ borderBottom: "1px inset" }}>
            <div className="container-fluid">
              <div className="navbar-nav">
                <a className="nav-link">
                  {t("my_files")} &nbsp;&nbsp;
                  <i className="fa fa-angle-right"></i>
                </a>
                {getFolderName && (
                  <a className="nav-link" href="#" style={{ textTransform: "capitalize" }}>
                    <i className="fa fa-folder-open"></i> &nbsp;&nbsp;{getFolderName}
                  </a>
                )}
              </div>
            </div>
          </nav>
        </div> */}

        <div style={{ overflowY: "scroll" }} className="items w-25 h-auto rounded m-3 p-3 shadow">
          {items}
        </div>

        <div className="shadow p-3 bg-body rounded flex-grow m-3">
          {getContentDetails?.length === 0 ? (
            <p style={{ textAlign: "center", marginTop: "15px", fontSize: "20px" }}>
              There are no records to display
            </p>
          ) : (
            <Card>
              <DataTable
                title="Content List"
                columns={columns}
                data={filteredItems}
                defaultSortField="Name"
                defaultSortAsc={true}
                striped
                pagination
                highlightOnHover
                // customStyles={customStyles}
                subHeader
                subHeaderComponent={subHeaderComponent}
                fixedHeader
                // fixedHeaderScrollHeight="300px"
                selectableRows
                onSelectedRowsChange={handleRowSelected}
              />

              <Row style={{ marginBottom: '15px' }}>
                <Col style={{ textAlign: 'center' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                    timeInputLabel="Time:"
                    showTimeInput
                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                    popperPlacement="top-start"
                    minDate={new Date()}
                    disabled={selectedRows.length === 0}
                    showMonthDropdown
                    showYearDropdown
                  />
                </Col>

                <Col style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={handleAddContentAtEnd} disabled={selectedRows.length === 0}>
                    Add Content at End
                  </Button>
                  <div style={{ width: '10px' }}></div>
                  {!isLibrary && <Button onClick={handleAddContentInBetween} disabled={selectedRows.length === 0}>
                    Add Content In-Between
                  </Button>}
                </Col>
              </Row>
            </Card>
          )}
        </div>

        <ContentModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          folderData={folderData}
        />
      </Modal.Body>
      <StyledModalFooter>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </StyledModalFooter>
    </StyledModal>
  );
};


export const ShareUrlModal = ({ shareUrlModal, shareUrlModalHide, getShareUrlData }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={shareUrlModal?.show}
      onHide={shareUrlModalHide}
    >
      <Modal.Body style={{ padding: "0px" }}>
        <div class="container" style={{ width: "75%" }}>
          <div class="copy-text1">
            <input
              type="text"
              class="text"
              value={getShareUrlData}
              readonly
            />
            <button>
              <i class="fa fa-clone"></i>
            </button>
            <div style={{ marginLeft: "35px", marginTop: "12px" }}>
              <a href="#">
                <i
                  class="fas fa-times fa-2x"
                  onClick={shareUrlModalHide}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const CreateModuleTopicModal = ({ getModuleModalState, AddModuleModalHide, errorMsg, startDate, setStartDate, changeBackgroundOut, changeBackgroundOver, AddModule }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={getModuleModalState.show}
      onHide={() => AddModuleModalHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ alignContent: "center" }}
        >
          {t("create_module_topic")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("required_fields")} *
        <div class="mb-3 mt-3">
          <label for="name">{t("name")} : *</label>
          <input
            type="text"
            minLength={2}
            maxLength={200}
            class="form-control"
            id="module_name"
            placeholder={t("enter_folder_name")}
            name="module_name"
          />
          <span style={{ color: "red" }}>{errorMsg}</span>
          <span className="registration_input-msg"></span>
        </div>
        <div class="mb-3 mt-3">
          <label for="publish_date">{t("publish_date_s")} : *</label>
          <br></br>
          {/* <DateTimePicker calendarIcon={<i class="fas fa-calendar" aria-hidden="true"></i>} onChange={setdatePicker} value={getdatePicker} minDate={new Date()} /> */}
          <DatePicker
            value={startDate}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm:ss aa"
            showMonthDropdown
            showYearDropdown
            showTimeInput
            minDate={new Date()}
            placeholderText="Click to show date"
          />

          {/* <input type="datetime-local" min={a} class="form-control" id="publish_date" placeholder="Enter Publish Date" name="publish_date" /> */}
          {/* <span style={{ color: "red" }}>{errorMsg2}</span> */}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant="primary"
          style={{
            background: "linear-gradient(90deg, #11B67A 0%, #009444 100%)",
          }}
          onMouseOut={changeBackgroundOut}
          onMouseOver={changeBackgroundOver}
          id="register"
          onClick={AddModule}
        >
          {t("submit")}
        </Button>
        <Button variant="secondary" onClick={() => AddModuleModalHide()}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}


export const UpdateFolderOrContentDetailsModal = ({ getFolderOrContentDetUpdate, UpdateContentOrFolderModalHide, getPdate, changeBackgroundOut, changeBackgroundOver, updateContentOrModule, category, setPdate, handleAccessTypeChange, errorMsg }) => {

  const { t } = useTranslation()
  console.log("getFolderOrContentDetUpdate", getFolderOrContentDetUpdate)
  return (
    <Modal
      centered
      show={getFolderOrContentDetUpdate?.show}
      onHide={UpdateContentOrFolderModalHide}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ alignContent: "center" }}
        >
          {t("update_folder_or_content_details")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("required_fields")} *
        <div class="mb-3 mt-3">
          <label for="name">{t("name")} <span className='text-danger'>*</span></label>
          <input
            type="text"
            minLength={2}
            maxLength={200}
            class="form-control"
            id="module_name"
            defaultValue={getFolderOrContentDetUpdate?.name}
            placeholder={t("enter_folder_name")}
            name="module_name"
          />
          <span style={{ color: "red" }}>{errorMsg}</span>
          <span className="registration_input-msg"></span>
        </div>
        {
          category === "library" && <div class="mb-3 mt-3">
            <label for="name">Access Type<span className='text-danger'>*</span></label>
            <select class="form-control" id="module_accessType" defaultValue={getFolderOrContentDetUpdate?.accessType}>
              <option value="open">Open</option>
              <option value="restrict">Restrict</option>
            </select>
            <span style={{ color: "red" }}>{errorMsg}</span>
            <span className="registration_input-msg"></span>
          </div>
        }
        {getFolderOrContentDetUpdate?.nodetype !== "folder" && category !== 'library' &&
          <div class="mb-3 mt-3">
            <label for="duration">Duration <span className='text-danger'>*</span></label>
            <input
              type="number"
              minLength={1}
              maxLength={150}
              class="form-control"
              id="module_duration"
              defaultValue={getFolderOrContentDetUpdate?.duration}
              placeholder="Enter duration"
              name="module_duration"
              disabled={(getFolderOrContentDetUpdate?.nodetype === "assignment" || getFolderOrContentDetUpdate?.nodetype === "externalLink")}
            />
            <span style={{ color: "red" }}>{errorMsg}</span>
            <span className="registration_input-msg"></span>
            {getFolderOrContentDetUpdate?.nodetype === "assignment" &&
              <Alert className="my-2" key="assignment" variant="info">
                <i className="fa fa-exclamation-triangle fa-lg px-1" aria-hidden="true"></i>By default the Assignment duration is set to one minute!
              </Alert>
            }
            {getFolderOrContentDetUpdate?.nodetype === "externalLink" &&
              <Alert className="my-2" key="assignment" variant="info">
                <i class="fa fa-exclamation-triangle fa-lg px-1" aria-hidden="true"></i>By default the External-Link duration is set to one minute!
              </Alert>
            }
          </div>
        }
        <div class="mb-3 mt-3">
          <label for="publish_date">{t("publish_date_s")}  <span className='text-danger'>*</span></label>
          <br></br>
          {/* <DateTimePicker calendarIcon={<i class="fas fa-calendar" aria-hidden="true"></i>} onChange={setdatePicker} value={getdatePicker} minDate={new Date()} /> */}
          <DatePicker
            value={new Date(getPdate)}
            selected={new Date(getPdate)}
            onChange={(date) => setPdate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm:ss aa"
            showMonthDropdown
            showYearDropdown
            showTimeInput
            // minDate={new Date()}
            placeholderText="Click to show date"
          />

          {/* <input type="datetime-local" min={a} class="form-control" id="publish_date" placeholder="Enter Publish Date" name="publish_date" /> */}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant="primary"
          style={{
            background: "linear-gradient(90deg, #11B67A 0%, #009444 100%)",
          }}
          onMouseOut={changeBackgroundOut}
          onMouseOver={changeBackgroundOver}
          id="register"
          onClick={updateContentOrModule}
        >
          {t("submit")}
        </Button>
        <Button
          variant="secondary"
          onClick={UpdateContentOrFolderModalHide}
        >
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
};


export const YoutubeLinkModal = ({ getModalState, handleModal2, errorMsg, getFileContentDetails, startDate, setStartDate, changeBackgroundOut, changeBackgroundOver, YoutubeAddContentToCourseStructure }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={getModalState?.show}
      onHide={handleModal2}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ alignContent: "center" }}
        >
          {t("add_youtube_video_link")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("required_fields")} *
        <div class="mb-3 mt-3">
          <label for="name">{t("youtube_link")} * : (Add Link with http://)</label>
          <input
            type="text"
            class="form-control"
            id="youtube_link"
            placeholder={t("enter_youtube_link")}
            name="youtube_link"
          />
          <span style={{ color: "red" }}>{errorMsg}</span>
          <span className="registration_input-msg"></span>
        </div>
        <Row>
          <Col>
            <div class="mb-3 mt-3">
              <label for="name">{t("duration")}* : </label>
              <input
                type="number"
                class="form-control"
                defaultValue={getFileContentDetails?.contentDuration}
                min="1"
                max="60"
                id="duration"
                placeholder={t("enter_duration")}
                name="duration"
              />
              <span style={{ color: "red" }}>{errorMsg}</span>
              <span className="registration_input-msg"></span>
            </div>
          </Col>
          <Col>
            <div class="mb-3 mt-3">
              <label for="publish_date">{t("publish_date")} : *</label>
              <br></br>
              <DatePicker
                value={startDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm:ss aa"
                showMonthDropdown
                showYearDropdown
                showTimeInput
                minDate={new Date()}
                placeholderText="Click to show date"
              />
              <span style={{ color: "red" }}>{errorMsg}</span>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant="primary"
          style={{
            background: "linear-gradient(90deg, #11B67A 0%, #009444 100%)",
          }}
          onMouseOut={changeBackgroundOut}
          onMouseOver={changeBackgroundOver}
          id="register"
          onClick={YoutubeAddContentToCourseStructure}
        >
          {t("add")}
        </Button>
        <Button variant="secondary" onClick={handleModal2}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}


export const TopicFeedbackModal = ({ getTopicFeedback, TopicFeedbackModalHide, courseId, itemIdForFeedback }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={getTopicFeedback?.show}
      onHide={TopicFeedbackModalHide}
      backdrop="static"
      className="custom-modal-style"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ alignContent: "center" }}
        >
          {t("topic_feedback")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TopicFeedback
            courseId={courseId}
            itemIdForFeedback={itemIdForFeedback}
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        {/* <Button variant="primary" style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)' }}
                    onMouseOut={changeBackgroundOut} onMouseOver={changeBackgroundOver} id="register" >
                    Add
                </Button> */}
        <Button variant="secondary" onClick={TopicFeedbackModalHide}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}


export const FeedbackAnalysisModal = ({ modelShow, setModelShow, checkPieChart, dataPie, optionPie, dataBar, optionBar }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={modelShow}
      onHide={() => {
        setModelShow(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ alignContent: "center" }}>
          {t("feedback_analysis")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {//console.log(graphData)} */}
        {checkPieChart ? (
          <>
            <Pie data={dataPie} options={optionPie} />
          </>
        ) : (
          <>
            <Bar data={dataBar} options={optionBar} />
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export const StreamingLinkModal = ({ videoModal, videoModalHide, errorMsg, getFileContentDetails, startDate, setStartDate, changeBackgroundOut, changeBackgroundOver, externalLinkContentToCourseStructure }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={videoModal?.show}
      onHide={() => videoModalHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ alignContent: "center" }}
        >
          Add External Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("required_fields")} *
        <div class="mb-3 mt-3">
          <label for="name">Add External Link* : (Add Link with http://) </label>
          <input
            type="text"
            class="form-control"
            id="externalLink"
            placeholder="Enter External link"
            name="externalLink"
          />
          <span style={{ color: "red" }}>{errorMsg}</span>
          <span className="registration_input-msg"></span>
        </div>
        <Row>
          <Col>
            <div class="mb-3 mt-3">
              <label for="name">{t("duration")} : </label>
              <input
                type="number"
                class="form-control"
                defaultValue={getFileContentDetails?.contentDuration}
                min="1"
                max="60"
                id="duration_videoStreaming"
                placeholder="Enter Duration"
                name="duration"
              />
              <span style={{ color: "red" }}>{errorMsg}</span>
              <span className="registration_input-msg"></span>
            </div>
          </Col>
          <Col>
            <div class="mb-3 mt-3">
              <label for="publish_date">{t("publish_date")} : *</label>
              <br></br>
              <DatePicker
                value={startDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm:ss aa"
                showMonthDropdown
                showYearDropdown
                showTimeInput
                minDate={new Date()}
                placeholderText="Click to show date"
              />
              <span style={{ color: "red" }}>{errorMsg}</span>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant="primary"
          style={{
            background: "linear-gradient(90deg, #11B67A 0%, #009444 100%)",
          }}
          onMouseOut={changeBackgroundOut}
          onMouseOver={changeBackgroundOver}
          id="register"
          onClick={externalLinkContentToCourseStructure}
        >
          {t("add")}
        </Button>
        <Button variant="secondary" onClick={() => videoModalHide()}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

}

export const DrapAndDropModal = ({ dragModalOpen, handleDragModalClose, handleDragEnd, structure, onCourseStructureUpdate }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={dragModalOpen}
      onHide={handleDragModalClose}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Rearrange the Contents according to sequence.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DragDropContext onDragEnd={handleDragEnd}>
          <DragAndDropContainer items={structure?.nodes} type="root" />
        </DragDropContext>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCourseStructureUpdate}>
          Submit
        </Button>
        <Button variant="secondary" onClick={handleDragModalClose}>
          Close
        </Button>
        {/* You can add additional buttons or actions here */}
      </Modal.Footer>
    </Modal>
  )
}

export const FeedbackQuestionViewModal = ({ showFeedbacks, toggleFeedbackView, getFeedbackRow, getInstructors }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={showFeedbacks}
      onHide={toggleFeedbackView}
      size="xl"
      backdrop="static"
      scrollable

    >
      <Modal.Header closeButton>
        <Modal.Title>Feedback questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <DragDropContext onDragEnd={handleDragEnd}>
            <DragAndDropContainer items={structure?.nodes} type="root" />
          </DragDropContext> */}
        <ShowFeedbackComponent row={getFeedbackRow} getInstructors={getInstructors} />

      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={onCourseStructureUpdate}>
            Submit
          </Button> */}
        <Button variant="danger" onClick={toggleFeedbackView}>
          Close
        </Button>
        {/* You can add additional buttons or actions here */}
      </Modal.Footer>
    </Modal>
  )
}

export const AddAssignToCourseStructureModal = ({ getAssignmentList, toggleAssignmentModal, columnViewAssignment, filteredAssignmentList,
  customStyles, subHeaderComponent, handleAssignRowSelected, changeBackgroundOut, changeBackgroundOver, addAssignmentToCourseStructure }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={getAssignmentList?.show}
      onHide={() => toggleAssignmentModal()}
      backdrop="static"
      size="xl"
    >
      <Modal.Header >
        <CloseButton onClick={toggleAssignmentModal}><i className="fa fa-times-circle" aria-hidden="true"></i></CloseButton>

        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ alignContent: "center" }}
        >
          <h6 style={{ textAlign: 'center', fontWeight: 'bold', position: "relative" }}>
            <span className='text-success'>ADD </span>
            <span className='text-secondary'>ASSIGNMENT</span>
            <span style={{ position: 'absolute', left: "15.5%", bottom: "-4px", width: '30%', height: '2px', backgroundColor: '#28a745' }}></span>
          </h6>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          columns={columnViewAssignment}
          data={filteredAssignmentList}
          defaultSortField="Name"
          defaultSortAsc={true}
          striped
          pagination
          highlightOnHover
          customStyles={customStyles}
          subHeader
          subHeaderComponent={subHeaderComponent}
          fixedHeader
          // fixedHeaderScrollHeight="300px"
          selectableRows
          onSelectedRowsChange={handleAssignRowSelected}
        />
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant="primary"
          style={{
            background: "linear-gradient(90deg, #11B67A 0%, #009444 100%)",
          }}
          onMouseOut={changeBackgroundOut}
          onMouseOver={changeBackgroundOver}
          id="register"
          onClick={addAssignmentToCourseStructure}
        >
          {t("add")}
        </Button>
        <Button variant="secondary" onClick={() => toggleAssignmentModal()}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export const ViewAssignModal = ({ getviewAssignment, toggleViewAssignment, assignmentId, userId }) => {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      show={getviewAssignment}
      onHide={() => toggleViewAssignment()}
      backdrop="static"
      size="xl"
    >
      <CloseButton onClick={toggleViewAssignment}><i className="fa fa-times-circle" aria-hidden="true"></i></CloseButton>
      <Modal.Body>
        {/* Assignment Delivery modal component re-used  */}
        <AssignmentDelivery assignmentId={assignmentId} userId={userId} userRole={'admin'} />
      </Modal.Body>
      <Modal.Footer>

        <Button variant="danger" onClick={() => toggleViewAssignment()}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ShowFeedbackComponent = ({ row, getInstructors }) => {
  console.log("feeback row", typeof (row))
  return (
    <Card>
      {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
      <Card.Body className='text-center border'>
        <Card.Title>{row['feedbackTitle']}</Card.Title>
        <Card.Text>
          {row['description']}
        </Card.Text>
        <Table responsive striped>
          <thead>
            <tr>
              <th>S.No</th>
              {/* {Array.from({ length: 12 }).map((_, index) => (
              <th key={index}>Table heading</th>
            ))} */}
              <th>Question</th>
              <th>Question Type</th>
              <th>Options</th>
              <th>Author</th>
            </tr>
          </thead>
          <tbody>
            {
              row['questionMasters'] !== null && row['questionMasters'] !== undefined &&
              row['questionMasters']?.map((question, index) => (
                <tr key={index}>
                  <td>{

                    index + 1}</td>
                  <td>{question.question}</td>
                  <td>{question.questionType === "TF" ? "True/False" : question.questionType === "SC" ? "Single Choice" : "Multiple Choice"}</td>
                  <td>{question?.optionsMasters !== null && question?.optionsMasters.length !== 0 &&
                    question?.optionsMasters.map((option) => (
                      <span className='d-block'>{option?.optionText}</span>
                    ))}
                  </td>
                  <td>
                    {getInstructors[question.updatedBy]?.[0]?.firstName || "--"}
                  </td>


                </tr>
              ))
            }
          </tbody>
        </Table>
      </Card.Body >
    </Card>
  );
};



