import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Sidebar from "../shared/Sidebar";
import UserService from "../../services/UserService";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import StickyMenu from "../../components/common/StickyMenu";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import DataTable from "react-data-table-component";
import { downloadExcel, DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import service from "../../services/service";

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
      // justifyContent: 'center',
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      // justifyContent: 'center',
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};

const customStyles2 = {
    title: {
      style: {
        fontColor: "red",
        fontWeight: "900",
      },
    },
    headCells: {
      style: {
        fontSize: "19px",
        fontWeight: "500",
        paddingLeft: "0 8px",
        marginLeft: "10px",
        
      },
    },
    cells: {
      style: {
        fontSize: "17px",
        background:"#ccf2ff",
        fontWeight:"bold",
        // paddingLeft: '0 8px',
        // marginLeft: '10px'
      },
    },
  };

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

export default function CourseLearnerAndFeesStatus() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const [getCourseStatusList, setCourseStatusList] = useState([]);

  const [getRegistrationData, setRegistrationData] = useState([]);

  let filteredItems = getCourseStatusList;

  let RegistrationData = getRegistrationData;

  useEffect(() => {
    service
      .getCourseandFeesStatus()
      .then((res) => {
        // console.log(res.data);
        setCourseStatusList(res.data);
      })
      .catch((err) => {});

      service.LearnersCountForStatus()
      .then((res) =>{
        //console.log(res.data);
        setRegistrationData(res.data);
      })
      .catch((err) =>{

      })
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "100px",
      sortable: true,
    },
    {
      name: "Course ID",
      selector: (row) => `${row.courseId}`,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Course Name",
      selector: (row) => `${row.courseName}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Course Fees",
      selector: (row) => `${row.courseFees}`,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Total Enrolled",
      selector: (row) => row.totalEnrolledLearner,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Total Approved",
      selector: (row) => row.totalApprovedLearner,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Total Rejected",
      selector: (row) => row.totalRejectedLearner,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Total Confirmed(Fees paid)",
      selector: (row) => row.feesPaidLearner,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Total (Fees Pending)",
      selector: (row) => row.feesPendingLearner,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Course Completed Learner Count",
      selector: (row) => row.learnerCCompletedCount,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Course Incomplete Learner Count",
      selector: (row) => row.learnerCNotCompletesCount,
      sortable: true,
      width: "150px",
      wrap: true,
    },
  ];

  const columns2 = [
    {
      name: "Total Registered Users",
      selector: (row) => `${row.totalCount}`,
      wrap: true,
    },
    {
      name: "Approved Users",
      selector: (row) => `${row.learnerApprovedCount}`,
      wrap: true,
    },
    {
      name: "Rejected Users",
      selector: (row) => `${row.learnerRejectedCount}`,
      wrap: true,
    },
    {
        name: "Users Request Pending",
        selector: (row) => `${row.activatedlearnerCount}`,
        wrap: true,
      },

  ];

  const tableExportExcel = () => {
    var checkData = [];
    const header = ["Course ID", "Course Name", "Course Fees", "Total Enrolled", "Total Approved", "Total Rejected", "Total Confirmed(Fees paid)", "Total (Fees Pending)", "Course Completed Learner Count", "Course Incomplete Learner Count"]
    if (getCourseStatusList !== undefined) {
        getCourseStatusList.map((data) => {
            const courseId = `${data.courseId}`;
            const courseName = `${data.courseName}`;
            const courseFees = `${data.courseFees}`;
            const totalEnrolledLearner = `${data.totalEnrolledLearner}`;
            const totalApprovedLearner = `${data.totalApprovedLearner}`;
            const totalRejectedLearner = `${data.totalRejectedLearner}`;
            const feesPaidLearner = `${data.feesPaidLearner}`;
            const feesPendingLearner = `${data.feesPendingLearner}`;
            const learnerCCompletedCount = `${data.learnerCCompletedCount}`;
            const learnerCNotCompletesCount = `${data.learnerCNotCompletesCount}`;
            
            const instData = [courseId, courseName, courseFees, totalEnrolledLearner, totalApprovedLearner, totalRejectedLearner, feesPaidLearner, feesPendingLearner, learnerCCompletedCount, learnerCNotCompletesCount]
            checkData.push(instData);
        })
    }
    downloadExcel({
        fileName: "Course Status",
        sheet: "Course Status",
        tablePayload: {
            header,
            body: checkData,
        },
    })
  };

  const tableExportPDF = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape"
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(13);
    const title = "Course Learner List";
    const headers = [["Course ID", "Course Name", "Course Fees", "Total Enrolled", "Total Approved", "Total Rejected", "Total Confirmed(Fees paid)", "Total (Fees Pending)", "Course Completed Learner Count", "Course Incomplete Learner Count"]];
    var checkData = [];
    if (getCourseStatusList !== undefined) {
        getCourseStatusList.map((data) => {
            const courseId = `${data.courseId}`;
            const courseName = `${data.courseName}`;
            const courseFees = `${data.courseFees}`;
            const totalEnrolledLearner = `${data.totalEnrolledLearner}`;
            const totalApprovedLearner = `${data.totalApprovedLearner}`;
            const totalRejectedLearner = `${data.totalRejectedLearner}`;
            const feesPaidLearner = `${data.feesPaidLearner}`;
            const feesPendingLearner = `${data.feesPendingLearner}`;
            const learnerCCompletedCount = `${data.learnerCCompletedCount}`;
            const learnerCNotCompletesCount = `${data.learnerCNotCompletesCount}`;

            const instData = [courseId, courseName, courseFees, totalEnrolledLearner, totalApprovedLearner, totalRejectedLearner, feesPaidLearner, feesPendingLearner, learnerCCompletedCount, learnerCNotCompletesCount]
            checkData.push(instData);
        })
    }
    let content = {
        startY: 50,
        head: headers,
        body: checkData
    };
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("CourseStatus.pdf")
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">Course Learner and Fees Status</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="!#" onClick={(event) => event.preventDefault()}>
                      {t("courses")}
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Course Learner and Fees Status
                  </li>
                </ol>
              </nav>
            </div>
            <div class="card" style={{ padding: "40px" }}>

            <Row>
            <Col lg="12">
                  <div>
                    <h4 style={{ textAlign: "center" , fontWeight:"bold"}}>
                    Registered Learner Status
                    </h4>
                  </div>
                  </Col>
              </Row>
              <br></br>
              <Row>
              <Col lg={{span: 7, offset: 2}}>
                <div style={{ overflowX: "auto" }}>
                  <DataTable
                    columns={columns2}
                    data={RegistrationData}
                    // striped
                    highlightOnHover
                    customStyles={customStyles2}
                  />
                </div>
                </Col>
              </Row>
              <br></br>

              {/* <Col lg="9"> */}
              <Row>
                <Col lg="12">
                  <div>
                    <h4 style={{ textAlign: "center" , fontWeight:"bold"}}>
                      Course Learner and Fees Status
                    </h4>
                  </div>
                </Col>
              </Row>
              <Col lg="3">
                  <div className="d-flex px-2">
                    <Button
                      onClick={tableExportExcel}
                      style={{
                        marginRight: "5px",
                        background: "#f0ad4e",
                        border: "0px",
                      }}
                    >
                      {" "}
                      {t("export_to_excel")}{" "}
                    </Button>
                    <Button
                      onClick={tableExportPDF}
                      style={{ background: "#f0ad4e", border: "0px" }}
                    >
                      {" "}
                      {t("export_to_pdf")}{" "}
                    </Button>
                  </div>
                </Col>
              <Row>
              </Row>
              <br></br>
              <Row>
                <div style={{ overflowX: "auto" }}>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    striped
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                  />
                </div>
              </Row>
              {/* </Col> */}
            </div>
          </div>
          <SettingsPanel />
        </div>
        <Footer />
      </div>
    </div>
  );
}
