import React, { useEffect, useState, useMemo } from "react";
import StickyMenu from "../../components/common/StickyMenu";
import FilterDataTable from "../../pages/instructor/FilterDataTable";
import adminServices from "../../services/adminServices";
import learnerService from "../../services/learnerService";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import Sidebar from "../shared/Sidebar";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UserService from "../../services/UserService";
import USER_API from '../../services/service';

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import moment from "moment";
import { Circles } from 'react-loader-spinner';
import { PreviewModal } from "../../pages/instructor/instCourses/CourseStructureDriveNew";

const languages = [
    {
        code: "en",
        name: "English",
        country_code: "gb",
    },
    {
        code: "hi",
        name: "Hindi",
        country_code: "in",
    },
];

const customStyles = {
    title: {
        style: {
            color: "#333",
            fontWeight: "700",
            fontSize: "22px",
            marginBottom: "20px",
        },
    },
    headCells: {
        style: {
            backgroundColor: "#f4f5f7",
            color: "#555",
            fontSize: "16px",
            fontWeight: "600",
            padding: "10px",
            borderBottom: "2px solid #ddd",
        },
    },
    cells: {
        style: {
            fontSize: "14px",
            padding: "10px",
            borderBottom: "1px solid #eee",
        },
    },
    rows: {
        style: {
            minHeight: "60px",
            '&:nth-child(even)': {
                backgroundColor: "#f9f9f9",
            },
        },
    },
    pagination: {
        style: {
            borderTop: "1px solid #ddd",
            padding: "10px",
        },
    },
};

const AssignInstructor = () => {
    const currentLanguageCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        document.body.dir = currentLanguage.dir || "ltr";
        document.title = t("app_title");
    }, [currentLanguage, t]);

    useEffect(() => {
        UserService.generateToken();
    }, []);

    const um_api = USER_API;

    const [learnerData, setLearnerData] = useState([]);
    const [searchEmailId, setSearchEmailId] = useState("");
    const [loading, setLoading] = useState(false);
    const [assignLoading, setAssignLoading] = useState(false);

    const [IDEmail, setIDEmail] = useState();
    const [getViewID, setViewID] = useState({
        show: false
    });
    const [IDLoading, setIDLoading] = useState({
        isLoading: false
    })

    const viewID = (idproofpicpath, email) => {
        setIDEmail(email);
        setIDLoading({ isLoading: true });
        setViewID({
            show: true,
            idproofpicpath: idproofpicpath,
        });
    }

    const IDCardHide = () => {
        setViewID({ show: false });
        setIDLoading({ isLoading: false });
    }

    const getLearnerData = () => {
        adminServices
            .searchUserByEmail(searchEmailId)
            .then((resp) => {
                //console.log(resp.data);
                setLearnerData(resp.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };


    const onClickAssignInstructor = (userId) => {
        setAssignLoading(true);
        setLoading(true);
        adminServices.assignAsInstructor(userId)
            .then((res) => {
                if (res.status === 200) {
                    swal(`${t('success')}`, res.data, "success");
                }
            })
            .catch((err) => {
                setAssignLoading(false);
                setLoading(false);
                if (err.response.status === 404) {
                    swal(`${t('not_found')}`, "Not Found", 'error');
                } else {
                    swal(`Error`, "Internal Server Error", 'error');
                }
            })
            .finally(() => {
                getLearnerData();
                setAssignLoading(false);
                setLoading(false);
            });
    }

    const onClickRemoveInstructor = (userId) => {
        setAssignLoading(true);
        setLoading(true);
        adminServices.removeAsInstructor(userId)
            .then((res) => {
                if (res.status === 200) {
                    swal(`${t('success')}`, res.data, "success");


                }
            })
            .catch((err) => {
                setAssignLoading(false);
                setLoading(false);
                if (err.response.status === 404) {
                    swal(`${t('not_found')}`, err.response.data, 'error');
                } else {
                    swal(`Error`, err.response.data, 'error');
                }
            })
            .finally(() => {
                getLearnerData();
                setAssignLoading(false);
                setLoading(false);
            });
    }

    const [enableLearnerName, setenableLearnerName] = useState();
    const [disableLearnerName, setDisableLearnerName] = useState();

    const enableUser = (userId) => {
        setenableLearnerName(userId);
        setEnableLoading({ isLoading: true });
        setLoading(true);

        adminServices
            .enableUser(userId)
            .then((resp) => {
                if (resp.status === 200) {
                    swal(`${t("success")}`, `${t("user_enable_msg")}`, "success");

                }
            })
            .catch((err) => {
                setEnableLoading({ isLoading: false });
                setLoading(false);
                swal(
                    `${t("error")}`,
                    `${t("something_went_wrong_try_later")}`,
                    "error"
                );
            })
            .finally(() => {
                getLearnerData();
                setEnableLoading({ isLoading: false });
                setLoading(false);
            });
    };

    const disableUser = (userId) => {
        setDisableLearnerName(userId);
        setDisableLoading({ isLoading: true });
        adminServices
            .disableUser(userId)
            .then((resp) => {
                if (resp.status === 200) {
                    swal(t("success"), t("user_disable_msg"), "success");

                }
            })
            .catch((err) => {
                setDisableLoading({ isLoading: false });
                swal(
                    `${t("error")}`,
                    `${t("something_went_wrong_try_later")}`,
                    "error"
                );
            })
            .finally(() => {
                getLearnerData();
                setDisableLoading({ isLoading: false });
            });
    };

    const columns = [
        {
            name: "Learner",
            cell: (row) => (
                <img
                    src={um_api + `public/getprofilepic/${row.learnerUsername}`}
                    alt="profile"
                    style={{
                        width: '42px',
                        height: '42px',
                        borderRadius: '100%',
                        boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)",
                        objectFit: 'cover',
                    }}
                />
            ),
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => `${row.firstName} ${row.lastName}`,
            sortable: true,
            wrap: true,
            style: {
                color: '#333',
                fontWeight: '500',
            },
        },
        {
            name: "Email",
            selector: (row) => row.email,
            wrap: true,
            sortable: true,
            style: {
                color: '#555',
            },
        },
        {
            name: "Documents",
            //selector: row => row.gpfno,
            sortable: true,
            cell: (row) => <div>
                <div className="d-inline-block">
                    <button
                        onClick={() => viewID(row.idproofpicpath, row.email, row.idcardType)}
                        type="button" class="btn btn-info m-2" disabled={IDLoading.isLoading ? "true" : ""}>
                        {
                            IDLoading.isLoading ?
                                IDEmail === row.email ?
                                    (
                                        <>
                                            <i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <i class="fas fa-id-card fa-2x"></i>
                                        </>
                                    ) : (
                                    <>
                                        <i class="fas fa-id-card fa-2x">
                                        </i>
                                    </>
                                )}
                    </button>
                </div>
            </div>

        },
        {
            name: "Action",
            sortable: true,
            wrap: true,
            cell: (row) => (
                <div className="d-flex justify-content-start flex-wrap">
                    <span className="d-inline-block m-1">
                        <button
                            onClick={() =>
                                shareUrlModalShow(
                                    row.firstName,
                                    row.lastName,
                                    row.email,
                                    row.mobile,
                                    row.designation,
                                    row.serviceDistrictName
                                    // row.stateName,
                                    // row.gender,
                                    // row.address,
                                    // row.countryName,
                                    // row.city,
                                    // row.pincode,
                                    // row.dob,
                                    // row.userDesignation,
                                    // row.userNationalityName,
                                    // row.organizationName,
                                    // row.userCategoryName,
                                    // row.eduQualification,
                                    // row.rankName,
                                    // row.instituteName,
                                    // row.idproofNumber
                                )
                            }
                            type="button"
                            className="btn btn-info"
                            style={{ width: '150px' }}
                            disabled={infoLoading.isLoading}
                        >
                            {infoLoading.isLoading && ShareUrlModal.email === row.email ? (
                                <>{t("loading")}</>
                            ) : (
                                <>
                                    <i className="fa fa-info-circle"></i> {t("info")}
                                </>
                            )}
                        </button>
                    </span>

                    {row.role == "2" ? (
                        <span className="d-inline-block m-1">
                            <button
                                onClick={() => onClickRemoveInstructor(row.learnerUsername)}
                                type="button"
                                className="btn btn-warning"
                                style={{ width: '150px' }}
                            >
                                {assignLoading ? t("loading") : "Remove as Instructor"}
                            </button>
                        </span>
                    ) : (
                        <span className="d-inline-block m-1">
                            <button
                                onClick={() => onClickAssignInstructor(row.learnerUsername)}
                                type="button"
                                className="btn btn-success"
                                style={{ width: '150px' }}
                            >
                                {assignLoading ? t("loading") : "Assign as Instructor"}
                            </button>
                        </span>
                    )}

                    {row.status === "blocked" ? (
                        <span className="d-inline-block m-1">
                            <button
                                onClick={() => enableUser(row.learnerUsername)}
                                type="button"
                                className="btn btn-success"
                                style={{ width: '150px' }}
                            >
                                {enableLoading.isLoading && enableLearnerName === row.learnerUsername ? (
                                    t("loading")
                                ) : (
                                    <>
                                        <i className="fa fa-unlock"></i> {t("enable")}
                                    </>
                                )}
                            </button>
                        </span>
                    ) : null}

                    {row.status !== "blocked" ? (
                        <span className="d-inline-block m-1">
                            <button
                                type="button"
                                onClick={() => disableUser(row.learnerUsername)}
                                className="btn btn-danger"
                                style={{ width: '150px' }}
                            >
                                {disableLoading.isLoading && disableLearnerName === row.learnerUsername ? (
                                    t("loading")
                                ) : (
                                    <>
                                        <i className="fa fa-lock"></i> {t("disable")}
                                    </>
                                )}
                            </button>
                        </span>
                    ) : null}
                </div>
            )
        }
    ];

    const [ShareUrlModal, setShareUrlModal] = useState({
        show: false,
    });

    const [infoLoading, setInfoLoading] = useState({
        isLoading: false,
    });
    const [enableLoading, setEnableLoading] = useState({
        isLoading: false,
    });
    const [disableLoading, setDisableLoading] = useState({
        isLoading: false,
    });

    const shareUrlModalHide = () => {
        setShareUrlModal({ show: false });
        setInfoLoading({ isLoading: false });
    };

    const shareUrlModalShow = (
        firstname,
        lastname,
        email,
        mobile,
        designation,
        serviceDistrictName
        // gender,
        // address,
        // countryName,
        // city,
        // pincode,
        // dob,
        // stateName,
        // userDesignation,
        // userNationalityName,
        // organizationName,
        // userCategoryName,
        // eduQualification,
        // rankName,
        // instituteName,
        // idproofNumber,
    ) => {
        setInfoLoading({ isLoading: true });
        setShareUrlModal({
            show: true,
            firstName: firstname,
            lastName: lastname,
            email: email,
            mobile: mobile,
            designation: designation,
            serviceDistrictName: serviceDistrictName,
            // stateName: stateName,
            // gender: gender,
            // address: address,
            // country: countryName,
            // city: city,
            // pincode: pincode,
            // dob: dob,
            // userDesignation: userDesignation,
            // userNationalityName: userNationalityName,
            // organizationName: organizationName,
            // userCategoryName: userCategoryName,
            // eduQualification: eduQualification,
            // rankName: rankName,
            // instituteName: instituteName,
            // idproofNumber: idproofNumber,
        });
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                {loading && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                        }}
                    >
                        <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
                    </div>
                )}
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header" style={{ marginBottom: '20px' }}>
                            <h3 className="page-title" style={{ color: '#333', fontSize: '24px', fontWeight: '700' }}>
                                {t("assign_inst")}
                            </h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="!#" onClick={(event) => event.preventDefault()} style={{ color: '#007bff', textDecoration: 'none' }}>
                                            {t("assign_inst")}
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t("assign_inst")}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{t("search_user")}</h4>
                                        <div className="form-group">
                                            <div className="d-flex flex-column flex-md-row align-items-center">
                                                <input
                                                    type="email"
                                                    className="form-control mb-2 mb-md-0 me-md-2 " // Margin bottom on small screens, right margin on medium+
                                                    placeholder={t("enter_user_email")}
                                                    value={searchEmailId}
                                                    onChange={(e) => setSearchEmailId(e.target.value)}
                                                    style={{ flexGrow: 1 }}
                                                />
                                                <Button
                                                    variant="primary"
                                                    onClick={getLearnerData}
                                                    className="btn btn-primary"
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    {t("search")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            data={learnerData}
                                            striped
                                            highlightOnHover
                                            customStyles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal
                centered
                show={ShareUrlModal.show}
                onHide={() => shareUrlModalHide()}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontWeight: '600' }}>
                        {t("user_details")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Name : </td>
                                <td>
                                    {ShareUrlModal.firstName} {ShareUrlModal.lastName}
                                </td>
                            </tr>
                            <tr>
                                <td>Email : </td>
                                <td>{ShareUrlModal.email}</td>
                            </tr>
                            <tr>
                                <td>Mobile : </td>
                                <td>{ShareUrlModal.mobile}</td>
                            </tr>
                            <tr>
                                <td>Designation : </td>
                                <td>{ShareUrlModal.designation}</td>
                            </tr>
                            <tr>
                                <td>Service District/Establishment: </td>
                                <td>{ShareUrlModal.serviceDistrictName}</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "flex-end" }}>
                    <Button variant="secondary" onClick={() => shareUrlModalHide()}>
                        {t("cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <PreviewModal
                show={getViewID.show}
                onHide={() => IDCardHide()}
                contentType={"img"}
                url={um_api + `api/getidforadminverification/${getViewID.idproofpicpath}`}
                videoEncodeStatus={"false"}
            />
        </div>
    );
}

export default AssignInstructor;
