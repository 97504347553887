import React, { useState, useEffect } from 'react';
import "./ContentDeliveryArea.css";
import service, { Assessment_Delivery, DMS, DMS_URL, Frontend_UI, URI } from '../../../../services/service';
import axios from 'axios';
import CryptoJS from "crypto-js";
import ProgressBar from '@ramonak/react-progress-bar';
import { Circles } from 'react-loader-spinner';
import ViewPdf from '../../../instructor/ViewPdf';
import ReactPlayer from 'react-player';
import MediaContentView from '../../components/MediaContentView';
import { AssignmentDelivery } from '../Assignment/AssignmentDelivery';
// import { AssignmentDelivery } from '../Assignment/AssignmentDelivery';

const ContentDeliveryArea = ({
  mergedCourseStructure,
  selectedContent,
  sideBarOpen,
  userId,
  courseId,
  sessionId,
  url,
  showContent,
  activityProgress,
  videoEncodeStatus,
  contentLoader,
}) => {

  const [externalLinkOpened, setExternalLinkOpened] = useState(false);
  const [getContentUrl, setContentUrl] = useState('#');

  console.log("url assignment", url)
  console.log("selectedContent", url)
  console.log("videoEncodeStatus",videoEncodeStatus)

  useEffect(() => {
    setExternalLinkOpened(false);
  }, [selectedContent]);

  useEffect(() => {
    if (selectedContent?.nodetype === "externalLink" && url && showContent && selectedContent?.activityProgressforDependency === 100 && !externalLinkOpened) {
      setExternalLinkOpened(true);
      window.open(url, "_blank");
    }
  }, [selectedContent, url, showContent, externalLinkOpened]);

  // for generate the token url for image and mp4
  useEffect(()=>{},[getContentUrl])
  useEffect(() => {
    const fetchContent = async () => {
      if (url && selectedContent?.activityProgressforDependency === 100) {
        setContentUrl("#")
       
        switch (selectedContent?.nodetype) {
          case 'mp4':
          case 'png':
          case 'jpeg':
          case 'jpg':
            try {
              let response = await axios.get(`${URI.SERVER?._DMS_URL}/${url}`, {
                responseType: 'blob'
              });

              let blobUrl = URL.createObjectURL(response.data);
              setContentUrl(blobUrl);
              // setContentType(nodeType);
              
            } catch (error) {
              console.error('Error fetching content:', error);
              setContentUrl('#');
            }
            break;
          default: break;

        }

      }
    };

    fetchContent();

    // Cleanup function
    return () => {
      if (getContentUrl !== '#') {
        URL.revokeObjectURL(getContentUrl);
      }
    };
  // }, []);

  }, [url, selectedContent?.activityProgressforDependency]);


  const ContentDisplay = () => {

    if (!showContent) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <Circles
              height={40}
              width={40}
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> */}
          </div>
        </div>
      )
    }
    else if (selectedContent?.nodetype === "VLabs" && showContent) {

      return (
        (selectedContent?.activityProgressforDependency === 100) ? (
          <>
            <div>
              Check next tab
            </div>
          </>
        ) : (
          <>
            <h6>
              Complete previous contents
            </h6>
          </>
        ));
    }
    else if (selectedContent?.nodetype === "mp4" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}

            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (

            videoEncodeStatus === "success" && url != "" ? (
              <div>
                <MediaContentView cmsUrl={url} />
              </div>
            ) : (
              <div>
                <video  key={getContentUrl} width="100%" height="100%" controls autoPlay>
                  <source
                    // src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
                    src={getContentUrl}
                    type="video/mp4"
                  />
                </video>
              </div>
            )
          ) : (
            <div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}
        </>
      );
    } else if ((selectedContent?.nodetype === "png" || selectedContent?.nodetype === "jpg" || selectedContent?.nodetype === "jpeg") && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>

          {(selectedContent?.activityProgressforDependency === 100) ? (
            <div>
              <figure>
                <img
                  // src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
                  key={getContentUrl}
                  src={getContentUrl}
                  width="100%"
                  height="100%"
                  alt='#'
                />
              </figure>
            </div>
          ) : (
            <div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}

        </>
      );
    } else if ((selectedContent?.nodetype === "scorm" || selectedContent?.nodetype === "html") && url && showContent) {
      return (

        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <object
              data={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
              width="100%" height="700" type="text/html"></object>
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "zip" && url && showContent) {
      return (

        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <iframe
              src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
              width="100%"
              height="700px"
              allowFullScreen={true}
              style={{ minHeight: "700px", overflow: "auto" }}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>


      );
    }

    else if ((selectedContent?.nodetype === "practiceQuiz" || selectedContent?.nodetype === "pretestQuiz" || selectedContent?.nodetype === "finalQuiz") && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
            <div style={{ marginTop: '10px', fontSize: '16px', fontWeight: 'bold' }}>
              Redirecting, Please Wait...
            </div>
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ?
            <>
              {/* {window.location.replace(URI.SERVER?._ASSESSMENT_DELIVERY + `/${url}`)} */}
              {
                //Auto assign  all the available final quizzes if the autoAssign flag is set to true or enabled by instructor
                // setTimeout(() => {
                window.location.replace(URI.SERVER?._ASSESSMENT_DELIVERY + `/${url}`)
                // }, 2000)
              }
            </>
            : <>
              <div>
                <h6>
                  Complete previous contents
                </h6>
              </div>
            </>
          }
        </>
      );
    }

    else if (selectedContent?.nodetype === "pdf" && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <ViewPdf
              height={"1200px"}
              pdfUrl={`${URI.SERVER?._DOMAIN_URL}/pdfViewer/${url}`}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>
      );
    }
    else if (selectedContent?.nodetype === "youtube" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <ReactPlayer
              url={selectedContent?.activityProgressforDependency === 100 ? `${url}` : "#"}
              width="100%"
              height="700px"
              controls="true"
              config={{
                youtube: {
                  playerVars: { showinfo: 1 }
                }
              }}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "streaming" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <iframe
              title="Embedded HTML Page"
              src={selectedContent?.activityProgressforDependency === 100 ? `${url}` : "#"}
              width="100%"
              height="800"
              allowFullScreen
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>
      );
    }
    else if (selectedContent?.nodetype === "externalLink" && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {selectedContent?.activityProgressforDependency === 100 ? (
            <h6>Link will open in new tab...</h6>
          ) : (
            <h6>Complete previous contents</h6>
          )}
        </>
      );
    }
    else if (selectedContent?.nodetype === "assignment" && url && showContent) {
      return <AssignmentDelivery assignmentId={url} userId={userId} userRole={`learner`} />
    }
    else {
      return (
        <div></div>
      )
    }
  };

  useEffect(() => {
    ContentDisplay();
    // console.log("ContentDisplay useeffcetCalled");
  }, [url])

  // console.log("URL: ", url);
  // console.log("CONTENT AREA selectedContent: ", selectedContent);
  // console.log("CONTENT AREA previousContent: ", selectedContent);
  // console.log("updatedSelectedContentData:", updatedSelectedContentData);
  // console.log("activityProgress: ", activityProgress);
  // console.log("intervalDuration: ", intervalDuration);


  return (
    <div className='content-delivery-area-main'>
      <div className={`content-display-area ${sideBarOpen ? '' : 'open'}`}>

        {Object.keys(selectedContent).length == 0 && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            < h3 > Please Select a Content</h3>
          </div>
        )}
        {
          selectedContent && (
            <div className='content-display'>
              {ContentDisplay()}
            </div>
          )}
      </div>

    </div >
  )
}

export default ContentDeliveryArea