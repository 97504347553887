import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import React, { useEffect, useState, useMemo } from 'react';
import StickyMenu from '../../components/common/StickyMenu';
import { Button } from 'react-bootstrap';
import adminServices from '../../services/adminServices';
import UserService from '../../services/UserService';
import DataTable from "react-data-table-component";
import FilterDataTable from '../../pages/instructor/FilterDataTable';
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import Swal from 'sweetalert2';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]

const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};


function BulkUserRegistration() {
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()   

    const defaultLearnerState = {
        learnerData: [],
        learnerValidation: {},
        learnerServiceData: []
    };

    const [respShow, setRespShow] = useState()

    const [file, setFile] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [learner, setLearner] = useState(defaultLearnerState);


    const handleFileChange = (event) => {
        onClickCancel();
        if (event.target.files[0]) {
            console.log(event.target.files[0])
            const selectedFile = event.target.files[0];
            setFile(selectedFile)
        }
        else {
            console.log("ZZZZ : Not Selected")
            setFile(false)
        }

    };

    useEffect(() => {
        console.log(learner)
    }, [learner])

    const uploadFile = () => {
        onClickCancel();
        setShowTable(true);
        if (!file) {
            return
        }
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = (e) => {
            const excelData = e.target.result
            const workBook = XLSX.read(excelData, { type: 'buffer' })
            const workSheetName = workBook.SheetNames[0]
            const workSheet = workBook.Sheets[workSheetName]
            const excelJSON = XLSX.utils.sheet_to_json(workSheet)
            setLearner((prevState) => {
                return {
                    ...prevState,
                    learnerData: excelJSON
                }
            })
            console.log("excelJSON : ", learner.learnerData)
            validate(excelJSON)
        }
        // setTimeout(()=>{
        //     validate();
        // },[2000])

    }

    const validate = (excelJSON) => {
        // console.log(learner.learnerData)
        excelJSON.map((row) => {
            console.log("insde Validate", row)
            // console.log("1st : ", row.firstName.match(/^[A-Za-z\s]+$/))
            console.log("1st : ", (/^[A-Za-z\s]+$/).test(row.firstName))
            if ((/^[A-Za-z\s]+$/).test(row.firstName.trim()) && (/^[A-Za-z\s]+$/).test(row.lastName.trim()) && (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(row.emailId.toString().trim())) {
                const validateRow = {
                    valid: true,
                    row: row
                }
                setLearner((prevState) => {
                    return {
                        ...prevState,
                        learnerValidation: {
                            ...prevState.learnerValidation,
                            [row.emailId]: validateRow
                        }
                    }
                })
            }
        })
    }

    const columns = [
        {
            name: "S.No.",
            selector: (row, index) => index + 1,
            sortable: true,
            wrap: true,
        },
        {
            name: "First Name",
            selector: row => row.firstName,
            sortable: true,
            wrap: true
        },
        {
            name: "Last Name",
            selector: row => row.lastName,
            sortable: true
        },
        // {
        //     name: "Mobile No",
        //     selector: row => row.mobileNo,
        //     sortable: true,
        //     wrap: true
        // },
        {
            name: "Email ID",
            selector: row => row.emailId,
            sortable: true,
            wrap: true
        },
        {
            name: "Status",
            cell: row =>
                <>
                    {
                        learner.learnerValidation[row.emailId]?.valid ?
                            < span className='text-info font-weight-bold'><i class="fa fa-check" aria-hidden="true" style={{ fontSize: "25px" }}></i></span>
                            :
                            <>
                                < span className='text-danger font-weight-bold'><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "25px" }}></i></span>
                            </>
                    }
                </>,
            sortable: true,
            wrap: true
        }
    ];

    const respColumns = [
        {
            name: "S.No.",
            selector: (row, index) => index + 1,
            sortable: true,
            wrap: true,
        },
        {
            name: "First Name",
            selector: row => row.firstName,
            sortable: true,
            wrap: true
        },
        {
            name: "Last Name",
            selector: row => row.lastName,
            sortable: true
        },
        // {
        //     name: "Mobile No",
        //     selector: row => row.mobileNo,
        //     sortable: true,
        //     wrap: true
        // },
        {
            name: "Email ID",
            selector: row => row.emailId,
            sortable: true,
            wrap: true
        },
        {
            name: "Status",
            cell: row =>
                <>
                    {
                        row.status === "Success" ?
                            <small className='text-uppercase text-success font-weight-bold'>Registered</small>
                            :
                            <>
                                <small className='text-uppercase text-danger font-weight-bold'>Already <br /> Registered</small>
                            </>
                    }
                </>,
            sortable: true,
            wrap: true
        }
    ];

    const tableHandlerRow = (row) => {
        console.log(row)
    }

    const onClickSubmit = () => {
        const listofUsers = Object.keys(learner.learnerValidation).map((userEmail) => {
            return learner.learnerValidation[userEmail].row
        })
        Swal.fire({
            title: "Please Wait",
            text: "Data is Uploading",
            didOpen: () => {
                Swal.showLoading();
                console.log(listofUsers)
                adminServices.learnerBulkUpload(listofUsers, UserService.getUserid()).then((resp) => {
                    console.log(resp.data)
                    if (resp.status == 200) {
                        setRespShow(true);
                        setLearner({
                            learnerData: resp.data,
                        })
                        Swal.fire({
                            icon: 'success',
                            title: 'Learners Added Successfully',
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                }).catch((e) => {
                    Swal.fire({
                        icon: "error",
                        text: "Something Went Wrong , Try Later",
                        showConfirmButton: false,
                        timer: 3000
                    })
                })

            }
        })


    }

    const onClickCancel = () => {
        setRespShow(false);
        setShowTable(false)
        setLearner({
            ...learner,
            learnerData: [],
            learnerValidation: {},
            learnerServiceData: []
        })
        setFile(false);
    }

    useEffect(() => {
        console.log("fileZZZZZ", file)
    }, [file])



    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <StickyMenu />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="page-header">
                                <h3 className="page-title">Learner Bulk Upload</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Learner</a></li>
                                        <li className="breadcrumb-item active" aria-current="page"> Learner bulk Upload</li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row' style={{ justifyContent: "space-evenly" }}>
                                                <div className='col-md-6 col-xl-8 '>
                                                    <h4 className="card-title">Instructions For Learner Bulk Registrations </h4>
                                                    <ol>
                                                        <li>Use the Excel template provided on the right hand side.</li>
                                                        <li>It is recommended to upload 250 user records at a time to avoid show registrations.</li>
                                                    </ol>
                                                    <div>
                                                        <span className="input-group-addon ">
                                                            <label className='btn btn-success' htmlFor="chooseFile">
                                                                Choose Excel File
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                id="chooseFile"
                                                                style={{ display: 'none' }}
                                                                onChange={handleFileChange}
                                                                onClick={(e) => { e.target.value = "" }}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div >
                                                        <button className='btn btn-success' disabled={file === false} onClick={() => { uploadFile() }}>Upload File</button>
                                                        <Button className='btn btn-primary mt-2 m-sm-2 m-md-2' href={process.env.PUBLIC_URL + `/assets/images/Learner.xlsx`} target="_blank" variant="primary">Download Template</Button>
                                                    </div>
                                                </div>
                                                <div className='col-md-5 offset-md-1 col-xl-3 offset-xl-1'>
                                                    <img src={`${process.env.PUBLIC_URL + '/assets/images/excel.png'}`} style={{ height: "170px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                showTable ?
                                    <>
                                        <div className="row">
                                            <div className="col-12 grid-margin stretch-card">
                                                <div className="card">
                                                    {
                                                        respShow ?
                                                            <>
                                                                <div className="card-body">
                                                                    <h4 className="card-header bg-transparent border-0 text-center text-uppercase font-weight-bold">Learner Bulk Upload Status</h4>
                                                                    <DataTable
                                                                        columns={respColumns}
                                                                        data={learner.learnerData}
                                                                        defaultSortField="Name"
                                                                        defaultSortAsc={true}
                                                                        striped
                                                                        pagination
                                                                        highlightOnHover
                                                                        onRowClicked={tableHandlerRow}

                                                                    />
                                                                    <button className='btn btn-danger' onClick={() => { onClickCancel() }}>Close</button>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="card-body">
                                                                    <h4 className="card-header bg-transparent border-0 text-center text-uppercase font-weight-bold">Learner Bulk Upload</h4>
                                                                    <DataTable
                                                                        columns={columns}
                                                                        data={learner.learnerData}
                                                                        defaultSortField="Name"
                                                                        defaultSortAsc={true}
                                                                        striped
                                                                        pagination
                                                                        highlightOnHover
                                                                        onRowClicked={tableHandlerRow}

                                                                    />
                                                                    <button className='btn btn-success' onClick={() => { onClickSubmit() }}>Submit Data</button>
                                                                    <button className='btn btn-danger' onClick={() => { onClickCancel() }}>Cancel</button>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkUserRegistration;