import React, { Component } from 'react'

function Spinner() {
  return (
    <div>
      <div className="spinner-wrapper">
        <div className="donut"></div>
      </div>
    </div>
  )
}


export default Spinner
