import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  TabPane,
  Table,
} from "react-bootstrap";
import { Styles, StyledModal, StyledModalHeader, StyledModalFooter, HeaderUnderline, HeaderTitle, CloseButton } from "./styles/coursedetails.js";
// import { StyledModal, StyledModalHeader, StyledModalFooter } from './StyledModal';
// import { Styles } from "../../../app/Courses/styles/course1.js";
import FooterTwo from "../../../components/FooterTwo.js";
import HeaderTwo from "../../../components/HeaderTwo.js";
import { BreadcrumbBox } from "../../../components/common/Breadcrumb.js";
import ReviewForm from "../../courses/components/ReviewForm.js";
import instructorService from "../../../services/instructorService.js";
import swal from "sweetalert";
import "./styles/styles.scss";
import Videojs from "./video.js";
import DataTable from "react-data-table-component";
import FilterDataTable from "../FilterDataTable.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import PopularCourse from '../../courses/components/PopularCourse'
// import CourseTag from '../../courses/components/CourseTag'
import TreeMenu from "react-simple-tree-menu";
import "../../../../node_modules/react-simple-tree-menu/dist/main.css";
import { Alert, Form, ListGroup, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/tree.css";
import Timer from "react-compound-timer";
import service, { CREATE_QUIZ_URL, DMS, URI, urlsDefined } from "../../../services/service.js";
import UserService from "../../../services/UserService.js";
import { UncontrolledCollapse } from "reactstrap";
import "../styles.css";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import DiscussionMain from "../../discussion/DiscussionMain.js";
import Accordion from "react-bootstrap/Accordion";
import Query from "../../courses/Query/Query.js";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import TopicFeedback from "./TopicFeedback.js";
import FrontAssignment from "./assignment/FrontAssignment.js";
import CompletionCriteria from "./CompletionCriteria.js";
import { Bar, Pie } from "react-chartjs-2";
import { ConsoleView } from "react-device-detect";
import axios from "axios";
import md5 from "md5";
import moment from "moment";
import ViewPdf from "../ViewPdf.js";
import CryptoJS from "crypto-js";
import {
  DMS_URL,
  COURSE_URL,
  USER_API,
  Frontend_UI,
  Assessment_Authoring,
} from "../../../services/service.js";
import { DragDropContext } from "react-beautiful-dnd";
import DragAndDropContainer from "./DragAndDropContainer.js";
import ToggleButton from "react-toggle-button";
import Switch from "react-switch";
// import { GlobalContext } from "../../../pages/courses/contentdelivery/contexts/GlobalContext.js";
import { Rating } from 'react-simple-star-rating';
import ContentReviews from '../../courses/Reviews/ContentReviews.js';
import MediaContentView from "../../courses/components/MediaContentView.js";
import useNonce from "../../../helper/Nonce.js";
import { AddAssignToCourseStructureModal, CourseDriveModal, CreateModuleTopicModal, DrapAndDropModal, FeedbackAnalysisModal, FeedbackQuestionViewModal, ShareUrlModal, StreamingLinkModal, TopicFeedbackModal, UpdateFolderOrContentDetailsModal, ViewAssignModal, YoutubeLinkModal } from "./instCourseComponents/instCourseModals.js";
import { PreviewModal } from "./CourseStructureDriveNew.js";
import AssignmentService from "../../../services/AssignmentService.js";
import { AssignmentDelivery } from "../../courses/contentDeliveryUpdated/Assignment/AssignmentDelivery.js";


const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      fontWeight: "500",
      textTransform: "uppercase",
      // paddingLeft: '0 8px',
      // marginLeft: '10px',
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];




function InstCourseDetails(props) {
  // console.log("RANDOm Logger");

  const fetchAndSetNonce = useNonce();


  // variables-
  let tenantId = 1;
  let UserId = UserService.getUserid();
  const { t } = useTranslation();
  let courseid = props.match.params.cId;
  const initialStateId = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    gender: "",
    dob: "",
    instituteName: "",
    eduQualification: "",
    address: "",
    city: "",
    pincode: "",
    countryId: "",
    stateId: "",
    districtId: "",
    updateBy: props.match.params.updateBy,
    learnerUsername: props.match.params.learnerUsername,
    firstNameError: "",
    lastNameError: "",
    genderError: "",
    dobError: "",
    mobileError: "",
    eduQualificationError: "",
    instituteNameError: "",
    addressError: "",
    cityError: "",
    pincodeError: "",
    countryIdError: "",
    stateIdError: "",
    districtIdError: "",
    facebookId: "",
    twitterId: "",
    linkedinId: "",
    youtubeId: "",
    skypeId: "",
    facebookIdError: "",
    twitterIdError: "",
    linkedinIdError: "",
    youtubeIdError: "",
    skypeIdError: "",
  };

  const initialState = {
    title: "",
    titleError: "",
    titledesc: "",
    titledescError: "",
    publishfrom: "",
    publishfromError: "",
    publishupto: "",
    publishuptoError: "",
  };
  const questionInitialState = {
    question: "",
    mandatory: "",
    questionType: "",

    questionError: "",
    mandatoryError: "",
    questionTypeError: "",
    questionOptionError: "",
  };
  const feedbackInitialState = {
    feedback_title: "",
    feedbacktitleError: "",
    feedbacktitledesc: "",
    feedbacktitledescError: "",
  };
  // variablesend-

  //Uri
  const um_api = URI.SERVER?._USER_API;
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  //Uriend-

  //states-
  const [ratingCount, setRatingCount] = useState(0);
  const [getAvgRating, setAvgRating] = useState([]);
  const [getContentOwner, setContentOwner] = useState(false);
  const [getCourseOwner, setCourseOwner] = useState(false);
  const [courseId, setDecodedCourseId] = useState(null);
  const [isDecodeValid, setIsDecodeValid] = useState("NOTVALID");
  const [getCourseDetails, setCourseDetails] = useState([]);
  const [startDate, setStartDate] = useState();
  const [getRating, setRating] = useState([]);
  const [getServerTime, setServerTime] = useState();
  const [isActiveFolderId, setIsActiveFolderId] = useState();
  const [getColorActiveId, setColorActiveId] = useState();
  // const [getRootFolderColor,setRootFolderColor] = useState(false);
  const [getYouTubeUrl, setYouTubeUrl] = useState();
  const [completionType, setCompletionType] = useState("None");
  const [restriction, setRestriction] = useState(" ");
  const [getStreamingUrl, setStreamingUrl] = useState();
  const [videoEncodeStatus, setVideoEncodeStatus] = useState(null);
  const [getUserDetails, setUserDetails] = useState(initialStateId);
  const [getCourseStructureJson, setCourseStructureJson] = useState([]);
  const [userCount, setUserCount] = useState();
  const [announcementDetails, setAnnouncementDetails] = useState([]);
  const [ratingView, setRatingView] = useState(false);
  const [isActive, setIsActive] = useState({
    folder: 0,
    file: 0,
  });
  const [getAddContentFilesClicked, setAddContentFilesClicked] = useState(false)
  /* Course Structure Create Code Start */
  const [getContentDetails, setContentDetails] = useState([]);
  const [getFolder, setFolder] = useState([]);
  const [getFolderName, setFolderName] = useState();
  const [getLoading, setLoading] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [errorMsg2, setErrorMsg2] = useState();
  const [showRestriction, setShowRestriction] = useState(false);
  const [getParentId, setParentId] = useState([]);
  const [getUploadModalState, setUploadModalState] = useState({
    show: false,
  });
  const [getModalState, setModalState] = useState({
    show: false,
  });
  const [videoModal, setVideoModal] = useState({
    show: false,
  });
  const [getTopicFeedback, setTopicFeedback] = useState({
    show: false,
  });
  const [courseDriveModal, setCourseDriveModal] = useState(false)
  const [itemIdForFeedback, setItemIdForFeedback] = useState();
  const [getUrlModal, setUrlModal] = useState({
    show: false,
  });
  const [items, setItems] = useState([]);
  const [globalState, setGlobalState] = useState([]);
  const [activeAddModule, setActiveAddModule] = useState(false);
  const [contentRatingModalShow, setContentRatingModalShow] = useState(false);
  const [selectedContent, setselectedContent] = useState({})
  const [apiData] = useState([]);
  const [itemsCourse, setItemsCourse] = useState([]);
  const [getabc, setabc] = useState({
    selectedFiles: undefined,
    currentFile: undefined,
    file: "",
  });
  const [checkBox, setCheckBox] = useState(false);
  const [show, setShow] = useState();
  const [getFileContentUpdateModalState, setFileContentUpdateModalState] =
    useState({
      show: false,
    });
  const [getDirNameUpdateModal, setDirNameUpdateModal] = useState({
    show: false,
    dirName: "",
    dirParentId: "",
  });
  const [getShareUrlData, setShareUrlData] = useState();
  const [shareUrlModal, setShareUrlModal] = useState({
    show: false,
  });
  const [getContentName, setContentName] = useState();
  const [getUrl, setUrl] = useState();
  const [getContentType, setContentType] = useState();
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [toggle, setToggle] = useState(false);
  const [getLibrary, setLibrary] = useState(false);

  const [addContent, setAddContent] = useState(false);
  let [getCourseStructureFolderId, setCourseStructureFolderId] = useState();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [getModuleModalState, setModuleModalState] = useState({
    show: false,
  });
  const [getFolderOrContentDetUpdate, setFolderOrContentDetUpdate] = useState({
    show: false,
  });
  const [getPdate, setPdate] = useState();
  const [getAddActivityCompletion, setAddActivityCompletion] = useState({
    show: false,
  });
  const [getAddAccessRestriction, setAddAccessRestriction] = useState({
    show: false,
  });
  const [headerState, setHeaderState] = useState({
    id: UserService.getUserid(),
    img: um_api + "public/getprofilepic/",
  });
  const [announcementData, setAnnouncementData] = useState(initialState);
  const [announcementDiv, setAddAnnouncementDiv] = useState(false);
  const [feedbackData, setfeedbackData] = useState(feedbackInitialState);
  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [questionData, setquestionData] = useState(questionInitialState);
  const [getFeedbackQuestion, setfeedbackQuestion] = useState([]);
  const [getFeedbackResponse, setfeedbackResponse] = useState([]);
  const [showCreateQuestion, setShowCreateQuestion] = useState(false);
  const [questionselectedRows, setQuestionSelectedRows] = React.useState([]);
  const [getNewCriteriaModal, setNewCriteriaModal] = useState({
    show: false,
  });
  const [assementLoader, setAssementLoader] = useState({
    isLoading: false,
  });
  const [getInstructors, setInstructors] = useState({});
  const [feedbackListData, setFeedbackListData] = useState();
  const [showAddFeedback, setShowAddFeedback] = useState(false);
  const [listFeedbackData, setListFeedbackData] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [modelShow, setModelShow] = useState(false);
  const [graphLabel, setGraphLebel] = useState([]);
  const [graphCount, setGraphCount] = useState([]);
  const [checkPieChart, setCheckPieChart] = useState(false);
  const [total, setTotal] = useState();
  const [activeKey, setActiveKey] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [structure, setStructure] = useState();

  const [dragModalOpen, setDragModalOpen] = useState(false);
  const [showFeedbacks, setShowFeedbacks] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  // console.log("dragModalOpen: ", dragModalOpen);
  const [getFeedbackRow, setFeebackRow] = useState();
  const [dragLoading, setDragLoading] = useState(false);

  const [updatedCourseStructureJson, setUpdatedCourseStructureJson] =
    useState();
  const [isContentChange, setIsContentChange] = useState(false);
  const [inactiveCertificateInCourse, setInactiveCertificateInCourse] = useState(false);
  const [getFileContentDetails, setFileContentDetails] = useState({
    contentId: "",
    contentName: "",
    contentDuration: "",
  });

  const initAssignmentToCourseStructure = {
    show: false,
    assignmentList: [],
    assignSelectedRows: [],
  }
  const [getAssignmentList, setAssignmentList] = useState(initAssignmentToCourseStructure)

  const [getviewAssignment, setViewAssignment] = useState(false)
  // const [assignmentList, setAssignmentList] = useState([]);
  // const [assignSelectedRows, setAssignSelectedRows] = React.useState([]);


  //statesend-

  //useeffect-
  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 1100,
    height: 800,
    controls: true,
    sources: [
      {
        src: URI.SERVER?._DMS_URL + `/${getUrl}`,
        type: "video/mp4",
      },
    ],
  };

  const youtubeVideoJs = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 1100,
    height: 800,
    controls: true,
    sources: [
      {
        src: getYouTubeUrl,
      },
    ],
  };
  useEffect(() => {
    UserService.generateToken();
  }, []);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    const courseButton = document.querySelectorAll(".course-button");
    courseButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "course-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "course-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  useEffect(() => {
    service
      .getUserById(UserId)
      .then((res) => {
        setUserDetails(res.data);
        ////console.log('UserDetail-------------'+res.data);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    //console.log("Called isDecodeValid")

    if (isDecodeValid !== "VALID") {
      decodeHandler();
    }
  }, [isDecodeValid, courseId]);

  useEffect(() => {
    if (isDecodeValid === "VALID") {
      fatchCourseData();

      const fatchRatingData = async () => {
        try {
          const res = await service.getRatingByItemIdReviewTypeTenantId(courseId, tenantId, 1);
          setRating(res.data);
          setRatingCount(res.data.length);
          ////console.log(res.data);
        } catch (e) {
          //console.log(e)
        }
      };
      const fatchServerTime = async () => {
        try {
          const res = await instructorService.getServerTime();
          setStartDate(new Date(res.data));
        } catch (e) {
          //console.log(e)
        }
      };

      // fatchCourseData();
      fatchRatingData();
      fatchServerTime();
      fatchUserCount();
      fatchAnnouncementData();
    }
  }, [courseId]);
  // useEffect(() => {
  //   if (isDecodeValid === "VALID") {
  //     const fatchCourseData = async () => {
  //       try {
  //         const res = await instructorService.getCourseById(courseId);
  //         setInactiveCertificateInCourse(res.data.certificate);
  //         setCourseDetails(res.data);
  //         setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
  //         //console.log((res.data.courseStructureJson));
  //       } catch (e) { }
  //     };
  //     const fatchUserCount = async () => {
  //       try {
  //         const res = await service.userCount(courseId, tenantId);
  //         setUserCount(res.data.userCount);
  //       } catch (error) { }
  //     };

  //     const fatchRatingData = async () => {
  //       try {
  //         const res = await service.getRatingByItemIdReviewTypeTenantId(courseId, tenantId, 1);
  //         setRating(res.data);
  //         setRatingCount(res.data.length);
  //       } catch (e) { }
  //     };
  //     const fatchServerTime = async () => {
  //       try {
  //         const res = await instructorService.getServerTime();
  //         setStartDate(new Date(res.data));
  //       } catch (e) { }
  //     };

  //     fatchCourseData();
  //     fatchRatingData();
  //     fatchServerTime();
  //     fatchUserCount();
  //     fatchAnnouncementData();
  //   }
  // }, [courseId]);


  useEffect(() => {
    if (isDecodeValid === "VALID") {
      checkRatingBody();
    }
  }, [ratingCount, courseId]);



  useEffect(() => {
    if (isDecodeValid === "VALID") {
      let rating = [{
        itemId: courseId,
        reviewType: 1,
        tenantId: 1
      }]
      service
        .averageRating(rating)
        .then((res) => {
          setAvgRating(res.data);
        })
        .catch((error) => {
          // console.log("AVG RATING ERROR", error);
        });
    }
  }, [isDecodeValid]);

  useEffect(() => {
    if (isDecodeValid === "VALID") {
      folderStructureTreeCourse();
    }
  }, [getColorActiveId, isActive, courseId]);

  useEffect(() => {
    if (isDecodeValid === "VALID") {
      folderStructureTree();
    }
  }, [isActiveFolderId, isActive, courseId]);
  useEffect(() => {
    instructorService
      .getFolderStructure(UserId)
      .then((res) => {
        setFolder(res.data);
      })
      .catch((err) => {
        swal(`${t("error")}`, `${err} ${t("try_sometimes")}`, "error");
      });
  }, []);
  useEffect(() => {
    console.log("-------", getFolderOrContentDetUpdate)
  }, [getFolderOrContentDetUpdate])
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  useEffect(() => {
    if (isDecodeValid === "VALID") {
      getAllFeedbackQuestionList();
      courseFeedbackResponses();
    }
  }, [courseId]);
  // useEffect(() => { }, [filteredItemsFeedbackQuestion]);
  useEffect(() => {
    if (isDecodeValid === "VALID") {
      getFeedbackList();
      getInstDetials();
      console.log("------ resp", "comming", getInstructors)

    }
  }, [courseId]);
  useEffect(() => {
    if (isDecodeValid === "VALID") {
      getResponseCount();
    }
  }, [courseId]);
  useEffect(() => {
    setUpdatedCourseStructureJson(getCourseStructureJson);
  }, [getCourseStructureJson]);
  useEffect(() => {
    // console.log("------useeffect call")
    courseOwner();
  }, [])
  useEffect(() => {
    console.log("getCourseStructureFolderId", getCourseStructureFolderId, getAddContentFilesClicked, toggle)
  }, [getCourseStructureFolderId, getAddContentFilesClicked, toggle])

  useEffect(() => {
    // assignmentListAPI();
    console.log("getAssignmentList ", getAssignmentList)
  }, [getAssignmentList])


  //useeffectend-

  //functions-
  const decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 32))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  const decodeHandler = () => {
    const secretKey = "cdac@123";

    const encodedCourseId = props.match.params.cId
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const decodedCourseId = CryptoJS.AES.decrypt(
      encodedCourseId,
      secretKey
    ).toString(CryptoJS.enc.Utf8);

    // const encodedTenantId = props.match.params.tid.replace(/-/g, "+").replace(/_/g, "/");
    // const decodedTenantId = CryptoJS.AES.decrypt(
    //     encodedTenantId,
    //     secretKey
    // ).toString(CryptoJS.enc.Utf8);

    const regex = /^[0-9]+$/;

    const isValidCourseId = regex.test(decodedCourseId);
    // const isValidTenantId = regex.test(decodedTenantId)

    if (isValidCourseId) {
      setIsDecodeValid("VALID");
      setDecodedCourseId(decodedCourseId);
    } else {
      setIsDecodeValid("NOTVALID");

      swal({
        title: t("something_went_wrong_try_later"),
        text: t("redirecting_to_course_list"),
        timer: 5000,
      }).then(() => {
        // history.push(`${process.env.PUBLIC_URL + "/ViewCourses"}`);
      });
    }
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-info" onClick={onClick} ref={ref}>
      {t("publish_date")}: {value}
    </button>
  ));

  const checkRatingBody = () => {
    if (ratingCount !== 0) {
      setRatingView(true);
    }
  };

  const fatchCourseData = async () => {
    try {
      const res = await instructorService.getCourseById(courseId);
      setInactiveCertificateInCourse(res.data.certificate);
      setCourseDetails(res.data);
      setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
      //console.log((res.data.courseStructureJson));
    } catch (e) {
      //console.log(e)
    }
  };
  console.log("getCourseDetails: ", getCourseDetails);
  const fatchUserCount = async () => {
    try {
      const res = await service.userCount(courseId, tenantId);
      setUserCount(res.data.userCount);
    } catch (error) {
      //console.log(error)
    }
  };

  const fatchAnnouncementData = async () => {
    try {
      const res = await service.getAllCourseAnnouncementListByAuthor(
        UserId,
        courseId
      );
      setAnnouncementDetails(res.data);
    } catch (error) { }
  };
  const scrollWin = () => {
    document.getElementById("Main").scrollIntoView({ behavior: "smooth" });
    //window.scrollTo(0, 290);
  };

  const fee_validator = (fees) => {
    if (fees === 0) {
      return <p>Free</p>;
    } else {
      return <p>&#8377;{fees}</p>;
    }
  };

  const convertDate = (dateFormat) => {
    let timestamp = Date.parse(dateFormat);
    let date = new Date(timestamp);
    let d = date.toLocaleString("en-IN", { hour12: false, timeZone: "IST" });

    // let d = new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(timestamp);
    return d;
  };

  const abc1 = (dirId, dirName) => {
    setIsActiveFolderId(dirId);
    setParentId(dirId);
    setFolderName(dirName);
    instructorService.contentDetails(dirId, UserId).then((res) => {
      console.log("setContentDetails: ", res.data);
      setContentDetails(res.data);
    });
  };

  const handleOpenRestriction = (e) => {
    setRestriction(e.target.value);
    ////console.log(restriction);
    if (e.target.value == "Yes") {
      setShowRestriction(true);
    } // to show restriction modal portion
    else {
      setShowRestriction(false);
    }
  };

  //folder structure modal
  const toggleCourseDriveModal = () => {

    setCourseDriveModal(prevState => !prevState)
  }
  const handleModal2 = () => {
    setModalState({ show: false });
  };
  const handleModal = () => {
    setModalState({ show: true });
  };
  const videoModalHide = () => {
    setVideoModal({ show: false });
  };

  const videoModalShow = () => {
    setVideoModal({ show: true });
  };

  // feedback Modal show and hide
  const handleTopicFeedback = (id) => {
    setTopicFeedback({ show: true });
    setItemIdForFeedback(id);
    ////console.log(id);
  };
  const TopicFeedbackModalHide = () => {
    setTopicFeedback({ show: false });
  };
  const UrlModalHide = () => {
    setUrlModal({ show: false });
  };
  const alertMsg = () => {
    swal(`${t("select")}`, `${t("node")}`, "warning");
  };

  const dirClick = (dirId, dirName) => {
    var togglers = document.querySelectorAll(".caret");
    togglers.forEach((toggler) => {
      toggler.onclick = function () {
        toggler.parentElement
          .querySelector(".nested")
          .classList.toggle("active");
        toggler.classList.toggle("caret-down");
      };
    });
  };

  const deleteDirectory = (id) => {
    swal({
      title: `${t("swal_title")}`,
      text: `${t("del_folder")}`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: t("yes_delete"),
      closeOnConfirm: false,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        let data = { lastModifiedBy: UserId, dirParentId: id };
        instructorService.deleteDirectory(data).then(async (res) => {
          if (res.data === "deleted successfully") {
            await swal(`${t("swal_delete")}`, `${t("del_msg")}`, "success");
            instructorService.getFolderStructure(UserId).then((res) => {
              setFolder(res.data);
            });
          }
        });
      }
    });
  };

  function folderStructureTree() {

    instructorService.getFolderStructure(UserId).then((res) => {
      let menuItems = res.data.map((item, i) => {
        let menuItem = returnMenuItem(item, i);
        return menuItem;
      });
      setItems(menuItems);
    });
    const returnMenuItem = (item, i) => {
      let menuItem;
      if (item.Child.length === 0) {
        menuItem = (
          <div className="item" key={i}>
            <div>
              <span
                style={
                  isActiveFolderId === item.Id
                    ? {
                      backgroundColor: "#11b67a",
                      display: "block",
                      color: "white",
                      padding: "8px",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginTop: "8px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      cursor: "pointer"
                    }
                    : {
                      padding: "8px",
                      display: "block",
                      border: "1px solid #d3d3d3",
                      color: "rgb(1, 94, 60)",
                      borderRadius: "5px",
                      marginTop: "8px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                    }
                }
                onClick={() => abc1(item.Id, item.Name)}
              >
                <i
                  className="fas fa-folder"
                // style={
                //   isActiveFolderId == item.Id
                //     ? { fontSize: "18px", color: "white" }
                //     : { fontSize: "18px", color: "red" }
                // }
                ></i>
                <span style={{ marginLeft: "10px" }}>
                  {item.Name} &nbsp;&nbsp;
                </span>
                {/* <span style={{ position: 'relative', float: 'right' }}>
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                          <span className="d-inline-block">
                                              <a class="link" href="#"><i class="fas fa-trash" onClick={() => deleteDirectory(item.Id)} style={{ fontSize: "16px" }}></i></a>&nbsp;&nbsp;
                                          </span>
                                      </OverlayTrigger>
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                          <span className="d-inline-block">
                                              <a class="link" href="#"><i class="fas fa-edit" onClick={() => directoryEdit(item.Id, item.Name)} style={{ fontSize: "16px" }}></i></a>
                                          </span>
                                      </OverlayTrigger>
                                  </span> */}
              </span>
            </div>
          </div>
        );
      } else {
        let menuItemChildren = item.Child.map((item, i) => {
          let menuItem = returnMenuItem(item, i);
          return menuItem;
        });
        menuItem = (
          <div key={i} className="item">
            <div className="toggler" id={`toggle-menu-item-${item.Id}`}>
              <div>
                <span
                  style={
                    isActiveFolderId == item.Id
                      ? {
                        backgroundColor: "#11b67b",
                        display: "block",
                        color: "white",
                        padding: "8px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "5px",
                        marginTop: "8px",
                        verticalAlign: "middle",
                        marginRight: "10px",
                        cursor: "pointer"
                      }
                      : {
                        padding: "8px",
                        display: "block",
                        border: "1px solid #d3d3d3",
                        color: "rgb(1, 94, 60)",
                        borderRadius: "5px",
                        marginTop: "8px",
                        verticalAlign: "middle",
                        marginRight: "10px",
                      }
                  }
                  onClick={() => abc1(item.Id, item.Name)}
                >
                  <i
                    className="fas fa-folder"
                  // style={
                  //   isActiveFolderId == item.Id
                  //     ? { fontSize: "18px", color: "white" }
                  //     : { fontSize: "18px", color: "black" }
                  // }
                  ></i>
                  <span
                    onClick={() => abc1(item.Id, item.Name)}
                    style={{ marginLeft: "10px" }}
                  >
                    {item.Name} &nbsp;&nbsp;
                  </span>
                  {/* <span style={{ position: 'relative', float: 'right' }}>
                                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                              <span className="d-inline-block">
                                                  <a class="link" href="#"><i class="fas fa-trash" onClick={() => deleteDirectory(item.Id)} style={{ fontSize: "16px" }}></i></a>&nbsp;&nbsp;
                                              </span>
                                          </OverlayTrigger>
                                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                              <span className="d-inline-block">
                                                  <a class="link" href="#"><i class="fas fa-edit" onClick={() => directoryEdit(item.Id, item.Name)} style={{ fontSize: "16px" }}></i></a>
                                              </span>
                                          </OverlayTrigger>
                                      </span> */}
                </span>
              </div>
            </div>
            <UncontrolledCollapse
              className="children"
              toggler={`#toggle-menu-item-${item.Id}`}
            >
              {menuItemChildren}
            </UncontrolledCollapse>
          </div>
        );
      }
      return menuItem;
    };
  }

  const folderColorChangeHandler = (itemId) => {
    console.log("----------------cliclked second", itemId)
    // setRootFolderColor(prevState => !prevState)
    setColorActiveId(itemId);
  };

  const Breadcrumb = () => {
    return (
      <>
        {globalState.map((obj) => (
          <>
            <a class="btn btn-default">{obj}</a>
          </>
        ))}
      </>
    );
  };
  const handleClickAddModule = () => {
    setActiveAddModule(!activeAddModule);
  };
  ///////////////////// CONTENT REVIEW AND RATING ////////////////////////
  const contentReviewSelected = (content) => {
    setContentRatingModalShow(true);
    setselectedContent(content);
  }
  ///////////////////// CONTENT REVIEW AND RATING ////////////////////////

  function folderStructureTreeCourse() {
    let menuData = [];
    instructorService.getCourseById(courseId).then((res) => {
      if (res.data.courseStructureJson != undefined) {
        //apiData.push([JSON.parse(res.data.courseStructureJson)]);
        menuData = [JSON.parse(res.data.courseStructureJson)];
        ////console.log(res.data.courseStructureJson);
        let menuItems = menuData.map((item, i) => {
          let menuItem = returnMenuItem(item, i);
          return menuItem;
        });
        setItemsCourse(menuItems);
      }
    });

    const returnMenuItem = (item, i) => {
      const getItemStyle = (isActive) => ({
        backgroundColor: isActive ? "rgb(0 165 105)" : undefined,
        display: "block",
        color: isActive ? "white" : undefined,
        padding: "8px",
        border: "1px solid #d3d3d3",
        borderRadius: "5px",
        marginTop: "8px",
        verticalAlign: "middle",
        marginRight: "10px",
        cursor: "pointer"
      });

      const getIconClass = (nodetype, isActive) => {
        const iconMap = {
          pdf: 'fas fa-file-pdf  fa-lg',
          png: 'fas fa-image fa-lg',
          jpg: 'fas fa-image fa-lg',
          zip: 'fas fa-file-archive fa-lg',
          scorm: 'fas fa-file-archive fa-lg',
          html: 'fab fa-html5 fa-lg',
          youtube: 'fab fa-youtube fa-lg',
          mp4: 'fas fa-video fa-lg',
          folder: 'fas fa-folder fa-lg',
          root: 'fa fa-list fa-lg',
          pretestQuiz: 'fa fa-clipboard fa-lg',
          practiceQuiz: 'fa fa-product-hunt',
          finalQuiz: 'fas fa-question-circle',
          streaming: 'fas fa-file-video fa-lg',
          externalLink: 'fa fa-external-link fa-lg',
          assignment: 'fa fa-book fa-lg'
        };
        return `${iconMap[nodetype] || 'fas fa-folder'} ${isActive ? 'text-white' : getColorClass(nodetype)}`;
      };

      const getColorClass = (nodetype) => {
        const colorMap = {
          pdf: 'text-danger',
          png: 'images_color',
          jpg: 'images_color',
          zip: 'zip_color',
          scorm: 'scorm_color',
          html: 'html_color',
          youtube: 'text-danger',
          mp4: 'mp4_color',
          folder: 'folder_color',
          root: 'folder_color',
          pretestQuiz: 'text-success',
          practiceQuiz: 'text-success',
          finalQuiz: 'text-success',
          streaming: 'mp4_color',
          externalLink: 'text-primary',
          assignment: 'text-info'
        };
        return colorMap[nodetype] || 'folder_color';
      };

      const handleClick = (item) => {
        console.log("clicked first ", item.id, getCourseStructureFolderId)

        if (toggle) {
          setToggle(false)
        }
        menuData.forEach((itemTopic) => {
          if (itemTopic.id === item.id) {
            globalState.length = 0;
            globalState.push(itemTopic.label);
            setIsActive({ folder: itemTopic.label, file: item.id });
          }
          itemTopic.nodes.forEach((itemContent) => {
            if (itemContent.id === item.id) {
              globalState.length = 0;
              globalState.push(itemTopic.label, itemContent.label);
              setIsActive({ folder: itemContent.label, file: item.id });
            }
            itemContent.nodes.map((itemtype) => {
              if (itemtype.id === item.id) {
                globalState.length = 0;
                globalState.push(
                  itemTopic.label,
                  itemContent.label,
                  itemtype.label
                );
                setIsActive({
                  folder: itemContent.label,
                  file: item.id,
                });
                // if(itemtype.nodetype == "root"){
                //     handleClickAddModule();
                // }
              }
            });
          });
        });
        folderColorChangeHandler(item.id);
      };



      const renderItemActions = (item) => (
        <span style={{ position: "relative", float: "right" }}>
          {item.nodetype === "root" && (
            <>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("add_module")}</Tooltip>}>
                <a className="hover-fx1" onClick={() => { AddModuleModalShow(item.id); handleClickAddModule(); }}>
                  <i className="fas fa-folder-plus hello" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                </a>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rearrange Course Structure</Tooltip>}>
                <a className="hover-fx1" onClick={() => handleDragModalOpen(item, false)}>
                  <i className="fa fa-arrows" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                </a>
              </OverlayTrigger>
            </>
          )}
          {item.nodetype === "folder" && (
            <>
              {(item?.userId === UserId || getCourseOwner) && (item?.nodes.length !== 0) && (
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rearrange Folder Structure</Tooltip>}>
                  <a className="hover-fx1" onClick={() => handleDragModalOpen(item, true)}>
                    <i className="fa fa-arrows" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                  </a>
                </OverlayTrigger>
              )}
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("add_content_file")}</Tooltip>}>
                <a className="hover-fx1" onClick={() => dmsUIVisible(item.id)}>
                  <i className="fa fa-file" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                </a>
              </OverlayTrigger>
              {(item?.userId === UserId || getCourseOwner) && (
                <>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>}>
                    <a className="hover-fx1" onClick={() => updateFolderOrContent(item.id, item.label, item.publishDate, item.nodetype)}>
                      <i className="fa fa-edit" style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e" }}></i>
                    </a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("remove")}</Tooltip>}>
                    <a className="hover-fx1" onClick={() => RemoveContentOrStructure(item.id)}>
                      <i className="fas fa-trash-alt" style={{ color: getColorActiveId === item.id ? "white" : "rgb(255 89 84)" }}></i>
                    </a>
                  </OverlayTrigger>
                </>
              )}
            </>
          )}
          {item.nodetype !== "folder" && item.nodetype !== "root" && (
            <>
              {(item.nodetype !== "pretestQuiz" && item.nodetype !== "practiceQuiz" && item.nodetype !== "finalQuiz") && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("view_content")}</Tooltip>}>
                <a className="hover-fx1" onClick={() => courseStructurContentView(item.nodetype, item.filePath, item.label)}>
                  <i className="fa fa-eye" style={{ color: getColorActiveId === item.id ? "white" : "#94b8b8" }}></i>
                </a>
              </OverlayTrigger>}
              {(item?.userId === UserId || getCourseOwner) && (
                <>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>}>
                    <a className="hover-fx1" onClick={() => updateFolderOrContent(item.id, item.label, item.publishDate, item.nodetype, item)}>
                      <i className="fa fa-edit" style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e" }}></i>
                    </a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rating and Review</Tooltip>}>
                    <a className="hover-fx1" onClick={() => contentReviewSelected(item)}>
                      <i className="fa fa-star-half-o" style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e" }}></i>
                    </a>
                  </OverlayTrigger>
                  {(item.nodetype !== "pretestQuiz" && item.nodetype !== "practiceQuiz" && item.nodetype !== "finalQuiz") &&
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("remove")}</Tooltip>}>
                      <a className="hover-fx1" onClick={() => RemoveContentOrStructure(item.id)}>
                        <i className="fas fa-trash-alt" style={{ color: getColorActiveId === item.id ? "white" : "rgb(255 89 84)" }}></i>
                      </a>
                    </OverlayTrigger>
                  }
                </>
              )}
            </>
          )}
        </span>
      );

      const renderItemContent = (item) => (
        <span style={{ marginLeft: "10px" }}>
          {item.label} &nbsp;&nbsp;
        </span>
      );

      const renderItem = (item, i) => (
        <div className="item" key={i}>
          <span
            style={getItemStyle(getColorActiveId === item.id)}
            onClick={() => handleClick(item)}
          >
            <i className={getIconClass(item.nodetype, getColorActiveId === item.id)} style={{ fontSize: "18px" }}></i>
            {renderItemContent(item)}
            {renderItemActions(item)}
          </span>
        </div>
      );

      if (item.nodes.length === 0) {
        return renderItem(item, i);
      } else {
        let menuItemChildren = item.nodes.map((childItem, i) => returnMenuItem(childItem, i));
        return (
          <div key={i} className="item">
            <div>
              <div className="toggler" id={`toggle-menu-item-${item.id}`}>
                <span
                  style={getItemStyle(getColorActiveId == item.id)}
                  onClick={() => handleClick(item)}
                >
                  <i className={getIconClass(item.nodetype, getColorActiveId === item.id)} style={{ fontSize: "18px", color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                  {renderItemContent(item)}
                  {renderItemActions(item)}
                </span>
              </div>
            </div>
            <UncontrolledCollapse className="children" toggler={`#toggle-menu-item-${item.id}`}>
              {menuItemChildren}
            </UncontrolledCollapse>
          </div>
        );
      }
    };

  }
  const Tree1 = ({ data }) => (
    <>

      <ul class="tree" style={{ marginLeft: "12px" }}>
        {data &&
          data.map((item) => (
            <li>
              <span class="caret" onClick={() => dirClick(item.Id, item.Name)}>
                <span
                  style={{ textTransform: "capitalize" }}
                  onClick={() => abc1(item.Id, item.Name)}
                >
                  {item.Name}&nbsp;&nbsp;
                </span>
              </span>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">{t("delete")}</Tooltip>}
              >
                <span className="d-inline-block">
                  <a class="link" href="#">
                    <i
                      class="fas fa-trash"
                      onClick={() => deleteDirectory(item.Id)}
                      style={{ fontSize: "16px" }}
                    ></i>
                  </a>
                  &nbsp;&nbsp;
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>}
              >
                <span className="d-inline-block">
                  <a class="link" href="#">
                    <i
                      class="fas fa-edit"
                      onClick={() => directoryEdit(item.Id, item.Name)}
                      style={{ fontSize: "16px" }}
                    ></i>
                  </a>
                </span>
              </OverlayTrigger>
              {item.Child && (
                <ul class="nested">{<Tree1 data={item.Child} />}</ul>
              )}
            </li>
          ))}
      </ul>
    </>
  );
  const Tree = ({ data }) => (
    <ul class="tree">
      {data &&
        data.map((item) => (
          <li style={{ marginTop: "5px" }}>
            <span class="caret" onClick={() => dirClick(item.Id, item.Name)}>
              <span
                style={{ textTransform: "capitalize" }}
                onClick={() => abc1(item.Id, item.Name)}
              >
                {item.Name}&nbsp;&nbsp;
              </span>
            </span>

            {item.Child && <ul class="nested">{<Tree data={item.Child} />}</ul>}
          </li>
        ))}
    </ul>
  );

  /* Edit Dirctory Code Start Here*/

  const FolderNameUpdateModalHide = () => {
    setDirNameUpdateModal({ show: false });
  };
  const directoryEdit = (Id, Name) => {
    setErrorMsg();
    setDirNameUpdateModal({ show: true, dirParentId: Id, dirName: Name });
  };
  const UpdateFolderName = (dirId) => {
    const fname = document.getElementById("folder_name");
    if (fname.value === "") {
      return setErrorMsg(t("enter_folder_name"));
    }
    let dirname = fname.value;
    let data = { dirName: dirname, lastModifiedBy: UserId, dirParentId: dirId };
    instructorService.folderNameUpdate(data).then(async (res) => {
      if (res.status == 200) {
        await swal(t("update"), t("folder_updated"), "success");
        instructorService.getFolderStructure(UserId).then((res) => {
          setFolder(res.data);
        });
        setDirNameUpdateModal({ show: false });
      } else {
        alert("some error");
      }
    });
  };

  /* Edit Dirctory Code End Here*/

  function changeBackgroundOver(e) {
    e.target.style.background =
      "linear-gradient(90deg, #009444 0%, #11B67A 100%)";
  }

  function changeBackgroundOut(e) {
    e.target.style.background =
      "linear-gradient(90deg, #11B67A 0%, #009444 100%)";
  }

  /* share url code here start */
  const shareUrlModalHide = () => {
    setShareUrlModal({ show: false });
  };
  const ShareUrl = (url) => {
    instructorService.contentAccess(url).then((res) => {
      setShareUrlData(URI.SERVER?._DMS_URL + "/" + res.data);
      setShareUrlModal({ show: true });
      let copyText = document.querySelector(".copy-text1");
      copyText.querySelector("button").addEventListener("click", function () {
        let input = copyText.querySelector("input.text");
        input.select();
        document.execCommand("copy");
        copyText.classList.add("active");
        window.getSelection().removeAllRanges();
        setTimeout(function () {
          copyText.classList.remove("active");
        }, 2500);
      });
    });
  };
  /* share url code here start */

  const contentView = (contentType, url, contentName, row) => {
    // console.log("conent type",contentType)
    // alert("contentType")
    instructorService
      .contentAccess(`${URI.SERVER?._DMS_URL}${url}`)
      .then((res) => {
        if (res.data.endsWith(".mp4")) {
          setVideoEncodeStatus("pending");
        } else {
          setVideoEncodeStatus("success");
        }
        if (contentType === "pdf") {
          // This encodes the Actual URL
          const encrypted = CryptoJS.AES.encrypt(
            "/" + URI.SERVER._MEGH_API + "/" + res.data,
            "cdac@123"
          ).toString();
          const safeEncodedString = encrypted
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

          const pdfDomain = URI.SERVER?._DMS;
          const finalSubDomain = pdfDomain + "/" + safeEncodedString;

          // console.log("finalSubDomain", finalSubDomain)

          setUrl(finalSubDomain);
        } else {
          setUrl(res.data);
          if (res.data.includes('/displayview?')) { // streaming url
            setUrl(res.data);
          }
        }
        setContentType(contentType);
        setContentName(contentName);
        setUrlModal({ show: true });
      })
      .catch((err) => {
        swal(`${t("error")}`, `${err} ${t("try_sometimes")}`, "error");
      });
  };
  console.log("getUrl", getUrl);

  const contentDelete = (contentId, folder_id) => {
    swal({
      title: `${t("swal_title")}`,
      text: `${t("u_want_to_delete_content")}`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: t("yes_delete"),
      closeOnConfirm: false,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        instructorService.contentDelete(contentId).then(async (res) => {
          if (res.status == 200) {
            await swal(
              `${t("deleted")}`,
              `${t("your_content_deleted")}`,
              "success"
            );
            instructorService.contentDetails(folder_id, UserId).then((res) => {
              setContentDetails(res.data);
            });
          }
        });
      }
    });
  };

  const columns = [
    {
      name: "Name",
      selector: "contentName",
      sortable: true,
      wrap: true,
      // width: "145px",
    },
    {
      name: "Type",
      selector: "contentType",
      sortable: true,
      // width: "145px",
    },
    {
      name: "Duration",
      selector: "contentDuration",
      sortable: true,
      // width: "145px",
    },
    {
      name: "Preview",
      sortable: true,
      // width: "145px",
      cell: (row) => (
        <a
          class="link"
          href="#"
          onClick={() =>
            contentView(row.contentType, row.shareUrl, row.contentName, row)
          }
        >
          {row.contentType === "zip" ? (
            <i
              class="fas fa-file-archive"
              style={{ fontSize: "25px", color: "#fdbf00" }}
            ></i>
          ) : row.contentType === "pdf" ? (
            <i
              class="fas fa-file-pdf"
              style={{ fontSize: "25px", color: "#b30b00" }}
            ></i>
          ) : row.contentType === "jpg" ||
            row.contentType === "png" ||
            row.contentType === "jpeg" ? (
            <i
              class="fas fa-file-image"
              style={{ fontSize: "25px", color: "#b2b1ff" }}
            ></i>
          ) : row.contentType === "html" ? (
            <i
              class="fab fa-html5"
              style={{ fontSize: "25px", color: "#e54c21" }}
            ></i>
          ) : row.contentType === "ogg" ||
            row.contentType === "webm" ||
            row.contentType === "mp4" ? (
            <i
              class="fas fa-file-video"
              style={{ fontSize: "25px", color: "#8cee02" }}
            ></i>
          ) : row.contentType === "txt" ? (
            <i
              class="fas fa-file-alt"
              style={{ fontSize: "25px", color: "#2766a0" }}
            ></i>
          ) : row.contentType === "doc" || row.contentType === "docx" ? (
            <i
              class="fas fa-file-word"
              style={{ fontSize: "25px", color: "#1e62b4" }}
            ></i>
          ) : row.contentType === "scorm" ? (
            <i
              class="far fa-file-archive"
              style={{ fontSize: "25px", color: "green" }}
            ></i>
          ) : row.contentType === "youtube" ? (
            <i
              class="far fa-youtube"
              style={{ fontSize: "25px", color: "green" }}
            ></i>
          ) : row.contentType === "streaming" ? (
            <i
              class="fas fa-file-video"
              style={{ fontSize: "25px", color: "green" }}
            ></i>
          ) : null}
        </a>
      ),
    },
    {
      name: "Share Url",
      // width: "170px",
      sortable: true,
      cell: (row) => (
        <>
          {row.contentType === "zip" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "pdf" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <CopyToClipboard text={getShareUrlData}>
                  <a
                    class="link"
                    onClick={() => ShareUrl(row.previewUrl)}
                    href="#"
                  >
                    <i class="fas fa-link"></i>
                  </a>
                </CopyToClipboard>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "jpg" ||
            row.contentType === "png" ||
            row.contentType === "jpeg" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "html" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "ogg" ||
            row.contentType === "webm" ||
            row.contentType === "mp4" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "txt" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "doc" || row.contentType === "docx" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : row.contentType === "scorm" ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
              }
            >
              <span className="d-inline-block">
                <a
                  class="link"
                  onClick={() => ShareUrl(row.previewUrl)}
                  href="#"
                >
                  <i class="fas fa-link"></i>
                </a>
              </span>
            </OverlayTrigger>
          ) : null}
        </>
      ),
    },

    // {
    //     name: "Action",
    //     sortable: true,
    //     cell: (row) => <div>
    //         <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
    //             <span className="d-inline-block">
    //                 <a class="link" href="#"><i class="fas fa-trash" onClick={() => contentDelete(row.contentId, getParentId)} style={{ fontSize: "20px", color: "#006dff" }}></i></a>
    //             </span>
    //         </OverlayTrigger>
    //         <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
    //             <span className="d-inline-block">
    //                 <a class="link" href="#"><i class="fas fa-edit" onClick={() => contentEdit(row.contentId, row.contentName, row.contentDuration)} style={{ fontSize: "20px", color: "#006dff", marginLeft: '20px' }}></i> </a>
    //             </span>
    //         </OverlayTrigger>
    //     </div>
    // }
  ];

  /* Table content Filter and Search */
  const filteredItems = getContentDetails?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  /* Course Structure Create Code End */
  const courseStructurContentView = (contentType, fileUrl, label) => {

    setContentType(contentType);
    setContentName(label);

    // console.log("url ======", contentType, fileUrl, label)
    // alert("asdfasdfa")
    if (contentType == "youtube") {
      setUrl(fileUrl)
      setYouTubeUrl(fileUrl);

      setContentName(label);
      ////console.log(label);
      setUrlModal({ show: true });
    } else if (contentType == "externalLink") {
      //console.log("fileUrl " , fileUrl);
      // window.location.href = fileUrl;
      window.open(fileUrl, '_blank');

      setContentName(label);
      //console.log(fileUrl);
    } else if (contentType === "assignment") {
      //view assignment
      setViewAssignment(true)
      setUrl(fileUrl)
    }
    else {
      instructorService
        .contentAccess(URI.SERVER?._DMS_URL + fileUrl)
        .then((res) => {
          if (res.data.endsWith(".mp4")) {
            setVideoEncodeStatus("pending");
          } else {
            setVideoEncodeStatus("success");
          }
          if (contentType === "pdf") {
            // This encodes the Actual URL
            const encrypted = CryptoJS.AES.encrypt(
              "/" + URI.SERVER._MEGH_API + "/" + res.data,
              "cdac@123"
            ).toString();
            const safeEncodedString = encrypted
              .replace(/\+/g, "-")
              .replace(/\//g, "_")
              .replace(/=+$/, "");

            const pdfDomain = URI.SERVER?._DMS;
            const finalSubDomain = pdfDomain + "/" + safeEncodedString;

            // console.log("finalSubDomain", finalSubDomain)

            setUrl(finalSubDomain);
          } else {

            // setUrl(`${URI?.SERVER?._DMS_URL}/${res.data}`);
            console.log("res", `/${res.data}`)

            setUrl(`${res.data}`);
            if (res.data.includes('/displayview?')) { // streaming url
              setUrl(res.data);
            }
          }


          setUrlModal({ show: true });
          //window.open({URI.SERVER?._DMS_URL} + res.data, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width=1000,height=1000");
        })
        .catch((err) => {
          swal("Error!", `${err} Try after sometime.`, "error");
        });
    }
  };

  const ToggleAddContent = () => {
    setAddContent(prevState => !prevState)
  }
  const dmsUIVisible = (id) => {
    console.log("clicked dmsUI", id)
    // setAddContentFilesClicked(prevState => !prevState)
    // setToggle(prevState => !prevState)
    setCourseStructureFolderId(id);
    setToggle(true)

  };
  /* dataTable Check box Code  */
  const toDateTime = (dateFormat) => {
    var date = new Date(dateFormat);
    var str = "";
    var year, month, day, hour, min, sec;
    year = date.getFullYear();
    month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    day = date.getDate();
    day = day < 10 ? "0" + day : day;
    hour = date.getHours();
    hour = hour < 10 ? "0" + hour : hour;
    min = date.getMinutes();
    min = min < 10 ? "0" + min : min;
    sec = date.getSeconds();
    sec = sec < 10 ? "0" + sec : sec;

    str += year + "-" + month + "-" + day;
    str += " " + hour + ":" + min + ":" + sec;
    return str;
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const newArr = selectedRows.map(
    ({
      contentId,
      lastModifiedDate,
      lastUpdatedBy,
      publishStatus,
      shareUrl,
      streamingStatus,
      uploadDate,
      userId,
      ...rest
    }) => {
      ////console.log(rest.previewUrl);
      let originalUrl = rest.previewUrl;
      let newPath = originalUrl.replace(URI.SERVER?._DMS_URL, "");
      rest["filepath"] = shareUrl;
      rest["publishDate"] = toDateTime(startDate);
      rest["cname"] = rest["contentName"];
      rest["topicDuration"] = rest["contentDuration"];
      rest["pcontentId"] = contentId;
      delete rest["contentName"];
      delete rest["contentDuration"];
      delete rest["previewUrl"];
      rest['accessType'] = 'open'
      return rest;
    }
  );
  newArr.map(
    (i) => (
      (i.categoryType = getCourseDetails.categoryName),
      (i.courseId = courseId),
      (i.userId = UserId),
      (i.itemId = getCourseStructureFolderId),
      (i.description = "Basic desc")
    )
  );
  /* dataTable Check box Code End here  */
  const AddContentToCourseStructure = (arrayData, id) => {
    if (id === undefined) {
      swal(`${t("select")}`, `${t("node_select")}`, "warning");
    } else {
      //console.log(arrayData);
      instructorService
        .addContentToCourseStructure(arrayData)
        .then(async (res) => {
          if (res.data === "Content added successfully!!") {
            await swal(`${t("success")}`, `${t("content_added")}`, "success");
            toggleCourseDriveModal()

            // setToggle(false);
            instructorService.getCourseById(courseId).then((res) => {
              //console.log("updated code----" + JSON.parse(res.data.courseStructureJson));
              setInactiveCertificateInCourse(res.data.certificate);
              setCourseStructureJson(JSON.parse(res.data.courseStructureJson));

            });
            folderStructureTreeCourse();
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };
  const addContentInBetween = async (
    newContentArray,
    folderId,
    existingContentId
  ) => {
    await fetchAndSetNonce();
    if (folderId === undefined) {
      swal(`${t("select")}`, `${t("node_select")}`, "warning");
    } else {
      instructorService
        .addContentInBetween(newContentArray, existingContentId)
        .then(async (res) => {
          if (res.data === "Content added successfully!!") {
            closeModal();
            await swal(`${t("success")}`, `${t("content_added")}`, "success");
            toggleCourseDriveModal()
            // setToggle(false);
            instructorService.getCourseById(courseId).then((res) => {
              setInactiveCertificateInCourse(res.data.certificate);
              setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
            });
            folderStructureTreeCourse();
          } else {
            closeModal();
            await swal(`error`, `ERROR`, "error");
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };
  const YoutubeAddContentToCourseStructure = (id) => {
    const youtube_link = document.getElementById("youtube_link");
    const duration = document.getElementById("duration");
    const youtubeLinkData = [
      {
        categoryType: getCourseDetails.categoryName,
        cname: "YouTube",
        contentId: 0,
        contentType: "youtube",
        courseId: courseId,
        description: "Basic desc",
        filepath: youtube_link.value,
        itemId: getCourseStructureFolderId,
        pcontentId: 0,
        publishDate: toDateTime(startDate),
        topicDuration: duration.value,
        userId: UserId,
      },
    ];
    if (id === undefined) {
      swal(`${t("select")}`, `${t("node_select")}`, "warning");
    } else {
      instructorService
        .addContentToCourseStructure(youtubeLinkData)
        .then(async (res) => {
          if (res.data === "Content added successfully!!") {
            await swal(`${t("success")}`, `${t("content_added")}`, "success");
            setModalState({ show: false });
            // setToggle(false);
            instructorService.getCourseById(courseId).then((res) => {
              setInactiveCertificateInCourse(res.data.certificate);
              setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
            });
            folderStructureTreeCourse();
          }
        })
        .catch((err) => { });
    }
  };

  /* Add Module or topic code start here */
  const AddModuleModalShow = (id) => {
    setCourseStructureFolderId(id);
    setModuleModalState({ show: true });
  };
  const AddModuleModalHide = () => {
    setModuleModalState({ show: false });
  };
  const AddModule = async (id) => {
    await fetchAndSetNonce();
    setErrorMsg();
    const module_name = document.getElementById("module_name");

    if (startDate === null) {
      return setErrorMsg2(`* ${t("pls_publish_date")}`);
    }
    if (module_name.value === "") {
      return setErrorMsg(`* ${t("pls_publish_folder")}`);
    }
    if (!module_name.value.match(/^[A-Za-z0-9()!#&,.\-\"\'\s]{2,200}$/)) {
      return setErrorMsg(
        "AlphNumeric with Space ( ) ! # \" ' , . & - and 2-200 are allowed."
      );
    } else {
      let data = {
        dirParentId: id,
        dirName: module_name.value,
        publishDate: toDateTime(startDate),
        lastModifiedBy: UserId,
        courseId: courseId
      };
      instructorService
        .addModuleOrTopic(data)
        .then(async (res) => {
          if (res.status == 200) {
            await swal(`${t("success")}`, `${t("fold_add")}`, "success");
            setModuleModalState({ show: false });
            setCourseStructureJson(res.data);
            folderStructureTreeCourse();
          }
        })
        .catch((err) => { });
    }
  };
  /* Add Module or topic code end here */
  /* Update Module or topic or content details code start here */

  const updateFolderOrContent = (id, name, pDate, nodetype, item) => {
    // console.log("nodetype",nodetype,item)
    if (nodetype === "practiceQuiz" || nodetype === "finalQuiz" || nodetype === "pretestQuiz") {
      // window.location.href = `http://samnayakawadi.hyderabad.cdac.in:3001/assessment/authoring/dashboard/courseId/${courseId}`;
      window.location.href = URI.SERVER?._CREATE_QUIZ_URL + '/assessment/authoring/dashboard/courseId/' + `${courseId}`;
    } else {
      setErrorMsg("");
      setPdate(pDate);
      setFolderOrContentDetUpdate({
        show: true,
        id: id,
        name: name,
        duration: item?.duration,
        nodetype: nodetype,
        item: item
      });
    }
  };
  const UpdateContentOrFolderModalHide = () => {
    setFolderOrContentDetUpdate({ show: false });
  };

  const updateContentOrModule = (id) => {
    const module_name = document.getElementById("module_name");
    const module_duration = document.getElementById("module_duration");
    const publishDate = getPdate;
    if (module_name.value === "") {
      return setErrorMsg(t("pls_publish_folder"));
    }
    if (publishDate === "") {
      return setErrorMsg(t("pls_publish_date"));
    }
    if (!module_name.value.match(/^[A-Za-z0-9()!#&,.\-\"\'\s]{2,200}$/)) {
      return setErrorMsg(
        "AlphNumeric with Space ( ) ! # \" ' , . & - and 2-200 are allowed."
      );
    } else {
      if (Number.isInteger(parseInt(id))) {
        let data = {
          contentId: id,
          courseId: courseId,
          cname: module_name.value,
          topic_duration: module_duration.value,
          description: "For frontend development",
          publishDate: toDateTime(publishDate),
          userId: UserId,
        };
        instructorService.updateContentDetails(data).then(async (res) => {
          if (res.status == 200 && res.data === "ERROR_401") {
            setFolderOrContentDetUpdate({ show: false });
            await swal("Warning", "You're not allowed to Perform this action!", "warning");
          }
          else if (res.status == 200) {
            await swal(t("success_swal"), t("content_updated"), "success");
            setFolderOrContentDetUpdate({ show: false });
            setCourseStructureJson(res.data);
            folderStructureTreeCourse();
          }
        });
      } else {
        let data = {
          dirParentId: id,
          dirName: module_name.value,
          lastModifiedBy: UserId,
          publishDate: toDateTime(publishDate),
          courseId: courseId
        };
        instructorService.updateFolderDetails(data).then(async (res) => {
          if (res.status == 200 && res.data === "ERROR_401") {
            setFolderOrContentDetUpdate({ show: false });
            swal("Warning", "You're not allowed to Perform this action!", "warning");
            folderStructureTreeCourse();
          }
          else if (res.status == 200) {
            await swal(t("success_swal"), t("folder_updated"), "success");
            setFolderOrContentDetUpdate({ show: false });
            setCourseStructureJson(res.data);
            folderStructureTreeCourse();
          }
        });
      }
    }
  };

  /* Update Module or topic or content details code end here */
  /* delete course content or remove structure */
  const RemoveContentOrStructure = (contentId) => {
    if (Number.isInteger(parseInt(contentId))) {
      swal({
        title: `${t("r_u_sure")}`,
        text: `${t("u_want_to_delete_content")}`,
        icon: "warning",
        buttons: [t("no_cancel"), t("yes_delete")],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          instructorService
            .deleteCourseContent({
              contentId: contentId,
              courseId: courseId,
              userId: UserId,
            })
            .then(async (res) => {
              if (res.data === "ERROR_401") {
                swal("Warning", "You're not allowed to Perform this action!", "warning");
              }
              else if (res.data === "Content deleted successfully !!") {
                await swal(
                  `${t("delete")}`,
                  `${t("content_deleted")}`,
                  "success"
                );
                instructorService.getCourseById(courseId).then((res) => {
                  setInactiveCertificateInCourse(res.data.certificate);
                  setCourseStructureJson(
                    JSON.parse(res.data.courseStructureJson)
                  );
                });
                folderStructureTreeCourse();
              }
            })
            .catch((err) => { });
        }
      });
    } else {
      swal({
        title: `${t("r_u_sure")}`,
        text: `${t("u_want_to_delete_folder")}`,
        icon: "warning",
        buttons: [t("no_cancel"), t("yes_delete")],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          instructorService
            .deleteCourseStructureChild({
              dirParentId: contentId,
              lastModifiedBy: UserId,
              courseId: courseId
            })
            .then(async (res) => {

              if (res.data === "ERROR_401") {
                swal("Warning", "You're not allowed to Perform this action!", "warning");
              }
              else if (res.data === "deleted successfully") {
                await swal(
                  `${t("delete")}`,
                  `${t("course_structure_folder_deleted")}`,
                  "success"
                );
                instructorService.getCourseById(courseId).then((res) => {
                  setInactiveCertificateInCourse(res.data.certificate);
                  setCourseStructureJson(
                    JSON.parse(res.data.courseStructureJson)
                  );
                });
                folderStructureTreeCourse();
              }
            })
            .catch((err) => { });
        }
      });
    }
  };
  /* Course Publish Function */
  const publishCourseButton = (cId) => {
    instructorService
      .coursePublishAdminRequest(cId)
      .then(async (res) => {
        if (res.data === "Course Published Successfully!!") {
          await swal(`${t("success")}`, `${t("request_send")}`, "success");
          instructorService.getCourseById(courseId).then((res) => {
            setInactiveCertificateInCourse(res.data.certificate);
            setCourseDetails(res.data);
            setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
          });
        } else {
        }
      })
      .catch((err) => { });
  };
  /* course unpublish funcation */
  const unPublishCourseButton = (cId) => {
    instructorService
      .courseUnPublish(cId)
      .then(async (res) => {
        if (res.data === "Course UnPublished Successfully!!") {
          await swal(
            `${t("success")}`,
            `${t("course_unpublished")}`,
            "success"
          );
          instructorService.getCourseById(courseId).then((res) => {
            setInactiveCertificateInCourse(res.data.certificate);
            setCourseDetails(res.data);
            setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
          });
        } else {
        }
      })
      .catch((err) => { });
  };

  /* course Disable funcation */
  const disableCourseButton = (cId) => {
    instructorService
      .CourseDisable(cId)
      .then(async (res) => {
        if (res.data === "Course Disabled Successfully!!") {
          await swal(`${t("success")}`, `${t("course_disabled")}`, "success");
          instructorService.getCourseById(courseId).then((res) => {
            setInactiveCertificateInCourse(res.data.certificate);
            setCourseDetails(res.data);
            setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
          });
        } else {
        }
      })
      .catch((err) => { });
  };
  /* this code is for  announcement creation and view */
  const deleteAnnouncement = (id) => {
    swal({
      title: `${t("swal_title")}`,
      text: `${t("swal_text")}`,
      icon: "warning",
      buttons: [t("no_cancel"), t("yes_delete")],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        service
          .deleteAnnouncement(id)
          .then(async (res) => {
            if (res.status === 200) {
              await swal(
                `${t("delete")}`,
                `${t("course_announcment_msg")}`,
                "success"
              );
              fatchAnnouncementData();
              reset();
              setAddAnnouncementDiv(false);
            }
          })
          .catch((err) => {
            const { status } = err.response;
            console.log("status", status)
            if (status === 401) {
              swal(
                'Warning',
                "Unauthorized Access!",
                "warning"
              );
            } else {
              swal(
                'error',
                "Internal server error!",
                "error"
              );
            }
          });
      }
    });
  };
  const dateConvertToTformate = (value) => {
    var date = new Date(value);
    var str = "";
    var year, month, day, hour, min, sec;
    year = date.getFullYear();
    month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    day = date.getDate();
    day = day < 10 ? "0" + day : day;
    hour = date.getHours();
    hour = hour < 10 ? "0" + hour : hour;
    min = date.getMinutes();
    min = min < 10 ? "0" + min : min;
    sec = date.getSeconds();
    sec = sec < 10 ? "0" + sec : sec;

    str += year + "-" + month + "-" + day;
    str += "T" + hour + ":" + min + ":" + sec;
    return str;
  };
  const editAnnouncement = (id, title, titledesc, publishFrom, publishUpto) => {
    setAddAnnouncementDiv(true);
    let data = {
      id: id,
      title: title,
      titledesc: titledesc,
      publishfrom: dateConvertToTformate(publishFrom),
      publishupto: dateConvertToTformate(publishUpto),
    };
    setAnnouncementData(data);
  };
  const columnsAnnouncement = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.body,
      sortable: true,
      wrap: true,
    },
    {
      name: "Publish From",
      selector: (row) => convertDate(row.publihFrom),
      sortable: true,
      wrap: true,
    },
    {
      name: "Publish Upto",
      selector: (row) => convertDate(row.publishUpto),
      sortable: true,
      wrap: true,
    },
    {
      name: "Author",
      selector: (row) => getInstructors[row.createdBy]?.[0]?.firstName || "--",
      sortable: true,
      wrap: true,
    },

    {
      name: "Action",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <button
            className="btn btn-info m-3"
            onClick={() => {
              setViewAnnouncement()
              editAnnouncement(
                row.id,
                row.title,
                row.body,
                row.publihFrom,
                row.publishUpto
              )
            }
            }
          >
            <i class="fas fa-edit"></i>{" "}
          </button>
          <button
            className="btn btn-danger"
            onClick={() => deleteAnnouncement(row.id)}
          >
            <i class="fas fa-trash"></i>{" "}
          </button>
        </div>
      ),
    },
  ];
  const filteredItemsAnnouncement = announcementDetails.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const formValidate = () => {
    let titleError = "";
    let titledescError = "";
    let publishfromError = "";
    let publishuptoError = "";

    if (!announcementData.title) {
      titleError = t("title_is_required_field");
      // } else if (announcementData.title.match(/^(?=.*[a-zA-Z ])(?=.*[0-9 ])[A-Za-z0-9 ]+$/)) {
      //     titleError = '';
      // } else if (announcementData.title.match(/\w+/g).length > 20) {
      //     titleError = t('title_greater_then_twenty');
      // } else {
    } else if (
      announcementData.title.match(/^[A-Za-z0-9(),.\-\"\'\s]{2,200}$/)
    ) {
      titleError = "";
    } else if (announcementData.title.length > 200) {
      titleError = t("title_max_200");
    } else {
      titleError = "AlphaNumeric with Space () , . - ' \" are Allowed";
    }

    if (!announcementData.titledesc) {
      titledescError = t("title_description_required_field");
      // } else if (announcementData.titledesc.match(/^(?=.*[a-zA-Z.& ])(?=.*[0-9 ])[A-Za-z0-9.& ]+$/)) {
      //     titledescError = '';
      // } else if (announcementData.titledesc.match(/\w+/g).length > 50) {
      //     titledescError = t('title_description_word_greater_than_fifty');
      // } else {
    } else if (
      announcementData.titledesc.match(/^[A-Za-z0-9()!#&,.\-\"\'\s]{2,500}$/)
    ) {
      titledescError = "";
    } else if (announcementData.titledesc.length > 500) {
      titledescError = t("max_500_character");
    } else {
      titledescError =
        "AlphaNumeric with Space ( ) , . & - \" ' ! # and 2-500 are allowed.";
    }

    if (!announcementData.publishfrom) {
      publishfromError = t("this_is_required_field");
    }
    if (!announcementData.publishupto) {
      publishuptoError = t("this_is_required_field");
    }
    if (titleError || titledescError || publishfromError || publishuptoError) {
      setAnnouncementData({
        ...announcementData,
        titleError,
        titledescError,
        publishfromError,
        publishuptoError,
      });
      return false;
    }
    return true;
  };
  const reset = () => {
    setAnnouncementData({
      title: "",
      titledesc: "",
      publishfrom: "",
      publishupto: "",
    });
  };
  async function handleSubmit(e) {
    e.preventDefault();
    const isValidate = formValidate();
    if (announcementData.id) {
      if (isValidate) {
        let data = {
          title: announcementData.title,
          body: announcementData.titledesc,
          publihFrom: announcementData.publishfrom,
          publishUpto: announcementData.publishupto,
          courseId: courseId,
          createdAt: new Date().toISOString(),
          createdBy: UserId,
          readStatus: "string",
          type: 2,
          id: announcementData.id,
        };

        service
          .updateAnnouncement(announcementData.id, data)
          .then(async (res) => {
            if (res.status === 200) {
              await swal(
                `${t("updated")}`,
                `${t("announcement_update_msg")}`,
                "success"
              );
              fatchAnnouncementData();
              setAddAnnouncementDiv(false);
            } else {
            }
          })
          .catch((err) => {
            const { status } = err.response;
            // console.log(" ssssssss ",status)
            if (status === 401) {
              swal(
                'Warning',
                "Unauthorized Access!",
                "warning"
              );

            } else {
              swal(
                'error',
                "Internal server error!",
                "error"
              )
            }
          });
      }
    } else {
      if (isValidate) {
        let data = {
          title: announcementData.title,
          body: announcementData.titledesc,
          publihFrom: announcementData.publishfrom,
          publishUpto: announcementData.publishupto,
          courseId: courseId,
          createdAt: new Date().toISOString(),
          createdBy: UserId,
          readStatus: "string",
          type: 2,
        };
        //
        await fetchAndSetNonce();
        service
          .createAnnouncement(data)
          .then(async (res) => {
            if (res.status === 200) {
              await swal(
                `${t("created")}`,
                `${t("announcement_create_msg")}`,
                "success"
              );
              fatchAnnouncementData();
              setAddAnnouncementDiv(false);
            } else {
            }
          })
          .catch((err) => { });
      }
    }
  }
  /* this code end announcement creation and view */
  /* this code is for  feedback handle submit */

  const isValidText = (text, pattern) => pattern.test(text);

  const validateField = (field, maxLength, minLength, pattern, errorMessages) => {
    if (!field) {
      return errorMessages.required;
    } else if (field.length > maxLength) {
      return errorMessages.lengthExceed;
    } else if (field.length < minLength) {
      return errorMessages.lengthGreaterThan;
    } else if (!isValidText(field, pattern)) {
      return errorMessages.invalidFormat;
    }
    return "";
  };

  const feedbackformVlaidate = () => {
    const feedbackTitlePattern = /^[A-Za-z0-9&.,()\-\\s ]+$/;
    const feedbackDescPattern = /^[A-Za-z0-9()&.,\"\'\\s ]+$/;

    const feedbackTitleErrorMessages = {
      required: t("title_is_required_field"),
      lengthExceed: t("length_exceed"),
      lengthGreaterThan: t("length_greater_than_3"),
      invalidFormat: "AlphaNumeric with ( ) & , . - and Space are Allowed"
    };

    const feedbackDescErrorMessages = {
      required: t("title_description_required_field"),
      lengthExceed: t("length_exceed"),
      lengthGreaterThan: t("length_greater_than_3"),
      invalidFormat: "AlphaNumeric with ( ) & . , - \" ' and Space are Allowed"
    };

    const feedbacktitleError = validateField(
      feedbackData.feedback_title,
      200,
      2,
      feedbackTitlePattern,
      feedbackTitleErrorMessages
    );

    const feedbacktitledescError = validateField(
      feedbackData.feedbacktitledesc,
      500,
      2,
      feedbackDescPattern,
      feedbackDescErrorMessages
    );

    if (feedbacktitleError || feedbacktitledescError) {
      setfeedbackData({
        ...feedbackData,
        feedbacktitleError,
        feedbacktitledescError,
      });
      return false;
    }
    return true;
  };

  const feedbackReset = () => {
    setfeedbackData({
      feedback_title: "",
      feedbacktitledesc: "",
    });
  };
  const feedbackhandleSubmit = async (e) => {
    e.preventDefault();
    const isValidate1 = feedbackformVlaidate();
    if (isValidate1) {
      let data = {
        description: feedbackData.feedbacktitledesc,
        feedbackId: 0,
        feedbackTitle: feedbackData.feedback_title,
        id: courseId,
        typeMasterId: 1,
        updatedBy: UserId,
      };
      await fetchAndSetNonce()
      service
        .feedbackMasterPost(data)
        .then(async (res) => {
          if (res.status === 201) {
            await swal(
              `${t("success")}`,
              `${t("feedback_add_msg")}`,
              "success"
            );
            feedbackReset();
            setShowAddFeedback(false);
            getFeedbackList();
            let data = {
              feedbackId: res.data.feedbackId,
              questionId: questionIds,
            };
            service.addQuestionsMapWithIds(data).then(async (res) => {
              if (res.status === 201) {
                await swal(
                  `${t("success")}`,
                  `${t("feedback_que_add_msg")}`,
                  "success"
                );
                getAllFeedbackQuestionList();
              }
            });
          } else {
          }
        })
        .catch((err) => { });
    }
  };
  /* this code is for  feedback handle submit */
  /* this code is for  feedback question handle submit */
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };
  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };
  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  const feedbackQuestionReset = () => {
    setquestionData({
      question: "",
      mandatory: "",
      questionType: "",
    });
  };

  const isValidOptionText = (text) => {
    return (
      /^[a-zA-Z0-9&,.\"\'\s ]+$/.test(text) &&
      !text.includes(">") &&
      !text.includes("<") &&
      !text.includes("%")
    );
  };

  const validateOptions = () => {
    if (serviceList.length < 1 || serviceList.length > 4) {
      return "";
    }
    for (let i = 0; i < serviceList.length; i++) {
      if (!serviceList[i].service) {
        return t("option_required_field");
      }
      if (!isValidOptionText(serviceList[i].service)) {
        return t("must_have_atleast_one_alphabet_not_include_special_charater");
      }
    }
    return "";
  };

  const questionformVlaidate = () => {
    let questionError = "";
    let mandatoryError = "";
    let questionTypeError = "";
    let questionOptionError = "";

    if (!questionData.question) {
      questionError = t("question_is_required_field");
    } else if (questionData.question.length > 200) {
      questionError = t("text_max_50_error");
    } else if (questionData.question.length < 2) {
      questionError = t("text_min_3_char");
    } else if (!/^[A-Za-z0-9&.,? ]+$/.test(questionData.question)) {
      questionError = "AlphaNumeric with & . , ? and Space are Allowed";
    }

    if (!questionData.mandatory) {
      mandatoryError = t("question_mandatory_is_required_field");
    }

    if (!questionData.questionType || questionData.questionType === "Select Option") {
      questionTypeError = t("quest_Type_required");
    }

    if (questionData.questionType === "MC" || questionData.questionType === "SC") {
      questionOptionError = validateOptions();
    }

    if (questionError || mandatoryError || questionTypeError || questionOptionError) {
      setquestionData({
        ...questionData,
        questionError,
        mandatoryError,
        questionTypeError,
        questionOptionError,
      });
      return false;
    }
    return true;
  };


  const questionhandleSubmit = async (e) => {
    e.preventDefault();

    const qValidate = questionformVlaidate();
    if (!qValidate) return;

    const getOptions = (questionType) =>
      questionType == "SC" || questionType == "MC"
        ? serviceList.map((d) => d.service)
        : ["NA"];

    const data = {
      courseId: 0,
      mandatory: questionData.mandatory,
      options: getOptions(questionData.questionType),
      question: questionData.question,
      questionId: questionData.questionId || 0,
      questionType: questionData.questionType,
      tenantId: 0,
      updatedBy: UserId,
      typeId: 1,
    };

    const handleResponse = async (res, successMessage) => {
      if (res.status === 200 || res.status === 201) {
        await swal(`${t("success")}`, successMessage, "success");
        setShowCreateQuestion(false);
        getAllFeedbackQuestionList();
        feedbackQuestionReset();
        setServiceList([{ service: "" }]);
      } else {
        swal(`${t("error")}`, `${t("something_wrong_try_again")}`, "error");
      }
    };

    try {
      if (questionData.questionId) {
        const res = await service.feedbackQuestionUpdateForCourse(data);
        handleResponse(res, `${t("question_update")}`);
      } else {
        await fetchAndSetNonce()
        const res = await service.feedbackQuestionCreationForCourse(data);
        handleResponse(res, `${t("question_created_successfully")}`);
      }
    } catch (err) {
      swal(`${t("error")}`, `${t("something_wrong_try_again")}`, "error");
    }
  };


  const getAllFeedbackQuestionList = async () => {
    let result = await service.getAllQuestionByTypeAndUpdatedBy(1, UserId);
    setfeedbackQuestion(result.data);
  };

  const courseFeedbackResponses = async () => {
    let result = await service.courseFeedbackResponse(1, courseId);
    setfeedbackResponse(result.data);
  };
  const updateQuestion = (
    questionId,
    question,
    questionType,
    mandatory,
    optionsMasters
  ) => {
    setShowCreateQuestion(true);
    let array = [];
    let data = {
      questionId: questionId,
      question: question,
      mandatory: mandatory,
      questionType: questionType,

      questionError: "",
      mandatoryError: "",
      questionTypeError: "",
      questionOptionError: "",
    };
    // if (mandatory === "true") {
    //     //console.log("mandatory true")
    //     document.getElementById('flexRadioDefault1').checked = true;
    //     document.getElementById('flexRadioDefault2').checked = false;
    // }
    // if (mandatory === "false") {
    //     //console.log("mandatory false")
    //     document.getElementById('flexRadioDefault2').checked = true;
    //     document.getElementById('flexRadioDefault1').checked = false;
    // }

    if (questionType === "TF") {
      // document.getElementById('option').value = "TF";
      setquestionData(data);
      setServiceList([{ service: "" }]);
    }
    if (questionType === "TA") {
      // document.getElementById('option').value = "TA";
      setquestionData(data);
      setServiceList([{ service: "" }]);
    }
    if (questionType === "SC") {
      // document.getElementById('option').value = "SC";
      setquestionData(data);
      optionsMasters.map((d) => array.push({ service: d.optionText }));
      setServiceList(array);
    }
    if (questionType === "MC") {
      // document.getElementById('option').value = "MC";
      setquestionData(data);
      optionsMasters.map((d) => array.push({ service: d.optionText }));
      setServiceList(array);
    }

    // setquestionData(data);
    // optionsMasters.map((d) =>
    //     array.push({ service: d.optionText })
    // )
    // setServiceList(array);
  };
  const deleteQuestion = (id) => {
    swal({
      title: `${t("swal_title")}`,
      text: `${t("swal_text")}`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: t("yes_delete"),
      closeOnConfirm: false,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        service
          .deleteFeedbackQuestion(id)
          .then(async (res) => {
            if (res.status === 200) {
              await swal(
                `${t("delete")}`,
                `${t("your_question_delete")}`,
                "success"
              );
              getAllFeedbackQuestionList();
              feedbackQuestionReset();
            }
          })
          .catch((err) => {
            swal(
              `${t("error")}`,
              `${t("something_went_wrong_try_later")}`,
              "error"
            );
          });
      }
    });
  };

  const onClickCreateQuestion = () => {
    setServiceList([{ service: "" }]);
    feedbackQuestionReset();
    setShowCreateQuestion(true);
  };
  const onClickCloseButton = () => {
    setServiceList([{ service: "" }]);
    feedbackQuestionReset();
    setShowCreateQuestion(false);
  };

  const getQuestionTypeLabel = (questionType) => {
    switch (questionType) {
      case "SC":
        return "Single Choice";
      case "MC":
        return "Multiple Choice";
      case "TF":
        return "True or False";
      case "TA":
        return "Descriptive";
      default:
        return "";
    }
  };

  const renderOptionsForFeedback = (row) => {
    if (row.questionType === "SC" || row.questionType === "MC") {
      return row.optionsMasters.map((d, i) => (
        <ol key={i}>
          <li>{i + 1}. {d.optionText}</li>
        </ol>
      ));
    }
    return "";
  };

  const renderActionButtons = (row) => {
    if (UserId === row.updatedBy) {
      return (
        <>
          <button
            className="btn btn-info"
            onClick={() =>
              updateQuestion(
                row.questionId,
                row.question,
                row.questionType,
                row.mandatory,
                row.optionsMasters
              )
            }
          >
            <i className="fas fa-edit"></i>
          </button>
          <button
            className="btn btn-danger m-3"
            onClick={() => deleteQuestion(row.questionId)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      );
    }
    return "";
  };

  const coloumnfeedbackQuestion = [
    {
      name: "Question",
      selector: (row) => row.question,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "Question Type",
      selector: (row) => getQuestionTypeLabel(row.questionType),
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "Question Mandatory",
      selector: (row) => row.mandatory,
      sortable: true,
      wrap: true,
      width: "270px",
    },
    {
      name: "Options",
      selector: renderOptionsForFeedback,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "Action",
      sortable: true,
      width: "200px",
      wrap: true,
      cell: renderActionButtons,
    },
  ];


  const coloumnfeedbackQuestion1 = [
    {
      name: "Question",
      selector: (row) => row.question,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "Question Type",
      selector: (row) =>
        row.questionType == "SC"
          ? "Single Choice"
          : row.questionType == "MC"
            ? "Multiple Choice"
            : row.questionType == "TF"
              ? "True or False"
              : row.questionType == "TA"
                ? "Descriptive"
                : "",
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: `Question Mandatory`,
      selector: (row) => row.mandatory,
      sortable: true,
      wrap: true,
      width: "270px",
    },
    {
      name: "Options",
      selector: (row) =>
        row.questionType == "SC" || row.questionType == "MC"
          ? row.optionsMasters.map((d, i) => (
            <ol>
              <li>
                {i + 1}.{d.optionText}
              </li>
            </ol>
          ))
          : "",
      sortable: true,
      wrap: true,
      width: "200px",
    },
  ];

  const filteredItemsFeedbackQuestion = getFeedbackQuestion.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const questionRowSelected = React.useCallback((state) => {
    setQuestionSelectedRows(state.selectedRows);
  }, []);

  let questionIds = questionselectedRows.map((d) => d.questionId);

  const feedbackResponseCheckHandler = (data) => {
    let array = data;
    let result = array.split(",").map((e) => parseInt(e));
    return result;
  };
  /* this code is for  feedback question handle submit */
  ////console.log(getUserDetails.facebookId);
  const scrollToView = () => {
    const ref = document.getElementById("Tab");
    ref.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
  };
  // Activity Completion

  function AddNewCriteriaModalShow() {
    setNewCriteriaModal({ show: true });
  }
  function AddNewCriteriaModalHide() {
    setNewCriteriaModal({ show: false });
  }

  const assementLoaderFunction = () => {
    setAssementLoader({ isLoading: true });
  };
  ///////////////////////  FEEDBACK LIST START  ///////////////////////////////////////
  const getInstDetials = () => {
    // console.log("------ resp", "comming", getInstructors)

    // const secretKey = "cdac@123"
    // const encodedTenantId = props.match.params.tid.replace(/-/g, "+").replace(/_/g, "/");
    // const decodedTenantId = CryptoJS.AES.decrypt(
    //   encodedTenantId,
    //   secretKey
    // ).toString(CryptoJS.enc.Utf8);
    service.getInstructorDetails(courseId, 1).then((res) => {
      // console.log("------ resp", res)
      if (res.status === 200) {
        let instDetails = res.data.reduce((instDetailsObj, obj) => {
          const { learnerUsername } = obj;
          if (!instDetailsObj[learnerUsername]) {
            instDetailsObj[learnerUsername] = []; // Initialize as array
          }
          instDetailsObj[learnerUsername].push(obj); // Push object to array
          return instDetailsObj; // Return accumulator
        }, {});
        setInstructors(instDetails)
        // console.log(" ++++ resp", instDetails);
      }
    }).catch((err) => {
      console.error("Error:", err);
    });
  }
  const getFeedbackList = () => {
    service
      .getFeedbackListByCourseId(courseId)
      .then((resp) => {
        setFeedbackListData(resp.data);
      })
      .catch((err) => { });
  };
  const closeCreateFeedback = () => {
    setfeedbackData({
      ...feedbackData,
      feedback_title: "",
      feedbacktitledesc: "",
      feedbacktitledescError: "",
      feedbacktitleError: "",
    });

    setShowAddFeedback(false);
  };
  const setActiveInactive = (id) => {
    service.setActiveInactive(id).then((resp) => {
      if (resp.data === "active") {
        swal(t("success"), "", "success");
        getFeedbackList();
      } else if (resp.data === "inactive") {
        swal(t("success"), "", "success");
        getFeedbackList();
      }
    }).catch((err) => {
      if (err.response) {
        const { status } = err.response;
        if (status === 409) {
          swal("Error", "You are try to Enable and disable Others Feedback", "error");

        } else {
          swal("Error", "Internal Server Error!", "error");

        }
      }
    })
  };

  const FeedbackList = [
    // {
    //   name: "S No.",
    //   selector: (row, index) => index + 1,
    // },
    {
      name: "Title",
      selector: (row) => row.feedbackTitle,
    },
    // {
    //   name: "Description",
    //   selector: (row) => row.description,
    // },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Author",
      selector: (row) => {
        const instructor = getInstructors[row.updatedBy];
        const firstName = instructor?.[0]?.firstName;
        return firstName || "NA";
      }
    },
    {
      name: "View Question",
      selector: (row) => (
        <>
          <Button
            onClick={() => {
              setViewFeedbackData(row)
              toggleFeedbackView()
              // alert("hello", row)
            }}
            variant='info'
            className=" pull-left m-2 w-10"
          >
            View
            {/* <i class="fas fa-eye"></i> */}
          </Button>

        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {row.status === "active" ? (
            <>
              <Button
                onClick={() => {
                  setActiveInactive(row.feedbackId);
                }}
                variant='danger'
                className=" pull-left m-2 w-10"
              >
                {t("deactivate_feedback")}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setActiveInactive(row.feedbackId);
                }}
                variant='success'
                className="pull-left m-2 w-10"
              >
                {t("activate_feedback")}
              </Button>
            </>
          )}
        </>
      ),
    },
  ];
  ///////////////////////  FEEDBACK LIST End  ///////////////////////////////////////
  /*************** FEEDBACK CHART ***********************/
  // const [option, setOption] = useState([]);
  const getResponseCount = () => {
    service
      .getResponseCount(1, courseId)
      .then((resp) => {
        setFeedbackList(resp.data);
        // setListFeedbackData(resp.data.responseCount);
      })
      .catch((err) => { });
  };
  const onClickAccodian = (index) => {
    setListFeedbackData([]);
    setListFeedbackData(feedbackList[index].responseCount);
  };
  const getTotalResponse = (row) => {
    if (row.questionType === "TF") {
      return row.optionCount
        ? row.optionCount[0].countTrue + row.optionCount[0].countFalse
        : null;
    }
    return row.optionCount
      ? row.optionCount.map((e) => e.count).reduce((a, b) => a + b, 0)
      : null;
  };

  const renderOptions = (row) => {
    if (row.questionType === "TF") {
      return row.optionCount
        ? row.optionCount.map((e, idx) => (
          <div key={idx}>
            <p>True : {e.countTrue}</p>
            <p>False : {e.countFalse}</p>
          </div>
        ))
        : null;
    }
    return row.optionCount
      ? row.optionCount.map((e, idx) => (
        <p key={idx}>
          {e.option_text} : {e.count}
        </p>
      ))
      : null;
  };

  const renderMoreDetailsButton = (row) => {
    if (row.questionType !== "TA") {
      return (
        <Button
          onClick={() => {
            onClickMoreDetails(row.question_id, row.questionType);
          }}
          variant="info"
        >
          {t("more_details")}
        </Button>
      );
    }
    return null;
  };

  const listFeedbackColumn = [
    {
      name: "S No.",
      selector: (row, index) => (
        <>{row.questionType !== "TA" ? index + 1 : null}</>
      ),
    },
    {
      name: "Question",
      selector: (row) => (
        <>{row.questionType !== "TA" ? row.question : null}</>
      ),
    },
    {
      name: "Total Response",
      selector: (row) => (
        <>{row.questionType !== "TA" ? getTotalResponse(row) : null}</>
      ),
    },
    {
      name: "Options",
      selector: (row) => (
        <>{row.questionType !== "TA" ? renderOptions(row) : null}</>
      ),
    },
    {
      name: "Visually",
      cell: (row) => <>{renderMoreDetailsButton(row)}</>,
    },
  ];

  const onClickMoreDetails = (questionid) => {
    const graphCounts = [];
    const graphLables = [];
    var total = 0;

    listFeedbackData.every((singleQuestion) => {
      if (
        singleQuestion.question_id === questionid &&
        singleQuestion.questionType === "TF"
      ) {
        setCheckPieChart(true);

        graphLables.push("True");
        //console.log("singleQuestion.optionCount.countTrue     -- ", singleQuestion.optionCount[0].countTrue)
        const totaltrue = singleQuestion.optionCount[0].countTrue;
        graphCounts.push(totaltrue);
        graphLables.push("False");
        const totalfalse = singleQuestion.optionCount[0].countFalse;
        graphCounts.push(totalfalse);
        total = totaltrue + totalfalse;

        setGraphLebel(graphLables);
        setGraphCount(graphCounts);
        setTotal(total);
        //console.log(total);
        return false;
      }
      if (
        singleQuestion.question_id === questionid &&
        singleQuestion.questionType === "SC"
      ) {
        setCheckPieChart(false);
        singleQuestion.optionCount.forEach((x) => {
          graphLables.push(x.option_text);
          graphCounts.push(x.count);
          total = total + x.count;
        });
        setGraphLebel(graphLables);
        setGraphCount(graphCounts);
        setTotal(total);
        //console.log(total);
        return false;
      }
      if (
        singleQuestion.question_id === questionid &&
        singleQuestion.questionType === "MC"
      ) {
        setCheckPieChart(false);
        singleQuestion.optionCount.forEach((x) => {
          graphLables.push(x.option_text);
          graphCounts.push(x.count);
          total = total + x.count;
        });
        setGraphLebel(graphLables);
        setGraphCount(graphCounts);
        setTotal(total);
        //console.log(total);
        return false;
      }
      return true;
    });
    setModelShow(true);
  };
  const dataBar = {
    labels: graphLabel,
    datasets: [
      {
        label: "Feedback Analysis",
        // data: [12, 19, 3, 5, 2, 3],
        data: graphCount,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          // 'rgba(153, 102, 255, 0.2)',
          // 'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          // 'rgba(153, 102, 255, 1)',
          // 'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const optionBar = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          // var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          // var total = meta.total;

          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + " (" + percentage + "%)";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  const dataPie = {
    labels: graphLabel,
    datasets: [
      {
        label: "Feedback Analysis",
        // data: [12, 19, 3, 5, 2, 3],
        data: graphCount,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          // 'rgba(153, 102, 255, 0.2)',
          // 'rgba(255, 159, 64, 0.2)',
        ],
        //hoverOffset: 4,
      },
    ],
  };
  const optionPie = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          // var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          // var total = meta.total;

          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + " (" + percentage + "%)";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };
  /*************** END FEEDBACK CHART ***********************/
  const handleAccordianSelect = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  const findNodeById = (node, targetId) => {
    console.log("-------findNodeById------")
    if (node.id === targetId) {
      return node;
    }

    if (node.nodes && node.nodes.length > 0) {
      for (const childNode of node.nodes) {
        const result = findNodeById(childNode, targetId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };
  const openModal = () => {
    console.log("openModal");
    setIsModalOpen(true);
  };
  const closeModal = () => {
    console.log("closeModal");

    setIsModalOpen(false);
  };
  const ContentModal = ({
    isOpen,
    closeModal,
    folderData,
    onSelectContent,
  }) => {
    const [selectedContentId, setSelectedContentId] = useState(null);

    const saveContent = () => {
      addContentInBetween(
        newArr,
        getCourseStructureFolderId,
        selectedContentId
      );

    };

    const handleSelectContent = (contentId) => {
      setSelectedContentId(contentId);
    };

    const listItemStyle = {
      margin: '0.5rem 0',
      padding: '0.5rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: '#fff',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s ease',
      display: 'flex',
      alignItems: 'center',
    };

    const buttonStyle = {
      cursor: 'pointer',
      marginRight: '1rem',
      border: 'none',
      backgroundColor: 'transparent',
      color: '#007bff',
      fontWeight: 'bold',
      transition: 'color 0.3s ease',
    };

    const buttonHoverStyle = {
      textDecoration: 'underline',
      color: '#5c5c5c',
    };

    const renderNode = (node) => (
      <div key={node.id}>
        {node.nodes && node.nodes.length > 0 && (
          <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
            {node.nodes.map((childNode) => (
              <li
                key={childNode.id}
                style={{
                  ...listItemStyle,
                  backgroundColor:
                    selectedContentId === childNode.id ? '#e6e6e6' : listItemStyle.backgroundColor,
                }}
              >
                <button
                  style={buttonStyle}
                  onClick={() => handleSelectContent(childNode.id)}
                >
                  <span>
                    Add Content Above<strong> ⇧</strong>
                  </span>
                  <span style={buttonHoverStyle}>
                    {childNode.label}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    );

    return (
      <Modal
        show={isOpen}
        onHide={closeModal}
        backdrop="static"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Folder Name: {folderData && folderData.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{folderData && renderNode(folderData)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={saveContent}>Add</Button>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  ///////////////////////////////DRAG AND DROP//////////////////////////////
  const setViewFeedbackData = (row) => {
    console.log("row === ", row)
    // ShowFeedbackComponent(row)
    setFeebackRow(row)

  }
  const toggleFeedbackView = () => {
    // console.log("clicked")
    setShowFeedbacks(prevState => !prevState)

  }
  const setViewAnnouncement = () => {
    console.log("clicked")
    setShowAnnouncement(prevState => !prevState)

  }


  const onCourseStructureUpdate = () => {
    //console.log("courseId: ", courseId);
    //console.log("updatedCourseStructureJson: ", updatedCourseStructureJson);
    instructorService
      .updatedCourseStructure(UserId, courseId, updatedCourseStructureJson)
      .then((res) => {
        swal(`${t("success")}`, res.data, "success");
        handleDragModalClose();
        fatchCourseData();
      })
      .catch((err) => {
        // console.log(err.response);
        swal(`${t("error")}`, err.response.data, "error");
        handleDragModalClose();
      });
  };

  const handleUpdateStructureSubmit = () => {
    onCourseStructureUpdate();
  };

  const contentOwner = (structure) => {
    instructorService.isContentOwner(UserId, structure.id)
      .then((res) => {
        // console.log("is content owner ");
        // console.log("---------res", res.data);
        if (res.data) {
          setContentOwner(res.data);
        }
        else {
          swal(`${t('warning')}`, "You're not the owner of Content!");

        }
      }).catch((err) => {
        swal(`${t('error')}`, err.response.data, "error");

      })
  }

  const courseOwner = () => {
    const secretKey = "cdac@123";

    const encodedCourseId = props.match.params.cId
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const decodedCourseId = CryptoJS.AES.decrypt(
      encodedCourseId,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    instructorService.isCourseOwner(UserId, decodedCourseId).then((res) => {
      // console.log("------------", res.data)
      if (res.data) {
        //course owner have all access
        setCourseOwner(res.data)
        // console.log("_isCourseOwner",res.data)
      }


    }).catch((err) => {
      swal(`${t('error')}`, err.response.data, "error");
    })
  }

  const handleDragModalOpen = (structure, bool) => {
    console.log("==================>", structure)
    instructorService.isCourseOwner(UserId, courseId).then((res) => {
      // console.log("------------", res.data)
      if (res.data) {
        //course owner have all access
        setIsContentChange(bool)
        setStructure(structure);
        setDragModalOpen(true);
        // console.log("_isCourseOwner",res.data)
      }
      else {
        //alternative instructors  has access to their module only
        instructorService.isContentOwner(UserId, structure.id)
          .then((res) => {
            // console.log("is content owner ");
            // console.log("---------res", res.data);
            if (res.data) {
              setContentOwner(res.data);
              setIsContentChange(bool)
              setStructure(structure);
              setDragModalOpen(true);
            }
            else {
              swal(`${t('warning')}`, "You're not allowed to Rearrange the Content!", "warning");

            }
          }).catch((err) => {
            swal(`${t('error')}`, err.response.data, "error");

          })

      }
    }).catch((err) => {
      swal(`${t('error')}`, err.response.data, "error");
    })



    //verify the owner
    // setIsContentChange(bool)
    // setStructure(structure);
    // setDragModalOpen(true);
  }

  const handleDragModalClose = () => {
    setDragModalOpen(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      // Item dropped outside the list
      return;
    }

    const { source, destination } = result;

    // Clone the structure to avoid mutating the state directly
    const updatedStructure = { ...structure };

    // Assuming a flat structure for simplicity
    const [movedItem] = updatedStructure.nodes.splice(source.index, 1);
    updatedStructure.nodes.splice(destination.index, 0, movedItem);

    setStructure(updatedStructure);
    // setUpdatedCourseStructureJson(updatedStructure)

    // If it's a content change, call the separate function
    if (isContentChange) {
      handleContentChange(updatedStructure);
    } else {
      setUpdatedCourseStructureJson(updatedStructure);
    }
  };

  const handleContentChange = (modifiedStructure) => {
    // Find the folder in the updatedCourseStructureJson and replace it
    const folderId = modifiedStructure.id;
    const updatedStructureCopy = { ...updatedCourseStructureJson };
    const foundFolder = findFolderById(updatedStructureCopy, folderId);

    if (foundFolder) {
      foundFolder.nodes = modifiedStructure.nodes;
      setUpdatedCourseStructureJson(updatedStructureCopy);
    }
  };

  // Function to find a folder by ID in the course structure
  const findFolderById = (structure, folderId) => {
    if (structure.id === folderId) {
      return structure;
    } else {
      for (const node of structure.nodes) {
        if (node.id === folderId) {
          return node;
        } else if (node.nodes && node.nodes.length > 0) {
          const foundInNestedFolder = findFolderById(node, folderId);
          if (foundInNestedFolder) {
            return foundInNestedFolder;
          }
        }
      }
      return null;
    }
  };
  function updateCertificateStatue(certificateStatus) {

    //console.log("certificateStatus   ---->    ",certificateStatus);
    instructorService.updateCertificateStatus(courseId, certificateStatus)
      .then((res) => {
        //console.log(res.data);
      })
      .catch((err) => {
        //console.log(err.data);
      })
  }
  const handleCertificateInCourse = () => {
    setInactiveCertificateInCourse(!inactiveCertificateInCourse);
    if (!inactiveCertificateInCourse) {
      setInactiveCertificateInCourse(1);
      updateCertificateStatue(1);
    } else {
      setInactiveCertificateInCourse(0);
      updateCertificateStatue(0);
    }
  };
  const externalLinkContentToCourseStructure = (id) => {
    const externalLink = document.getElementById("externalLink");
    // const duration = document.getElementById("duration_videoStreaming");
    const StramingLinkData = [
      {
        categoryType: getCourseDetails.categoryName,
        cname: "External Link",
        contentId: 0,
        contentType: "externalLink",
        courseId: courseId,
        description: "Basic desc",
        filepath: externalLink.value,
        itemId: getCourseStructureFolderId,
        pcontentId: 0,
        publishDate: toDateTime(startDate),
        topicDuration: 1,
        userId: UserId,
      },
    ];
    if (id === undefined) {
      swal(`${t("select")}`, `${t("node_select")}`, "warning");
    } else {
      instructorService
        .addContentToCourseStructure(StramingLinkData)
        .then(async (res) => {
          if (res.data === "Content added successfully!!") {
            await swal(`${t("success")}`, `${t("content_added")}`, "success");
            setVideoModal({ show: false });
            // setToggle(false);
            instructorService.getCourseById(courseId).then((res) => {
              setInactiveCertificateInCourse(res.data.certificate);
              setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
            });
            folderStructureTreeCourse();
          }
        })
        .catch((err) => { });
    }
  };

  const toggleViewAssignment = () => {
    setViewAssignment(prevState => !prevState)
  }
  const toggleAssignmentModal = () => {
    if (!getAssignmentList.show) {
      assignmentListAPI()
    }
    setAssignmentList(prevState => ({
      ...prevState,
      show: !getAssignmentList.show
    }))
  }
  const assignmentListAPI = async () => {
    try {
      let result = await AssignmentService.getAssignDetail(UserService.getUserid(), courseId, tenantId)
      setAssignmentList((prevState) => {
        return {
          ...prevState,
          assignmentList: [...(result.data)]
        }
      })
      // setAssignmentList(result.data);
    } catch (error) {

    }
  }
  const filteredAssignmentList = getAssignmentList.assignmentList.filter(
    item =>
      item.name.toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  const handleAssignRowSelected = React.useCallback((state) => {
    // setAssignSelectedRows(state.selectedRows);
    setAssignmentList((prevState) => {
      return {
        ...prevState,
        assignSelectedRows: [...(state.selectedRows)]
      }
    })

  }, []);
  const addAssignmentToCourseStructure = () => {
    if (getAssignmentList.assignSelectedRows.length === 0) {
      swal(`${t("select")}`, `${t("node_select")}`, "warning");
    } else {
      const addAssigmentList = [];
      getAssignmentList.assignSelectedRows.map((assigment) => {
        const singleAssignment = {
          categoryType: getCourseDetails.categoryName,
          cname: assigment?.name,
          contentId: 0,
          contentType: "assignment",
          courseId: courseId,
          description: assigment.description,
          filepath: assigment?.assignment_id,
          itemId: getCourseStructureFolderId,
          pcontentId: assigment?.assignment_id,
          publishDate: toDateTime(startDate),
          topicDuration: 1,
          userId: UserId,
        }
        addAssigmentList.push(singleAssignment)
      });
      console.log("get assigment data", getAssignmentList.assignSelectedRows, addAssigmentList)
      instructorService
        .addContentToCourseStructure(addAssigmentList)
        .then(async (res) => {
          if (res.data === "Content added successfully!!") {
            await swal(`${t("success")}`, `${t("content_added")}`, "success");
            setAssignmentList(initAssignmentToCourseStructure)
            // setToggle(false);
            instructorService.getCourseById(courseId).then((res) => {
              setInactiveCertificateInCourse(res.data.certificate);
              setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
            });
            folderStructureTreeCourse();
          }
        })
        .catch((err) => { });

    }




  }
  const columnViewAssignment = [
    {
      name: 'Assignment Name',
      selector: row => row.name,
      sortable: true,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Description',
      selector: row => row.description,
      wrap: true
    },
    {
      name: 'Author',
      selector: row => getInstructors[row.createdBy]?.[0]?.firstName || "--",
      wrap: true
    },
    {
      name: 'Total Marks',
      selector: row => row.totalMarks,
      wrap: true,
      width: '130px',
    },
    {
      name: 'Passing Marks',
      selector: row => row.passMarks,
      wrap: true,
      width: '150px',
    },
    {
      name: "Opening Date",
      selector: row => moment(row.openingDate).format('DD-MM-yyyy'),
      // selector: row => moment(row.openingDate).format('MM-DD-YYYY'),
      wrap: true,
      width: '150px',
    },
    {
      name: 'Closing Date',
      selector: row => moment(row.closingDate).format('DD-MM-yyyy'),
      // selector: row => moment(row.closingDate).format('MM-DD-YYYY'),
      wrap: true,
      width: '150px',
    },


  ]
  //functionend-

  return (
    <div className="main-wrapper course-details-page" onLoad={scrollWin}>
      <HeaderTwo />
      <BreadcrumbBox
        title={getCourseDetails.courseName}
        bannerUrl={`${URI.SERVER?._COURSE_URL}/${getCourseDetails.banner}`}
      />
      <Styles>
        <section className="course-details-area" id="Main">
          <Container>
            <div className="course-details-top">
              <Col lg="8">
                <div className="child1">
                  <div className="heading">
                    <h4 style={{ textTransform: 'capitalize' }}>{getCourseDetails.courseName}</h4>
                  </div>
                  <div className="course-top-overview">
                    <div className="d-flex overviews">
                      <div className="category">
                        <h6>{t('category')}</h6>
                        <p>{getCourseDetails.categoryName}</p>
                      </div>
                      <div className="rating">
                        <h6>Rating</h6>
                        <ul className="list-unstyled list-inline">
                          {getAvgRating.length > 0 && (
                            <Rating readonly initialValue={getAvgRating[0].avgScore} size={25} />
                          )}
                        </ul>
                      </div>
                      <div className="price">
                        <h6>{t('price')}</h6>
                        <p>{fee_validator(getCourseDetails.courseFee, getCourseDetails.courseType)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="course-details-banner" style={{ height: '100%', width: '100%' }}>
                    {getCourseDetails.video ? (
                      <video
                        src={`${process.env.PUBLIC_URL}${URI.SERVER?._COURSE_URL}/${getCourseDetails.video}`}
                        className="img-fluid"
                        controls
                        controlsList="nodownload noplaybackrate"
                        disablePictureInPicture
                        autoPlay
                        muted
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}${URI.SERVER?._COURSE_URL}/${getCourseDetails.imageUrl}`}
                        alt="Course Image"
                        className="img-fluid"
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="child2">
                  <div className="single-details-sidbar">
                    <Row>
                      <Col md="12">
                        <div className="course-details-feature">
                          <h5 className="title">{t('course_details')}</h5>
                          <ul className="list-unstyled feature-list">
                            <li>
                              <i className="far fa-calendar-check"></i> {t('enrollment_start_date')}:{' '}
                              <span>{convertDate(getCourseDetails.enrollStartDate)}</span>
                            </li>
                            <li>
                              <i className="far fa-calendar-check"></i> {t('enrollment_end_date')}:{' '}
                              <span>{convertDate(getCourseDetails.enrollEndDate)}</span>
                            </li>
                            <li>
                              <i className="far fa-calendar-check"></i> {t('course_Publish_Date')}:{' '}
                              <span>{convertDate(getCourseDetails.publishDate)}</span>
                            </li>
                            <li>
                              <i className="far fa-calendar-check"></i> {t('commence_date')}:{' '}
                              <span>{convertDate(getCourseDetails.commenceDate)}</span>
                            </li>
                            <li>
                              <i className="far fa-calendar-check"></i> {t('closing_date')}:{' '}
                              <span>{convertDate(getCourseDetails.closingDate)}</span>
                            </li>
                            <li>
                              <i className="far fa-clock"></i> {t('duration')}: <span>{getCourseDetails.duration === 1825 ? 'Unlimited' : getCourseDetails.duration} {t('days')}</span>
                            </li>
                            <li>
                              <i className="fas fa-globe-asia"></i> {t('language1')}: <span>{t('english')}</span>
                            </li>
                            <li>
                              <i className="far fa-bookmark"></i> {t('enrolled')}: <span>{userCount}</span>
                            </li>
                            <li>
                              <i className="fas fa-certificate"></i> {t('certification')}: <span>{t('yes')}</span>
                            </li>
                          </ul>
                          <ul className="feature-list">
                            {getCourseDetails.status === 'C' && getCourseOwner && (
                              <>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => disableCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('Disable Course')}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => publishCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('request_to_publish_course')}
                                  </button>
                                </li>
                              </>
                            )}
                            {getCourseDetails.status === 'P' && getCourseOwner && (
                              <>
                                {userCount === 0 && (
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() => unPublishCourseButton(courseId)}
                                      className="enroll-btn"
                                    >
                                      {t('unpublish_course')}
                                    </button>
                                  </li>
                                )}
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => disableCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('Disable Course')}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => publishCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('request_to_publish_course')}
                                  </button>
                                </li>
                              </>
                            )}
                            {getCourseDetails.status === 'U' && (
                              <>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => disableCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('Disable Course')}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => publishCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('request_to_publish_course')}
                                  </button>
                                </li>
                              </>
                            )}
                            {getCourseDetails.status === 'D' && (
                              <li>
                                <button
                                  type="button"
                                  onClick={() => publishCourseButton(courseId)}
                                  className="enroll-btn"
                                >
                                  {t('enable_course')}
                                </button>
                              </li>
                            )}
                            {getCourseDetails.status === 'R' && (
                              <>
                                {userCount === 0 && (
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() => unPublishCourseButton(courseId)}
                                      className="enroll-btn"
                                    >
                                      {t('unpublish_course')}
                                    </button>
                                  </li>
                                )}
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => disableCourseButton(courseId)}
                                    className="enroll-btn"
                                  >
                                    {t('Disable Course')}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => publishCourseButton(courseId)}
                                    disabled
                                    className="enroll-btn"
                                    style={{ background: '#182B49' }}
                                  >
                                    {t('waiting_for_approval')}
                                  </button>
                                </li>
                              </>
                            )}
                          </ul>
                          <ul className="list-unstyled feature-list">
                            {getCourseStructureJson.lastPublishedDate && (
                              <li>
                                {t('last_published_date')}: <span>{moment(getCourseStructureJson.lastPublishedDate).format('DD-MM-yyyy HH:mm')}</span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </div>
          </Container>
        </section>
        <section className="course-details-area my-2" id="Tab">
          <Container>
            <div className="course-tab-list">
              <Tab.Container defaultActiveKey="overview">
                <Nav className="flex-column" onClick={scrollToView}>
                  <Nav.Item>
                    <Nav.Link eventKey="overview">{t('overview')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="curriculum">{t('curriculum')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="instructor">{t('instructors')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="announcement">{t('announcement')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="review">{t('reviews')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="discussion">{t('discussion')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="feedback">{t('course_feedback')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="query">{t('query')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="assignment">{t('assignment')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="assessment">{t('assessment')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="certificate">Certificate</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview" className="overview-tab">
                    <div className="course-desc">
                      <h5>{t('course_description')}</h5>
                      <p dangerouslySetInnerHTML={{ __html: getCourseDetails.generalDetails }}></p>
                    </div>
                    <div className="course-feature">
                      <h5>{t('course_prerequisite')}</h5>
                      <p dangerouslySetInnerHTML={{ __html: getCourseDetails.prerequisite }}></p>
                    </div>
                    <div className="course-feature">
                      <h5>{t('course_objective')}</h5>
                      <p dangerouslySetInnerHTML={{ __html: getCourseDetails.objective }}></p>
                    </div>
                    <div className="course-share">
                      <h5>{t('share_this_course')}</h5>
                      <ul className="social list-unstyled list-inline">
                        <li className="list-inline-item">
                          <a href={`https://www.facebook.com/${getUserDetails.facebookId}`} target="_blank">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={`https://www.twitter.com/${getUserDetails.twitterId}`} target="_blank">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={`https://www.linkedin.com/${getUserDetails.linkedinId}`} target="_blank">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={`https://www.youtube.com/${getUserDetails.youtubeId}`} target="_blank">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={`https://www.skype.com/${getUserDetails.skypeId}`} target="_blank">
                            <i className="fab fa-dribbble"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum" className="curriculum-tab">
                    <div className="course-curriculum">
                      <div className="btn-group btn-breadcrumb" style={{ border: '1px solid #d3d3d3', borderRadius: '5px' }}>
                        <a className="btn btn-default">
                          <i className="fas fa-house-user fa-lg" style={{ color: 'rgb(1, 94, 60)' }}></i>
                        </a>
                        <Breadcrumb />
                      </div>
                      <div className="items">{itemsCourse}</div>
                    </div>
                    {toggle ? (
                      <div className="d-flex justify-content-between shadow-sm rounded" style={{ border: '1px solid #80808024', padding: '10px' }}>
                        <section>
                          <h5 style={{ textAlign: 'center', fontWeight: 'bold', position: 'relative' }}>
                            <span className="text-success">Add </span>
                            <span className="text-secondary">
                              <i className="fas fa-hand-point-right ml-1" style={{ fontSize: '25px' }}></i>
                            </span>
                            <span style={{ position: 'absolute', left: '40.5%', bottom: '-4px', width: '30%', height: '2px', backgroundColor: '#28a745' }}></span>
                          </h5>
                        </section>
                        <section>
                          <button className="create-button" onClick={toggleCourseDriveModal}>
                            <i className="fa fa-file"></i>Add Content
                          </button>
                        </section>
                        <section>
                          <button className="create-button" onClick={toggleAssignmentModal}>
                            <i className="fas fa-folder-plus"></i>Assignment
                          </button>
                        </section>
                        <section>
                          <button className="create-button" onClick={handleModal}>
                            <i className="fab fa-youtube"></i>YouTube Video Link
                          </button>
                        </section>
                        <section>
                          <button className="create-button" onClick={videoModalShow}>
                            <i className="fa fa-file-video-o"></i>External Link
                          </button>
                        </section>
                      </div>
                    ) : ''}
                  </Tab.Pane>
                  <Tab.Pane eventKey="instructor" className="instructor-tab">
                    <h5>{t('course_instructor')}</h5>
                    <div className="instructor-box">
                      <div className="instructor-item">
                        <Row>
                          <div className="instructor-img">
                            <img src={`${headerState.img}${headerState.id}`} alt="" className="img-fluid" style={{ width: '110px' }} />
                          </div>
                          <Col md="8">
                            <div className="instructor-content">
                              <div className="instructor-box">
                                <div className="top-content">
                                  <div className="instructor-name">
                                    <h6>{UserService.getUsername()}</h6>
                                    <p>{getCourseDetails.inst_profile}</p>
                                  </div>
                                  <div className="instructor-social">
                                    <ul className="social list-unstyled list-inline">
                                      <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}>
                                        <a href={`https://www.facebook.com/${getUserDetails.facebookId}`} target="_blank">
                                          <i className="fab fa-facebook-f"></i>
                                        </a>
                                      </li>
                                      <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}>
                                        <a href={`https://www.twitter.com/${getUserDetails.twitterId}`} target="_blank">
                                          <i className="fab fa-twitter"></i>
                                        </a>
                                      </li>
                                      <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}>
                                        <a href={`https://www.linkedin.com/in/${getUserDetails.linkedinId}`} target="_blank">
                                          <i className="fab fa-linkedin-in"></i>
                                        </a>
                                      </li>
                                      <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}>
                                        <a href={`https://www.youtube.com/${getUserDetails.youtubeId}`} target="_blank">
                                          <i className="fab fa-youtube"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="instructor-desk"></div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="review" className="review-tab">
                    <Row>
                      <Col md="12">
                        <div className="review-comments">
                          <h5>{t('course_reviews_2')}</h5>
                          {ratingView && (
                            <div style={{ overflowY: 'auto', height: '60vh' }}>
                              {getRating.map((data) => (
                                <div className="comment-box d-flex">
                                  <div className="comment-image">
                                    <img src={data.profilePicUrl} alt="" />
                                  </div>
                                  <div className="comment-content" style={{ width: '100%' }}>
                                    <div className="content-title d-flex justify-content-between">
                                      <div className="comment-writer">
                                        <h6>{data.firstName}</h6>
                                        <p>{convertDate(data.creationTime)}</p>
                                        <ul className="list-unstyled list-inline">
                                          <Rating readonly size={25} initialValue={data.rating} />
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="comment-desc">
                                      <p>{data.reviewText}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {!ratingView && (
                            <div>
                              <p>{t('no_review')}</p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="announcement" className="announcement-tab">
                    <div className="shadow-lg p-3 bg-body rounded">
                      <Card>
                        <DataTable
                          columns={columnsAnnouncement}
                          data={filteredItemsAnnouncement}
                          defaultSortField="Name"
                          defaultSortAsc={true}
                          striped
                          pagination
                          highlightOnHover
                          customStyles={customStyles}
                          subHeader
                          subHeaderComponent={subHeaderComponent}
                          fixedHeader
                          fixedHeaderScrollHeight="300px"
                        />
                        <Row style={{ marginBottom: '15px', marginLeft: '18px', marginTop: '-45px' }}>
                          <Col>
                            <Button
                              onClick={() => {
                                reset();
                                setAddAnnouncementDiv(true);
                                setViewAnnouncement();
                              }}
                              variant="success"
                            >
                              {t('add_announcement')}
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                    {announcementDiv && (
                      <Modal centered show={showAnnouncement} onHide={setViewAnnouncement} size="xl" backdrop="static" scrollable>
                        <Modal.Header closeButton>
                          <div className="card-header bg-transparent border-0 text-center text-uppercase">
                            <h3>{announcementData.id ? t('update_announcement') : t('add_announcement')}</h3>
                          </div>
                        </Modal.Header>
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <Modal.Body>
                            <div className="card shadow-sm border-0">
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="mb-0">
                                    {t('tittle')}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="title"
                                    type="text"
                                    value={announcementData.title}
                                    className="form-control"
                                    minLength={5}
                                    maxLength={200}
                                    placeholder={t('enter_title')}
                                    onChange={(e) => setAnnouncementData({ ...announcementData, title: e.target.value })}
                                  />
                                  {announcementData.titleError && (
                                    <div className="alert alert-danger mt-2">{announcementData.titleError}</div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label className="mb-0">
                                    {t('description')}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <textarea
                                    name="titledesc"
                                    type="text"
                                    value={announcementData.titledesc}
                                    className="form-control"
                                    minLength={5}
                                    maxLength={500}
                                    placeholder={t('enter_title_desc')}
                                    onChange={(e) => setAnnouncementData({ ...announcementData, titledesc: e.target.value })}
                                  />
                                  {announcementData.titledescError && (
                                    <div className="alert alert-danger mt-2">{announcementData.titledescError}</div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label className="mb-0">
                                    {t('publish_from')}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="publishfrom"
                                    value={announcementData.publishfrom}
                                    type="datetime-local"
                                    className="form-control"
                                    placeholder={t('enter_date')}
                                    onChange={(e) => setAnnouncementData({ ...announcementData, publishfrom: e.target.value })}
                                  />
                                  {announcementData.publishfromError && (
                                    <div className="alert alert-danger mt-2">{t('date_required_field')}</div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label className="mb-0">
                                    {t('publish_upto')}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="publishupto"
                                    min={announcementData.publishfrom}
                                    value={announcementData.publishupto}
                                    type="datetime-local"
                                    className="form-control"
                                    placeholder={t('enter_date')}
                                    onChange={(e) => setAnnouncementData({ ...announcementData, publishupto: e.target.value })}
                                  />
                                  {announcementData.publishuptoError && (
                                    <div className="alert alert-danger mt-2">{t('date_required_field')}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button type="submit" variant="light">
                              {announcementData.id ? t('update') : t('add')}
                            </Button>
                            {!announcementData.id && (
                              <Button type="reset" onClick={reset} variant="light">
                                {t('reset')}
                              </Button>
                            )}
                            <Button variant="danger" onClick={setViewAnnouncement}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </form>
                      </Modal>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="discussion" className="overview-tab">
                    {isDecodeValid === 'VALID' && (
                      <DiscussionMain courseid={courseId} tenantid={tenantId} userid={UserId} itemid={courseId} />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="feedback" className="overview-tab">
                    <Tab.Container defaultActiveKey="AddQuestion">
                      <Nav className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="AddQuestion">{t('add_question')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="AddMaster">{t('manage_feedBack')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="ViewFeedback">{t('view_feedback')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="FeedbackGraph">{t('feedback_analysis')}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="AddMaster" className="overview-tab">
                          <div className="shadow-lg p-3 bg-body rounded">
                            <Card>
                              <DataTable
                                columns={FeedbackList}
                                data={feedbackListData}
                                striped
                                highlightOnHover
                                customStyles={customStyles}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                fixedHeader
                                fixedHeaderScrollHeight="300px"
                                pagination
                              />
                              <Row style={{ marginBottom: '15px', marginLeft: '2px', marginTop: '-40px' }}>
                                <Col>
                                  <Button onClick={() => setShowAddFeedback(true)} className="btn btn-success pull-left m-2 w-10">
                                    {t('create_new_feedback')}
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          </div>
                          {showAddFeedback && (
                            <Modal centered show={showAddFeedback} size="xl" onHide={closeCreateFeedback} backdrop="static" scrollable>
                              <Modal.Header closeButton>
                                <Modal.Title>Create Feedback</Modal.Title>
                              </Modal.Header>
                              <form onSubmit={feedbackhandleSubmit} autoComplete="off">
                                <Modal.Body>
                                  <Card>
                                    <div className="p-3 bg-body bg-light rounded shadow-sm">
                                      <div className="form-group">
                                        <label className="mb-0">
                                          {t('feedback_title')}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          name="feedback_title"
                                          type="text"
                                          minLength={2}
                                          maxLength={150}
                                          value={feedbackData.feedback_title}
                                          className="form-control"
                                          placeholder={t('enter_feedback_title')}
                                          onChange={(e) => setfeedbackData({ ...feedbackData, feedback_title: e.target.value })}
                                        />
                                        {feedbackData.feedbacktitleError && (
                                          <div className="alert alert-danger mt-2">{feedbackData.feedbacktitleError}</div>
                                        )}
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-0">
                                          {t('feedback_description')}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                          name="feedback_desc"
                                          type="text"
                                          minLength={2}
                                          maxLength={500}
                                          value={feedbackData.feedbacktitledesc}
                                          className="form-control"
                                          placeholder={t('enter_feedback_description')}
                                          onChange={(e) => setfeedbackData({ ...feedbackData, feedbacktitledesc: e.target.value })}
                                        />
                                        {feedbackData.feedbacktitledescError && (
                                          <div className="alert alert-danger mt-2">{feedbackData.feedbacktitledescError}</div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="p-3 bg-body rounded">
                                      <Card>
                                        <DataTable
                                          columns={coloumnfeedbackQuestion1}
                                          data={filteredItemsFeedbackQuestion}
                                          defaultSortField="Name"
                                          defaultSortAsc={true}
                                          striped
                                          highlightOnHover
                                          customStyles={customStyles}
                                          subHeader
                                          subHeaderComponent={subHeaderComponent}
                                          fixedHeader
                                          fixedHeaderScrollHeight="300px"
                                          selectableRows
                                          onSelectedRowsChange={questionRowSelected}
                                          pagination
                                        />
                                      </Card>
                                    </div>
                                  </Card>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    type="submit"
                                    className="btn btn-success pull-left m-2 w-10"
                                    disabled={questionIds.length === 0}
                                  >
                                    Create
                                  </Button>
                                  <Button onClick={closeCreateFeedback} className="btn btn-danger pull-left m-2 w-10">
                                    {t('cancel')}
                                  </Button>
                                </Modal.Footer>
                              </form>
                            </Modal>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="AddQuestion" className="overview-tab">
                          <div className="shadow-lg p-3 bg-body rounded">
                            <Card>
                              <DataTable
                                columns={coloumnfeedbackQuestion}
                                data={filteredItemsFeedbackQuestion}
                                defaultSortField="Name"
                                defaultSortAsc={true}
                                striped
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                fixedHeader
                                fixedHeaderScrollHeight="300px"
                              />
                            </Card>
                            <br />
                            <Button variant="success" onClick={onClickCreateQuestion}>
                              {t('create_question')}
                            </Button>
                          </div>
                          <br />
                          {showCreateQuestion && (
                            <Modal centered show={showCreateQuestion} onHide={onClickCloseButton} size="xl" backdrop="static" scrollable>
                              <Modal.Header closeButton>
                                <Modal.Title>Create Question</Modal.Title>
                              </Modal.Header>
                              <form onSubmit={questionhandleSubmit} autoComplete="off">
                                <Modal.Body>
                                  <div className="p-3 bg-body rounded">
                                    <div className="form-group">
                                      <label className="mb-0">
                                        {t('question')}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        name="feedback_title"
                                        type="text"
                                        minLength={2}
                                        maxLength={150}
                                        value={questionData.question}
                                        className="form-control"
                                        placeholder={t('enter_question')}
                                        onChange={(e) => setquestionData({ ...questionData, question: e.target.value })}
                                      />
                                      {questionData.questionError && (
                                        <div className="alert alert-danger mt-2">{questionData.questionError}</div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label className="mb-0">
                                        {t('question_mandatory')}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          checked={questionData.mandatory === 'true'}
                                          type="radio"
                                          value="true"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          onChange={(e) => setquestionData({ ...questionData, mandatory: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                          {t('yes')}
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          checked={questionData.mandatory === 'false'}
                                          type="radio"
                                          value="false"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault2"
                                          onChange={(e) => setquestionData({ ...questionData, mandatory: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                          {t('no')}
                                        </label>
                                      </div>
                                      {questionData.mandatoryError && (
                                        <div className="alert alert-danger mt-2">{questionData.mandatoryError}</div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label className="mb-0">
                                        {t('question_type')}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-control"
                                        value={questionData.questionType}
                                        onChange={(e) => setquestionData({ ...questionData, questionType: e.target.value })}
                                        aria-label="Default select example"
                                      >
                                        <option selected>{t('select_question')}</option>
                                        <option value="SC">{t('single_choice')}</option>
                                        <option value="MC">{t('multiple_choice')}</option>
                                        <option value="TF">{t('true_and_false')}</option>
                                        <option value="TA">{t('descriptive')}</option>
                                      </select>
                                      {questionData.questionTypeError && (
                                        <div className="alert alert-danger mt-2">{questionData.questionTypeError}</div>
                                      )}
                                    </div>
                                    {(questionData.questionType === 'SC' || questionData.questionType === 'MC') && (
                                      <div className="form-group">
                                        <label className="mb-0">
                                          {t('options')}
                                          <span className="text-danger">*</span>
                                        </label>
                                        {serviceList.map((singleService, index) => (
                                          <div key={index} className="services">
                                            <div className="first-division">
                                              <input
                                                className="form-control"
                                                name="service"
                                                type="text"
                                                id="service"
                                                value={singleService.service}
                                                onChange={(e) => handleServiceChange(e, index)}
                                              />
                                              {serviceList.length - 1 === index && serviceList.length < 4 && (
                                                <i
                                                  className="fa fa-plus-circle pull-right fa-2x"
                                                  onClick={handleServiceAdd}
                                                  style={{ color: 'green' }}
                                                  aria-hidden="true"
                                                ></i>
                                              )}
                                            </div>
                                            <br />
                                            <div className="second-division">
                                              {serviceList.length !== 1 && (
                                                <i
                                                  className="fa fa-times-circle pull-right fa-2x"
                                                  onClick={() => handleServiceRemove(index)}
                                                  style={{
                                                    background: 'radial-gradient(white 50%, transparent 50%)',
                                                    color: 'red',
                                                  }}
                                                  aria-hidden="true"
                                                ></i>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                        {questionData.questionOptionError && (
                                          <div className="alert alert-danger mt-2">{questionData.questionOptionError}</div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button type="submit" className="btn btn-light pull-left m-2 w-10">
                                    {questionData.questionId ? t('Update') : t('add')}
                                  </Button>
                                  <Button type="reset" onClick={feedbackQuestionReset} className="btn btn-light pull-left m-2 w-10">
                                    {t('reset')}
                                  </Button>
                                  <Button variant="danger" onClick={onClickCloseButton}>
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </form>
                            </Modal>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="ViewFeedback" className="overview-tab">
                          <div className="shadow-lg p-3 bg-body rounded">
                            <Accordion defaultActiveKey="0">
                              {getFeedbackResponse.length === 0 ? (
                                <p>{t('response_condition')}</p>
                              ) : (
                                getFeedbackResponse.map((data, i) => (
                                  <Accordion.Item eventKey={i}>
                                    <Accordion.Header>
                                      {i + 1} {data.feedbackTitle}
                                    </Accordion.Header>
                                    <Accordion.Body style={{ marginLeft: '25px' }}>
                                      {data.responseMaster.map((a, j) => (
                                        <div style={{ margin: '10px', padding: '10px' }}>
                                          {j + 1}.{a.questionMaster.question}
                                          {(a.questionMaster.questionType === 'TF' || a.questionMaster.questionType === 'TA') && (
                                            <ul style={{ listStyleType: 'none', marginLeft: '10px' }}>
                                              <li>Ans. {a.feedbackResponse} </li>
                                            </ul>
                                          )}
                                          {a.questionMaster.optionsMasters.map((d, k) => (
                                            <ul style={{ listStyleType: 'none', marginLeft: '10px' }}>
                                              <li>
                                                {k + 1}. {d.optionText}{' '}
                                                {feedbackResponseCheckHandler(a.feedbackResponse).includes(d.optionId) && (
                                                  <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true"></i>
                                                )}
                                              </li>
                                            </ul>
                                          ))}
                                        </div>
                                      ))}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                ))
                              )}
                            </Accordion>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="FeedbackGraph" className="overview-tab">
                          {feedbackList.map((data, index) => (
                            <Accordion activeKey={activeKey} onSelect={handleAccordianSelect}>
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header onClick={() => onClickAccodian(index)}>
                                  {data.feedbackTitle}
                                </Accordion.Header>
                                <Accordion.Body style={{ marginLeft: '25px' }}>
                                  <Card>
                                    <DataTable columns={listFeedbackColumn} data={listFeedbackData} />
                                  </Card>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          ))}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="query" className="overview-tab">
                    {isDecodeValid === 'VALID' && (
                      <Query courseid={courseId} tenantid={tenantId} userid={UserId} itemid={courseId} instructor="instructor" />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="assignment" className="overview-tab">
                    {isDecodeValid === 'VALID' && <FrontAssignment courseID={courseId} tenantID={tenantId} userID={UserId} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="assessment" className="overview-tab pb-3">
                    {isDecodeValid === 'VALID' && (
                      <a
                        className="btn btn-success btn-lg btn-block"
                        href={`${URI.SERVER?._ASSESSMENT_AUTHORING}/assessment/authoring/dashboard/courseId/${courseId}`}
                        onClick={assementLoaderFunction}
                        disabled={assementLoader.isLoading}
                      >
                        {assementLoader.isLoading ? t('loading') : t('manage_que_quize')}
                      </a>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="certificate" className="overview-tab">
                    <Row style={{ border: '1px solid #f0ad4e', borderRadius: '10px', padding: '5px', margin: '5px' }}>
                      <Col>
                        <h4>Certificate (Enable/Disable) :</h4>
                      </Col>
                      <Col>
                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                          <Switch
                            onChange={handleCertificateInCourse}
                            checked={inactiveCertificateInCourse}
                            className="react-switch"
                          />
                        </label>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Container>
          {selectedContent && (
            <ContentReviews
              selectedContent={selectedContent}
              setselectedContent={setselectedContent}
              contentRatingModalShow={contentRatingModalShow}
              setContentRatingModalShow={setContentRatingModalShow}
            />
          )}
        </section>
      </Styles>
      <FooterTwo />
      {/* Modal's */}
      <ShareUrlModal shareUrlModal={shareUrlModal} shareUrlModalHide={shareUrlModalHide} getShareUrlData={getShareUrlData} />

      <CreateModuleTopicModal
        getModuleModalState={getModuleModalState}
        AddModuleModalHide={AddModuleModalHide}
        errorMsg={errorMsg}
        startDate={startDate}
        setStartDate={setStartDate}
        changeBackgroundOut={changeBackgroundOut}
        changeBackgroundOver={changeBackgroundOver}
        AddModule={() => AddModule(getCourseStructureFolderId)}
      />

      <UpdateFolderOrContentDetailsModal
        getFolderOrContentDetUpdate={getFolderOrContentDetUpdate}
        UpdateContentOrFolderModalHide={UpdateContentOrFolderModalHide}
        getPdate={getPdate}
        setPdate={setPdate}
        changeBackgroundOut={changeBackgroundOut}
        changeBackgroundOver={changeBackgroundOver}
        updateContentOrModule={() => updateContentOrModule(getFolderOrContentDetUpdate?.id)}
        errorMsg={errorMsg}
        category={"course"}
      />

      <YoutubeLinkModal
        getModalState={getModalState}
        handleModal2={handleModal2}
        errorMsg={errorMsg}
        getFileContentDetails={getFileContentDetails}
        startDate={startDate}
        setStartDate={setStartDate}
        changeBackgroundOut={changeBackgroundOut}
        changeBackgroundOver={changeBackgroundOver}
        YoutubeAddContentToCourseStructure={() => YoutubeAddContentToCourseStructure(getCourseStructureFolderId)}
      />

      <TopicFeedbackModal getTopicFeedback={getTopicFeedback} TopicFeedbackModalHide={TopicFeedbackModalHide} courseId={courseId} itemIdForFeedback={itemIdForFeedback} />

      <FeedbackAnalysisModal
        modelShow={modelShow}
        setModelShow={setModelShow}
        checkPieChart={checkPieChart}
        dataPie={dataPie}
        optionPie={optionPie}
        dataBar={dataBar}
        optionBar={optionBar}
      />

      <StreamingLinkModal
        videoModal={videoModal}
        videoModalHide={videoModalHide}
        errorMsg={errorMsg}
        getFileContentDetails={getFileContentDetails}
        startDate={startDate}
        setStartDate={setStartDate}
        changeBackgroundOut={changeBackgroundOut}
        changeBackgroundOver={changeBackgroundOver}
        externalLinkContentToCourseStructure={() => externalLinkContentToCourseStructure(getCourseStructureFolderId)}
      />
      <DrapAndDropModal
        dragModalOpen={dragModalOpen}
        handleDragModalClose={handleDragModalClose}
        handleDragEnd={handleDragEnd}
        structure={structure}
        onCourseStructureUpdate={onCourseStructureUpdate}
      />

      <FeedbackQuestionViewModal showFeedbacks={showFeedbacks} toggleFeedbackView={toggleFeedbackView} getFeedbackRow={getFeedbackRow} getInstructors={getInstructors} />

      <CourseDriveModal
        show={courseDriveModal}
        onHide={toggleCourseDriveModal}
        items={items}
        getContentDetails={getContentDetails}
        columns={columns}
        filteredItems={filteredItems}
        subHeaderComponent={subHeaderComponent}
        handleRowSelected={handleRowSelected}
        selectedRows={selectedRows}
        startDate={startDate}
        setStartDate={setStartDate}
        ExampleCustomInput={ExampleCustomInput}
        AddContentToCourseStructure={AddContentToCourseStructure}
        newArr={newArr}
        getCourseStructureFolderId={getCourseStructureFolderId}
        getCourseStructureJson={getCourseStructureJson}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        folderData={folderData}
        setFolderData={setFolderData}
        findNodeById={findNodeById}
        openModal={openModal}
        ContentModal={ContentModal}
        Breadcrumb={Breadcrumb}
        setToggle={setToggle}
        isLibrary={getLibrary}
      />

      <PreviewModal
        show={getUrlModal?.show}
        onHide={UrlModalHide}
        contentType={getContentType}
        url={getUrl}
        videoEncodeStatus={videoEncodeStatus}
      />

      <AddAssignToCourseStructureModal
        getAssignmentList={getAssignmentList}
        toggleAssignmentModal={toggleAssignmentModal}
        columnViewAssignment={columnViewAssignment}
        filteredAssignmentList={filteredAssignmentList}
        customStyles={customStyles}
        subHeaderComponent={subHeaderComponent}
        handleAssignRowSelected={handleAssignRowSelected}
        changeBackgroundOut={changeBackgroundOut}
        changeBackgroundOver={changeBackgroundOver}
        addAssignmentToCourseStructure={addAssignmentToCourseStructure}
      />

      <ViewAssignModal
        getviewAssignment={getviewAssignment}
        toggleViewAssignment={toggleViewAssignment}
        assignmentId={getUrl}
        userId={UserId}
      />


    </div>
  );
}

export default InstCourseDetails;



