import axios from "axios";


console.log("loading assignment service")


// const getAssignmentDetail = URI.SERVER?._ASSIGNMENT + "/assignment/instructor/"
// //this will list out the assignment created by the Inst
// const postAssignmentDetail = URI.SERVER?._ASSIGNMENT + "/assignment/assignment/"
// //this is use to create the assignment at the instructor side with meta data
// const postFileUpload = URI.SERVER?._ASSIGNMENT + "/assignment/fileonlyadd/"
// // this api is use to upload the File at inst side
// const updateAssignmentDetail = URI.SERVER?._ASSIGNMENT + "/assignment/assignmentupdate"
// //this is use to update the assignment meta data at the instructor side with meta data

// const deleteAssignment = URI.SERVER?._ASSIGNMENT + "/assignment/assignmentdelete/"
// const deleteFile = URI.SERVER?._ASSIGNMENT + "/assignment/fileonlydelete/"


// const studentAssignListAPI = URI.SERVER?._ASSIGNMENT + "/assignment/student/"
// //This will list out the URI.SERVER?._ASSIGNMENT at the LEarner side with Opening and closing date

// const assignmentData = URI.SERVER?._ASSIGNMENT + '/assignment/getassignbyid/'
// export const getFileToView = URI.SERVER?._ASSIGNMENT + '/assignment/getfile/'

// const studentFileUpload = URI.SERVER?._ASSIGNMENT + '/assignment/solutionsubmit/'
// //This is use to upload the file at the Learner side
// const getStudentFileUpload = URI.SERVER?._ASSIGNMENT + '/assignment/getsolbyassignandsubmittedby/'
// const getAssignmentSubmittedList = URI.SERVER?._ASSIGNMENT + '/assignment/getsolbycourseandtenantassign/'
// const putEvaluatedBy = URI.SERVER?._ASSIGNMENT + '/assignment/evaluate/'
// const solutionFileDelete = URI.SERVER?._ASSIGNMENT + '/assignment/solutionfileonlydelete/'
// // This is use to delete the File at the learner side. make sure that at reuploading the file previous or current file must be delete then only file can be upload by the learner


// export const getSolutionFile = URI.SERVER?._ASSIGNMENT + '/assignment/getsolnfile/'


export const ASSIGN_URI = {
    _GET_ASSIGNMENT_DETAIL: "",
    _POST_ASSIGNMENT_DETAIL: "",
    _POST_FILE_UPLOAD: "",
    _UPDATE_ASSIGNMENT_DETAIL: "",
    _DELETE_ASSIGNMENT: "",
    _DELETE_FILE: "",
    _STUDENT_ASSIGN_LIST_API: "",
    _ASSIGNMENT_DATA: "",
    _GET_FILE_TO_VIEW: "",
    _STUDENT_FILE_UPLOAD: "",
    _GET_STUDENT_FILE_UPLOAD: "",
    _GET_ASSIGNMENT_SUBMITTED_LIST: "",
    _PUT_EVALUATED_BY: "",
    _SOLUTION_FILE_DELETE: "",
    _GET_STUDENT_FILE: ""
}



class AssignmentService {
    constructor() {
        this.init()
    }

    async init() {
        // debugger;
        try {
            const response = await fetch('/data.json');
            let data = await response.json();

            const isStaging = data.SERVER?.MODE === 'staging';

            if (isStaging) {
                const sResp = await fetch('/datas.json');
                data = await sResp.json();
            }
            const baseUri = isStaging ? data.SERVER?._ASSIGNMENT : data.SERVER?._FRONTEND_UI;

            ASSIGN_URI._GET_ASSIGNMENT_DETAIL = `${baseUri}/assignment/api/instructor/`;
            ASSIGN_URI._POST_ASSIGNMENT_DETAIL = `${baseUri}/assignment/api/assignment/`;
            ASSIGN_URI._POST_FILE_UPLOAD = `${baseUri}/assignment/api/fileonlyadd/`;
            ASSIGN_URI._UPDATE_ASSIGNMENT_DETAIL = `${baseUri}/assignment/api/assignmentupdate`;
            ASSIGN_URI._DELETE_ASSIGNMENT = `${baseUri}/assignment/api/assignmentdelete/`;
            ASSIGN_URI._DELETE_FILE = `${baseUri}/assignment/api/fileonlydelete/`;
            ASSIGN_URI._STUDENT_ASSIGN_LIST_API = `${baseUri}/assignment/api/student/`;
            ASSIGN_URI._ASSIGNMENT_DATA = `${baseUri}/assignment/api/getassignbyid/`;
            ASSIGN_URI._GET_FILE_TO_VIEW = `${baseUri}/assignment/api/getfile/`;
            ASSIGN_URI._STUDENT_FILE_UPLOAD = `${baseUri}/assignment/api/solutionsubmit/`;
            ASSIGN_URI._GET_STUDENT_FILE_UPLOAD = `${baseUri}/assignment/api/getsolbyassignandsubmittedby/`;
            ASSIGN_URI._GET_ASSIGNMENT_SUBMITTED_LIST = `${baseUri}/assignment/api/getsolbycourseandtenantassign/`;
            ASSIGN_URI._PUT_EVALUATED_BY = `${baseUri}/assignment/api/evaluate/`;
            ASSIGN_URI._SOLUTION_FILE_DELETE = `${baseUri}/assignment/api/solutionfileonlydelete/`;
            ASSIGN_URI._GET_STUDENT_FILE = `${baseUri}/assignment/api/getsolnfile/`;

        } catch (error) {
            console.error('Failed to fetch URL:', error);
        }
    }

    getAssignDetail(userID, courseID, tenantID) {
        return axios.get(ASSIGN_URI._GET_ASSIGNMENT_DETAIL + `${userID}/${courseID}/${tenantID}`);
    }
    postFileUpload(assignid, data) {
        return axios.post(ASSIGN_URI._POST_FILE_UPLOAD + `${assignid}`, data);
    }
    postAssignmentDetail(ReqBodyInfoSave) {
        return axios.post(ASSIGN_URI._POST_ASSIGNMENT_DETAIL, ReqBodyInfoSave)
    }
    deleteAssignment(assignID) {
        return axios.post(ASSIGN_URI._DELETE_ASSIGNMENT + `${assignID}`);
    }
    fileOnDelete(fileID) {
        return axios.post(ASSIGN_URI._DELETE_FILE + `${fileID}`);
    }
    updateAssignmentDetail(ReqBodyInfo) {
        return axios.post(ASSIGN_URI._UPDATE_ASSIGNMENT_DETAIL, ReqBodyInfo)
    }

    getListStudentAssignment(courseID, tenantID) {
        return axios.get(ASSIGN_URI._STUDENT_ASSIGN_LIST_API + `${courseID}/${tenantID}`)
        //return axios.get(ASSIGN_URI._GET_ASSIGNMENT_DETAIL + `${userID}/${courseID}/${tenantID}`);
    }

    getAssignmentData(assignid) {
        return axios.get(ASSIGN_URI._ASSIGNMENT_DATA + assignid);
    }

    // getfileView(uri,fileId) {
    //     return axios.get(uri + fileId)
    // }
    getfileView = (uri, fileId) => {
        return axios.get(`${uri}/${fileId}`, {
            responseType: 'blob' // Ensure the response is in blob format
        });
    };
    postStudentFileUpload(assignId, userId, studFile) {
        return axios({
            method: "POST",
            url: ASSIGN_URI._STUDENT_FILE_UPLOAD + `${assignId}/${userId}`,
            data: studFile,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    getStudentFileUpload(assignId, UserId) {
        return axios.get(ASSIGN_URI._GET_STUDENT_FILE_UPLOAD + `${assignId}/${UserId}`)
    }
    getAssignmentSubmittedList(courseId, tenantId, assignId) {
        return axios.get(ASSIGN_URI._GET_ASSIGNMENT_SUBMITTED_LIST + `${courseId}/${tenantId}/${assignId}`)
    }
    putEvaluatedBy(evalBody) {
        return axios.post(ASSIGN_URI._PUT_EVALUATED_BY, evalBody)

    }
    solutionfileonlydelete(id) {
        return axios.post(ASSIGN_URI._SOLUTION_FILE_DELETE + id);
    }


}

export default new AssignmentService();


