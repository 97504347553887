import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import userProfile from './components/User/userProfile'
import Feedback from './pages/account/Feedback';
import UserGrid from './pages/courses/UserCourseGrid';
import UserList from './pages/courses/UserCourseList';
import RenderOnRole from './pages/account/RenderOnRole'
import AddTestimonial from './pages/account/AddTestimonial';
import Dashborad from './pages/dashboard/dashborad';
import AddCourseCategory from './pages/instructor/AddCourseCategory';
import CreateCourse from './pages/instructor/CourseCreate';
import ViewCourses from './pages/instructor/instCourses/ViewCourses';
import InstCourseDetails from './pages/instructor/instCourses/InstCourseDetails';
import CourseStructureDrive from './pages/instructor/instCourses/CourseStructureDrive';
import UpdateCourse from './pages/instructor/UpdateCourse';
import LearnerListToApprove from './app/basic-ui/LearnerListToApprove';
import LearnerListtoApproveForCourse from './app/Courses/LearnerListtoApproveForCourse';
import AdminCourseDetail from './app/Courses/AdminCourseDetail';

import styled, { ThemeProvider } from "styled-components";
import WebFont from 'webfontloader';
import { GlobalStyles } from './theme/GlobalStyles';
import { useTheme } from './theme/useTheme';

import ThemeSelector from './theme/ThemeSelector';

import Dialog from './theme/Dialog';
import CreateThemeContent from './theme/CreateThemeContent';
import Setting from './components/common/Setting';
import CertificateVerificatonpage from './pages/404/CertificateVerificatonpage';


// import Buttons from './app/basic-ui/Buttons';
// import Dropdowns from './app/basic-ui/Dropdowns';
// import Typography from './app/basic-ui/Typography';
// import BasicElements from './app/form-elements/BasicElements';
// import BasicTable from './app/tables/BasicTable';
// import Mdi from './app/icons/Mdi';
// import ChartJs from './app/charts/ChartJs';
// import LockScreen from './app/user-pages/Lockscreen';
// import Error500 from './app/error-pages/Error500';
// import Error404 from './app/error-pages/Error404';
// import BlankPage from './app/general-pages/BlankPage';
import Dashboard from './app/dashboard/Dashboard';
import AdminDashBoard from './app/AdminDashBoard';
import Spinner from './app/shared/Spinner';
import RenderOnAdmin from './pages/account/RenderOnAdmin';
import AdminViewCourses from './app/Courses/AdminViewCourses';
import LearnersData from './app/basic-ui/LearnersData';

import Menu from './pages/instructor/menu';
import Testimonials from './app/basic-ui/Testimonials';
import { GlobalContext } from './pages/courses/contentdelivery/contexts/GlobalContext';
import ContentDelivery from './pages/courses/contentdelivery/menubar/ContentDelivery';
import ContentDeliveryNew from "./pages/courses/contentDeliveryUpdated/ContentDeliveryUpdated.js"
import BulkUserRegistration from './app/basic-ui/BulkUserRegistration';
import RequestForInstructor from './app/basic-ui/RequestForInstructor';
import GeneralAnnouncement from './app/basic-ui/GeneralAnnouncement';
import MarqueeforHeader from './app/basic-ui/MarqueeforHeader';
import GeneralFeedbackMaster from './app/basic-ui/GeneralFeedbackMaster';
import AdminViewCourseDetails from './app/Courses/AdminViewCourseDetails';
import CourseAnalyticsReports from './app/basic-ui/CourseAnalyticsReports';
import MailConfigureSetting from './app/basic-ui/MailConfigureSetting';
import InstructorDashboard from './pages/dashboard/InstructorDashboard';
import InstructorsData from './app/basic-ui/InstructorsData';
import UserService from './services/UserService';
import ContactWebsiteMessage from './app/basic-ui/ContactWebsiteMessage';
// import { Modal } from 'react-bootstrap';
import SessionExpire from './pages/404/SessionExpire';
import Payment from './pages/courses/payment/Payment';
import EventHandling from './app/basic-ui/EventHandling';
import CoursePaymentDetails from './app/basic-ui/CoursePaymentDetails';
import PaymentEnquiryfromGateway from './app/basic-ui/PaymentEnquiryfromGateway';
import TokenActivatePage from './pages/404/TokenActivatePage';

import CourseListDetails from './app/CourseListDetails';
import InstructorCourseList from './app/basic-ui/InstructorCourseList';
import CourseWiseLearnerList from './app/Courses/CourseWiseLearnerList';
import CourseLearnerAndFeesStatus from './app/Courses/CourseLearnerAndFeesStatus';
import ManageDesignation from './app/basic-ui/ManageDesignation';
import ManageQualification from './app/basic-ui/ManageQualification';

import libraryContent from './components/library/libraryContent';
import CoursesToPublish from './app/Courses/CoursesToPublish';
import CreateLibrary from './components/library/CreateLibrary';
import ViewLibrary from './components/library/ViewLibrary';
import InstLibraryDetails from './components/library/InstLibraryDetails';
import LibraryToPublish from './components/library/LibraryToPublish';
import AdminLibraryDetails from './app/Courses/AdminLibraryDetails';
import AdminViewLibrary from './components/library/AdminViewLibrary';
import AdminViewAllLibrary from './components/library/AdminViewAllLibrary';

import AdminViewLibraryContent from './components/library/AdminViewLibraryContent';
import LibraryDetails from './pages/courses/LibraryDetails';
import axios from 'axios';
import "../src/assets/css/all.css";
import "../src/assets/css/line-awesome.min.css";
import "../src/assets/css/swiper.min.css";
import "../src/assets/css/font-awesome.min.css";
import Logout from './pages/logout/Logout';
import CloneCourse from './pages/instructor/CloneCourse';
import CourseSummary from './app/Courses/CourseSummary.js';
import AssignOrRemoveInstructors from './app/basic-ui/AssignOrRemoveInstructors.js';
import GlobalModal from './app/shared/GlobalModal.js';
import { Routes } from './Routes.jsx';

// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Container = styled.div`
  margin: 5px auto 5px auto;
`;

function App({ store, history }) {
    const [globalContextState, setGlobalContextState] = useState({
        currentId: "",
        currentPath: "",
        selectedIndex: 1,
        currentNodeType: '',
        apiData: [],
        discussionData: [],
        queryData: [],
        activityProgress: " ",
        DependencyActivityProgress: " ",
        // arcane: "meghsikshak",
        arcane: "cakes",
        _courseName: "",
        courseOwner: false,
        courseOwnerId: "IIIDDDDD",
        globalSpinner: false

    });
    let loadingCount = 0;
    // useEffect(() => {
    //     UserService.generateToken();
    //    }, []);

    //const nav = useRouteMatch();
    // useEffect(() => {
    //     // UserService.updateToken();
    //     // UserService.refreshToken();
    //     if (UserService.getToken() !== undefined) {
    //       axios.defaults.headers.common[
    //         "Authorization"
    //       ] = `Bearer ${UserService.getToken()}`;
    //     }
    //   }, []);


    const [checkIdle, setCheckIdle] = useState(false)
    //const [timer, setTimer] = useState(1 * 1000);
    //let timer = 5*1000
    //const loc = useLocation();
    const clickOnOK = () => {
        UserService.doLogout();
        //console.log("token Expire")
        //nav(`${process.env.PUBLIC_URL + "/"}`)
    }
    const clickOnLoginAgain = () => {
        UserService.doLogout();
        //console.log("token Expire");


    }
    const onIdle = () => {
        ////console.log("ABCD")
        // if (!UserService.isLoggedIn()) {
        //     setCheckIdle(true);
        // }
        setCheckIdle(true);
    }


    // const { theme, themeLoaded, getFonts } = useTheme();
    // const [selectedTheme, setSelectedTheme] = useState(theme);
    // const [showDialog, setShowDialog] = useState(false);
    // const [newTheme, setNewTheme] = useState();

    // useEffect(() => {
    //     WebFont.load({
    //         google: {
    //             families: getFonts()
    //         }
    //     });
    // });

    // useEffect(() => {
    //     setSelectedTheme(theme);
    // }, [themeLoaded]);

    // const manageDialog = () => {
    //     setShowDialog(!showDialog);
    // }

    // const createTheme = newTheme => {
    //     //console.log(newTheme);
    //     setShowDialog(false);
    //     setNewTheme(newTheme);
    // }


    ////CODE TO REFRESH THE TOKEN
    axios.interceptors.request.use(async (config) => {
        if (UserService.getToken() !== undefined) {
            try {
                await UserService.updateToken(60);
                config.headers['Authorization'] = `Bearer ${UserService.getToken()}`;
            } catch (error) {
                // navigate.push(`${process.env.PUBLIC_URL + "/"}`);
                await UserService.doLogout()
            }
        }
        return config;
    });


    
    return (
        <Provider store={store}>

            <GlobalContext.Provider value={{ globalContextState, setGlobalContextState }}>
                <Router history={history}>
                    <GlobalStyle />
                    <ScrollToTop />
                    <Routes />
                </Router>



                {/* {
                themeLoaded && <ThemeProvider theme={selectedTheme}>
                    <GlobalStyles />
                    <Container style={{ fontFamily: selectedTheme.font }}>
                        <button className="btn" onClick={manageDialog}>Create a Theme</button>
                        <Dialog
                            header="Create a Theme"
                            body={<CreateThemeContent create={createTheme} />}
                            open={showDialog}
                            callback={manageDialog} />
                        <ThemeSelector setter={setSelectedTheme} newTheme={newTheme} />
                    </Container>
                </ThemeProvider>
            } */}
            </GlobalContext.Provider>

        </Provider >
    )
}

App.propTypes = {
    history: PropTypes.any.isRequired,
    store: PropTypes.any.isRequired
};

export default App;